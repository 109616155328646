define('incus-vastaanota/pods/invoice/view/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/email', 'incus-vastaanota/models/invoice'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsEmail, _incusVastaanotaModelsInvoice) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  /* This view is used both by checker and the account holder. Thus the session test is handled within the route. */

  exports['default'] = _ember['default'].Route.extend({

    authenticated: false,
    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    storageId: null,
    storageKey: null,
    email: null,
    countryselector: _ember['default'].inject.service(),

    // We check the session in model. Should we do this in beforeModel?
    model: function model(param) {
      var isLaskumappi = this.get("countryselector.url") === 'laskumappi';
      var authenticatedView = false;
      _ember['default'].Logger.log(param);
      var apixclient = this.get("apixclient");
      // TODO Need interface for invoice without TransferID
      var userData = this.get("session").get("user");
      if (userData) {
        userData = userData[0];
        authenticatedView = true;
        this.set('email', userData.Email);
        this.set('authenticated', true);
      } else {
        this.set("storageKey", param.storageKey);
        this.set('email', param.email);
      }
      this.set("storageId", param.storageId);

      var invoice = _incusVastaanotaModelsInvoice['default'].create();
      invoice.set("storageID", param.storageId);
      invoice.set("storageKey", param.storageKey);

      var modelCreation = function modelCreation(data) {
        _ember['default'].Logger.log(data);
        invoice.setFields(data.data[0]);
        // Add observers for the adminstrate fake-modal dialog
        invoice.addObserver('invoiceStatus', invoice, function () {
          var storageId = invoice.get('storageID');
          var metadata = invoice.toXML();
          return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log("invoice invoiceStatus updated", data);
          });
        });
        invoice.addObserver('paymentStatus', invoice, function () {
          var storageId = invoice.get('storageID');
          var metadata = invoice.toXML();
          return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log("invoice paymentStatus updated", data);
          });
        });
        var type = 'image';
        var storageId = invoice.get("storageID");
        var storageKey = invoice.get("storageKey");
        _ember['default'].Logger.log(invoice.toXML());
        var dlURL = apixclient.downloadURL(storageId, storageKey, 'no', '', invoice.get('documentID') + '.zip');

        // TODO clean all this, used only for testing
        var req = [apixclient.listAttachment(storageId, storageKey)];
        return _ember['default'].RSVP.Promise.all(req).then(function (results) {
          var emailAddresses = [];

          var _results = _slicedToArray(
          //            storageList,
          //            storageInfo,

          results, 1);

          var
          //emailAddresses,
          attachments = _results[0];

          var images = [];
          var attachment = undefined;
          attachments.data.forEach(function (a) {
            if (a.FileName !== 'invoice.xml') {
              images.addObject(a);
            }
          });
          var imageName = invoice.get('imageName');
          var attachmentSrc = apixclient.downloadURL(storageId, storageKey, 'no', imageName, imageName, 'application/pdf', 'inline');
          // Used to determine which will be the actual recipients for the email.

          var model = _ember['default'].Object.extend({
            isLaskumappi: isLaskumappi,
            invoice: invoice,
            attachmentSrc: attachmentSrc,
            selectedAttachment: 'invoice.pdf',
            dlURL: dlURL,
            showAddComment: false,
            showAddPayment: false,

            paymentStatusValues: Object.keys(_incusVastaanotaModelsInvoice['default'].proto().PAYMENTSTATUS).map(function (key) {
              return _incusVastaanotaModelsInvoice['default'].proto().PAYMENTSTATUS[key];
            }),
            authenticatedView: authenticatedView,

            emailAddressSourceList: emailAddresses,
            emailAddressList: _ember['default'].computed('emailAddressSourceList.[]', function () {
              _ember['default'].Logger.log('creating email address object list');
              var list = [];
              var emailAddressSourceList = this.get('emailAddressSourceList');
              _ember['default'].Logger.log(emailAddressSourceList);
              for (var i = 0; i < emailAddressSourceList.length; i++) {
                list.addObject(_ember['default'].Object.create({
                  address: emailAddressSourceList[i],
                  selected: true,
                  index: i
                }));
              }
              return list;
            }),

            emailRecipient: null,
            emailBoxTitle: null,
            attachments: images

          }).create();
          return model;
        });
      };
      return apixclient.receiverMetadata(param.storageId, param.storageKey).then(function (data) {
        return modelCreation(data);
      });
    },

    actions: {
      addPayment: function addPayment() {
        var _this = this;

        var invoice = this.get("controller.model.invoice");
        if (!invoice.isValidPartialAmount(invoice.paidAmount)) {
          var notify = this.get('notify');
          var trans = this.get("i18n");
          notify.error(trans.t("invoice.partialAmount") + " " + trans.t("common.shouldBeNumber"));
          return 1;
        }
        var userData = undefined;
        var auth = this.get('controller.model.authenticatedView');
        if (auth) {
          userData = this.get("session").get("user")[0];
        }
        var amount = this.get('controller.model.invoice.paidAmount');
        var paymentDate = this.get("controller.model.invoice.paymentDate");
        var postingDate = this.get("controller.model.invoice.postingDate");
        var tempArray = invoice.addPayment(amount, paymentDate, postingDate);
        this.set("controller.model.invoice", invoice);

        if (auth) {
          var _invoice = this.get("controller.model.invoice");
          var storageId = _invoice.get('storageID');
          var metadata = _invoice.toXML();
          var apixclient = this.get('apixclient');
          var _userData = this.get("session").get("user")[0];
          return apixclient.setMetadata(_userData.TransferID, storageId, _userData.TransferKey, metadata, 'text/xml').then(function (data) {
            _ember['default'].Logger.log(data);
            _this.set("controller.model.showAddComment", false);
            _this.set("controller.model.commentText", '');
          });
        }
        _ember['default'].Logger.log(invoice.get("paymentArray"));
        this.set("controller.model.showAddPayment", false);
        return true;
      },
      showAddPayment: function showAddPayment() {
        this.set("controller.model.showAddPayment", true);
      },
      addCommentDisplay: function addCommentDisplay() {
        this.set("controller.model.showAddComment", true);
      },
      thumbsUp: function thumbsUp() {
        this._thumbsAction(_incusVastaanotaModelsInvoice['default'].proto().APPROVALSTATUS.POSITIVE);
      },
      thumbsIdle: function thumbsIdle() {
        this._thumbsAction(_incusVastaanotaModelsInvoice['default'].proto().APPROVALSTATUS.NEUTRAL);
      },
      thumbsDown: function thumbsDown() {
        this._thumbsAction(_incusVastaanotaModelsInvoice['default'].proto().APPROVALSTATUS.NEGATIVE);
      },
      deleteInvoice: function deleteInvoice() {
        var _this2 = this;

        var invoice = this.get("controller.model.invoice");
        var storageId = invoice.get('storageID');
        var apixclient = this.get('apixclient');
        var userData = this.get("session").get("user")[0];
        return apixclient.deleteStorage(userData.TransferID, storageId, userData.TransferKey).then(function (data) {
          _ember['default'].Logger.log(data);
          _this2.transitionTo('list');
        });
      },
      fileChanged: function fileChanged(fileName, fileType, fileSize) {
        _ember['default'].Logger.log('fileChanged', fileName, fileType);
        this.set('controller.model.fileName', fileName);
        this.set('controller.model.fileType', fileType);
      },
      upload: function upload() {
        var _this3 = this;

        var apixclient = this.get('apixclient');
        //let userData = this.get("session").get("user")[0];
        var invoice = this.get('controller.model.invoice');
        var storageId = invoice.get('storageID');
        var storageKey = invoice.get('storageKey');
        var attachmentData = this.get("controller.fileData");
        var attachmentType = this.get('controller.model.fileType');
        var attachmentName = this.get('controller.model.fileName');
        _ember['default'].Logger.log('uploading', attachmentName, attachmentType);
        if (attachmentData && attachmentName && attachmentType) {
          _ember['default'].Logger.log('uploading', attachmentName, attachmentType);
          return apixclient.uploadBySid(storageId, attachmentType, attachmentName, storageKey, attachmentData, 'application/octet-stream').then(function (data) {
            _ember['default'].Logger.log("uploaded");
            _ember['default'].Logger.log(data);
            _this3.set('controller.fileData', null);
            _this3.get('notify').info(_this3.get('i18n').t('common.uploadComplete').toString());
            _this3._closeAdministrate();
            return apixclient.listAttachment(storageId, storageKey).then(function (attachments) {
              var images = [];
              attachments.data.forEach(function (a) {
                if (a.FileName !== 'invoice.xml') {
                  images.addObject(a);
                }
              });
              _this3.set('controller.model.attachments', images);
            });
          });
        } else {
          this.get('notify').error('No file to upload').toString();
        }
      },
      // fake-modal view for administering
      // I looked at https://github.com/yapplabs/ember-modal-dialog which looked decent I guess. Still I think making a custom component would make more sense.
      administrate: function administrate() {
        _ember['default'].$("#overlay").addClass('active-overlay');
        _ember['default'].$("#administrate").addClass('active-lightbox').removeClass("inactive-lightbox");
      },
      closeAdministrate: function closeAdministrate() {
        this._closeAdministrate();
      },
      togglePaymentStatus: function togglePaymentStatus() {
        var invoice = this.get('controller.model.invoice');
        invoice.togglePaymentStatus();
      },
      showBarcode: function showBarcode(documentID) {
        var invoice = this.get("controller.model.invoice");
        window.prompt(this.get('i18n').t('listview.barcodePrompt'), invoice.generateBarcode());
      },
      // A separate display action for each type of email, actual sending separated.
      toCheckerEmail: function toCheckerEmail() {
        _ember['default'].$("#overlay").addClass('active-overlay');
        _ember['default'].$("#send-email").addClass('active-lightbox').removeClass("inactive-lightbox");
        this.set("controller.model.term", null);
        this.set("controller.model.emailBoxTitle", this.get('i18n').t("common.toChecker"));
        this.set('controller.model.emailType', 'checker');
        _ember['default'].$('#email-address-input').focus();
        _ember['default'].Logger.log("checker", this.get('controller.model.emailType'));
      },
      toAccountingEmail: function toAccountingEmail() {
        _ember['default'].$("#overlay").addClass('active-overlay');
        _ember['default'].$("#send-email").addClass('active-lightbox').removeClass("inactive-lightbox");
        this.set("controller.model.term", null);
        this.set("controller.model.emailBoxTitle", this.get('i18n').t("common.toAccounting"));
        this.set('controller.model.emailType', 'accounting');
        _ember['default'].$('#email-address-input').focus();
      },
      sendEmail: function sendEmail() {
        var _this4 = this;

        var emailAddressList = this.get('controller.model.emailAddressList');
        var list = emailAddressList.filterBy('selected');

        if (!list.length) {
          this.get('notify').error(this.get('i18n').t("email.noRecipient").toString());
          return;
        }

        /* Check emails */
        var failures = false;
        list.forEach(function (el) {
          if (!_incusVastaanotaUtilsEmail['default'].validateEmail(el.address)) {
            _this4.get('notify').error(_this4.get('i18n').t("email.invalidRecipient") + ": " + el.address);
            failures = true;
          }
        });
        if (failures) {
          return;
        }
        this._sendEmail(list).then(function () {
          _this4.send("closeSendEmail");
        });
      },
      closeSendEmail: function closeSendEmail() {
        this.set('controller.model.emailType', null);
        _ember['default'].$("#overlay").removeClass('active-overlay');
        _ember['default'].$("#send-email").addClass('inactive-lightbox').removeClass("active-lightbox");
      },
      // toggle the checkbox for the specific entry
      selectEmailAddress: function selectEmailAddress(e) {
        _ember['default'].Logger.log(e);
        e.toggleProperty('selected');
      },
      // Add a new email address to the list and backend
      addEmailAddress: function addEmailAddress(emailRecipient) {
        var emailAddressSourceList = this.get('controller.model.emailAddressSourceList');

        if (!_incusVastaanotaUtilsEmail['default'].validateEmail(emailRecipient)) {
          // FIXME For some reason without the concatenation the resulting string is empty
          this.get('notify').error("" + this.get('i18n').t("email.invalidRecipient").toString());
          _ember['default'].Logger.log("Invalid email address.", emailRecipient);
          return;
        }
        // If the email Address is not present in the source list, add it.
        // XXX This currently overwrites the selection status.
        if (emailAddressSourceList.indexOf(emailRecipient) === -1) {
          _ember['default'].Logger.log("Adding email addres" + emailRecipient);
          // The list of the email address objects is a property of the full list
          emailAddressSourceList.pushObject(emailRecipient);
          this.set("controller.model.emailAddressSourceList", emailAddressSourceList);
          /*
          apixclient.addEmailRecipient(userData.TransferID, storageID, userData.TransferKey, emailRecipient).then((data) => {
          Ember.Logger.log(data);
          });
          */
          this.set("controller.model.term", null);
          _ember['default'].$('#email-address-input').val(null);
          //document.getElementById('email-address-input').value = null;
        } else {
            _ember['default'].Logger.log("Address already added.");
          }
      },
      removeEmailAddress: function removeEmailAddress(e) {
        var emailAddressSourceList = this.get('controller.model.emailAddressSourceList');
        // TODO backend call
        /*
        let invoice = this.get("controller.model.invoice");
        let storageId = invoice.get('storageID');
        let apixclient = this.get('apixclient');
        let userData = this.get("session").get("user")[0];
        return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, emailRecipient,).then((data) => {
        Ember.Logger.log(data);
        });
         */
        emailAddressSourceList = emailAddressSourceList.filter(function (el) {
          if (e.address === el) {
            return false;
          }
          return true;
        });
        //emailAddressSourceList.removeObject(e);
        _ember['default'].Logger.log("removed", e, 'from', emailAddressSourceList);
        this.set('controller.model.emailAddressSourceList', emailAddressSourceList);
      },

      changeIframe: function changeIframe(attachment) {
        _ember['default'].Logger.log(attachment);
        var apixclient = this.get("apixclient");
        var invoice = this.get("controller.model.invoice");
        var storageId = invoice.get('storageID');
        var storageKey = invoice.get('storageKey');
        this.set('controller.model.selectedAttachment', attachment.FileName);
        var re = /:/;
        if (re.test(attachment.FileMimeType)) {
          _ember['default'].Logger.log("as the data seems to have colone (category:detail) instead of slash, substituting", attachment.FileMimeType);
          attachment.FileMimeType = attachment.FileMimeType.replace(':', '/');
        }
        _ember['default'].Logger.log(attachment.FileMimeType);
        var attachmentSrc = apixclient.downloadURL(storageId, storageKey, 'no', attachment.FileName, attachment.FileName, attachment.FileMimeType, 'inline');
        this.set("controller.model.attachmentSrc", attachmentSrc);
        //});
      }
    },

    // Internal functions
    _closeAdministrate: function _closeAdministrate() {
      _ember['default'].$('#overlay').removeClass('active-overlay');
      _ember['default'].$('#administrate').addClass("inactive-lightbox").removeClass("active-lightbox");
    },

    _thumbsAction: function _thumbsAction(approvalStatus) {
      var invoice = this.get("controller.model.invoice");
      var userData = undefined;
      var auth = this.get('controller.model.authenticatedView');
      if (auth) {
        userData = this.get("session").get("user")[0];
      }
      var email = this.get('email');
      var text = this.get("controller.model.commentText");
      var tempArray = invoice.addComment(approvalStatus, email, text);
      this.set("controller.model.invoice", invoice);
      if (auth) {
        this._setMetadata();
      } else {
        var metadata = this._commentMetadata(tempArray[0], tempArray[1]);
        this._addMetadata(metadata);
      }
      _ember['default'].Logger.log(invoice.get("commentArray"));
      // Hide the add comment elements
      this.set("controller.model.showAddComment", true);
    },
    _setMetadata: function _setMetadata() {
      var _this5 = this;

      var invoice = this.get("controller.model.invoice");
      var storageId = invoice.get('storageID');
      var metadata = invoice.toXML();
      var apixclient = this.get('apixclient');
      var userData = this.get("session").get("user")[0];
      return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
        _ember['default'].Logger.log(data);
        _this5.set("controller.model.showAddComment", false);
        _this5.set("controller.model.commentText", '');
      });
    },
    _addMetadata: function _addMetadata(metadata) {
      var _this6 = this;

      var invoice = this.get("controller.model.invoice");
      var storageId = invoice.get('storageID');
      var storageKey = invoice.get('storageKey');
      _ember['default'].Logger.log(invoice.get("commentArray"));
      var apixclient = this.get('apixclient');
      return apixclient.addMetadata(storageId, storageKey, metadata, 'text/xml').then(function (data) {
        _ember['default'].Logger.log(data);
        _this6.set("controller.model.showAddComment", false);
        _this6.set("controller.model.commentText", '');
      });
    },

    _commentMetadata: function _commentMetadata(commentString, commentNumber) {
      var invoice = this.get("controller.model.invoice");
      var size = invoice.get("commentArray").length;
      var metadata = '<Group>' + '<Value type="comment.' + commentNumber + '">' + _incusVastaanotaUtilsEmail['default'].escapeXML(commentString) + "</Value>\r\n";
      metadata = metadata + '<Value type="ApprovalStatus">' + _incusVastaanotaUtilsEmail['default'].escapeXML(invoice.get('approvalStatus')) + "</Value>\r\n";
      metadata = metadata + '<Value type="comment.size">' + _incusVastaanotaUtilsEmail['default'].escapeXML(size) + "</Value>\n";
      metadata = metadata + '</Group>';
      return metadata;
    },

    _approvalMetadata: function _approvalMetadata(approverString, approverNumber) {
      var invoice = this.get("controller.model.invoice");
      var size = invoice.get("approversList").length;
      var metadata = '<Group>' + '<Value type="approver.' + approverNumber + '">' + _incusVastaanotaUtilsEmail['default'].escapeXML(approverString) + "</Value>\r\n";
      metadata = metadata + '<Value type="approver.size">' + _incusVastaanotaUtilsEmail['default'].escapeXML(size) + "</Value>\n";
      metadata = metadata + '</Group>';
      return metadata;
    },

    _deleteAttachment: function _deleteAttachment(filename) {
      var invoice = this.get("controller.model.invoice");
      var storageId = invoice.get('storageID');
      var apixclient = this.get('apixclient');
      var userData = this.get("session").get("user")[0];
      return apixclient.deleteAttachment(userData.TransferID, storageId, filename, userData.TransferKey).then(function (data) {
        _ember['default'].Logger.log(data);
      });
    },

    _sendEmail: function _sendEmail(emailRecipientList) {
      var _this7 = this;

      var apixclient = this.get("apixclient");
      var emailType = this.get('controller.model.emailType');
      var invoice = this.get("controller.model.invoice");
      var userData = { name: '' };
      var documentID = invoice.get('documentID');
      var storageId = invoice.get('storageID');
      var storageKey = invoice.get('storageKey');

      var subject = undefined;
      if (emailType === 'checker') {
        subject = this.get('i18n').t("email.checkerSubject") + " " + invoice.get('documentID');
      } else if (emailType === 'accounting') {
        userData = this.get("session").get("user")[0];
        // The observer will handle the metadata commit
        subject = userData.Name + ' ' + this.get('i18n').t("email.accountingSubject");
      }

      var attach = 'no';
      if (emailType === 'accounting') {
        attach = 'yes';
      }

      // Loop through the recipients
      var req = [];
      //    emailRecipientList.forEach((emailRecipient) => {
      emailRecipientList.forEach(function (recipient) {
        var emailRecipient = recipient.get('address');

        _ember['default'].Logger.log("adding ", emailRecipient);
        //let url = Ember.String.htmlSafe(window.location.origin + '/' + this.router.generate('invoice.view', storageId, {
        var url = _ember['default'].String.htmlSafe(_incusVastaanotaConfigEnvironment['default'].clientHostRoot + '/' + _this7.router.generate('invoice.view', storageId, {
          queryParams: {
            storageKey: storageKey,
            email: emailRecipient
          }
        }));
        var xml = _incusVastaanotaUtilsEmail['default'].generateXML(emailRecipient, emailType, documentID, invoice, _this7.get('i18n'), userData, url, subject);
        req.addObject(apixclient.sendEmail(subject, _incusVastaanotaConfigEnvironment['default'].emailSender, emailRecipient, storageId, attach, storageKey, xml).then(function (data) {
          _ember['default'].Logger.log("Email sent.");
          _ember['default'].Logger.log(data);
          var auth = _this7.get('controller.model.authenticatedView');
          if (emailType === 'checker') {
            var tempArray = invoice.addApprover(emailRecipient);
            // When authenticated only set metadata after all sent
            if (!auth) {
              var metadata = _this7._approvalMetadata(tempArray[0], tempArray[1]);
              _ember['default'].Logger.log(metadata);
              _this7._addMetadata(metadata);
            }
          }
          if (emailType === 'accounting') {
            invoice.set('sentToBookkeeping', moment().format('YYYYMMDDHHmmss'));
          }
        }));
      });
      // All emails should be sent by now, so update the metadata
      _ember['default'].Logger.log(req);
      return _ember['default'].RSVP.Promise.all(req).then(function (results) {
        _ember['default'].Logger.log("all requests done");
        _ember['default'].Logger.log(results);
        _ember['default'].Logger.log(invoice);
        _this7.set("controller.model.invoice", invoice);
        var auth = _this7.get('controller.model.authenticatedView');
        if (auth) {
          _this7._setMetadata();
        }
      });
    }
  });
});