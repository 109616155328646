define("incus-vastaanota/pods/tools/users/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 46,
                "column": 6
              }
            },
            "moduleName": "incus-vastaanota/pods/tools/users/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "position:relative;");
            dom.setAttribute(el1, "class", "settings-form-group");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "password-strength-meter");
            var el2 = dom.createTextNode("\n\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "ckmark-container");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "checkmark");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "ckmark-container");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "checkmark");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "users-table-button");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-cancel");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "style", "margin-bottom:5px;");
            dom.setAttribute(el2, "class", "users-table-button");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-trash");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "users-table-button");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-save");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(fragment, [3, 1]);
            var element3 = dom.childAt(fragment, [5]);
            var element4 = dom.childAt(element3, [1]);
            var element5 = dom.childAt(element3, [3]);
            var element6 = dom.childAt(fragment, [7]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element6, [3]);
            var element9 = dom.childAt(element6, [5]);
            var morphs = new Array(13);
            morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element1, 3, 3);
            morphs[2] = dom.createAttrMorph(element2, 'class');
            morphs[3] = dom.createMorphAt(element4, 0, 0);
            morphs[4] = dom.createMorphAt(element4, 1, 1);
            morphs[5] = dom.createMorphAt(element5, 0, 0);
            morphs[6] = dom.createMorphAt(element5, 1, 1);
            morphs[7] = dom.createElementMorph(element7);
            morphs[8] = dom.createMorphAt(element7, 0, 0);
            morphs[9] = dom.createElementMorph(element8);
            morphs[10] = dom.createMorphAt(element8, 0, 0);
            morphs[11] = dom.createElementMorph(element9);
            morphs[12] = dom.createMorphAt(element9, 0, 0);
            return morphs;
          },
          statements: [["content", "model.editUsername", ["loc", [null, [30, 15], [30, 38]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "model.editPass", ["loc", [null, [31, 38], [31, 52]]]]], [], []], "placeholder", ["subexpr", "t", ["tools.users.newPass"], [], ["loc", [null, [31, 65], [31, 90]]]]], ["loc", [null, [31, 8], [31, 92]]]], ["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordStrengthClass2", []], "password-strength-class2"], [], []], " ", "password-strength-meter-bar"]]], ["inline", "t", ["tools.users.allowSell"], [], ["loc", [null, [38, 40], [38, 71]]]], ["inline", "input", [], ["checked", ["subexpr", "@mut", [["get", "model.editAllowSell", ["loc", [null, [38, 87], [38, 106]]]]], [], []], "type", "checkbox"], ["loc", [null, [38, 71], [38, 124]]]], ["inline", "t", ["tools.users.allowPurchase"], [], ["loc", [null, [39, 40], [39, 75]]]], ["inline", "input", [], ["checked", ["subexpr", "@mut", [["get", "model.editAllowPurchase", ["loc", [null, [39, 91], [39, 114]]]]], [], []], "type", "checkbox"], ["loc", [null, [39, 75], [39, 132]]]], ["element", "action", ["cancel"], [], ["loc", [null, [42, 16], [42, 36]]]], ["inline", "t", ["tools.users.cancel"], [], ["loc", [null, [42, 64], [42, 92]]]], ["element", "action", ["delete"], [], ["loc", [null, [43, 43], [43, 63]]]], ["inline", "t", ["tools.users.delete"], [], ["loc", [null, [43, 91], [43, 119]]]], ["element", "action", ["save", ["get", "u", ["loc", [null, [44, 33], [44, 34]]]]], [], ["loc", [null, [44, 16], [44, 36]]]], ["inline", "t", ["tools.users.save"], [], ["loc", [null, [44, 64], [44, 90]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 6
              },
              "end": {
                "line": 54,
                "column": 6
              }
            },
            "moduleName": "incus-vastaanota/pods/tools/users/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "position:relative;");
            dom.setAttribute(el1, "class", "settings-form-group");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "user-header");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "add-button-row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "users-table-button");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "fa fa-edit");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(element0, 0, 0);
            return morphs;
          },
          statements: [["content", "u.username", ["loc", [null, [48, 33], [48, 47]]]], ["element", "action", ["edit", ["get", "u", ["loc", [null, [52, 35], [52, 36]]]]], [], ["loc", [null, [52, 18], [52, 38]]]], ["inline", "t", ["tools.users.edit"], [], ["loc", [null, [52, 66], [52, 92]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 4
            },
            "end": {
              "line": 56,
              "column": 4
            }
          },
          "moduleName": "incus-vastaanota/pods/tools/users/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "edit-sub");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "is-equal", [["get", "u", ["loc", [null, [28, 22], [28, 23]]]], ["get", "model.editing", ["loc", [null, [28, 24], [28, 37]]]]], [], ["loc", [null, [28, 12], [28, 38]]]]], [], 0, 1, ["loc", [null, [28, 6], [54, 13]]]]],
        locals: ["u"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/tools/users/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "users-container");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "add-new-sub");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "position:relative;");
        dom.setAttribute(el3, "class", "settings-form-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "position:relative;");
        dom.setAttribute(el3, "class", "settings-form-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "password-strength-meter");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "style", "margin-top:14px;");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "ckmark-container");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "checkmark");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "ckmark-container");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "checkmark");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "add-button-row");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "users-table-button");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-plus");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var element11 = dom.childAt(element10, [1]);
        var element12 = dom.childAt(element11, [3]);
        var element13 = dom.childAt(element11, [5]);
        var element14 = dom.childAt(element11, [7, 1]);
        var element15 = dom.childAt(element11, [9]);
        var element16 = dom.childAt(element15, [1]);
        var element17 = dom.childAt(element15, [3]);
        var element18 = dom.childAt(element11, [11, 1]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element12, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(element13, 3, 3);
        morphs[5] = dom.createAttrMorph(element14, 'class');
        morphs[6] = dom.createMorphAt(element16, 0, 0);
        morphs[7] = dom.createMorphAt(element16, 1, 1);
        morphs[8] = dom.createMorphAt(element17, 0, 0);
        morphs[9] = dom.createMorphAt(element17, 1, 1);
        morphs[10] = dom.createElementMorph(element18);
        morphs[11] = dom.createMorphAt(element18, 0, 0);
        morphs[12] = dom.createMorphAt(element10, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["tools.users.newUser"], [], ["loc", [null, [4, 8], [4, 35]]]], ["inline", "t", ["tools.users.username"], [], ["loc", [null, [6, 13], [6, 41]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "model.newUsername", ["loc", [null, [7, 21], [7, 38]]]]], [], []], "placeholder", ["subexpr", "t", ["tools.users.username"], [], ["loc", [null, [7, 51], [7, 77]]]]], ["loc", [null, [7, 6], [7, 79]]]], ["inline", "t", ["tools.users.password"], [], ["loc", [null, [10, 13], [10, 41]]]], ["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "model.newPass", ["loc", [null, [11, 39], [11, 52]]]]], [], []], "placeholder", ["subexpr", "t", ["tools.users.password"], [], ["loc", [null, [11, 65], [11, 91]]]]], ["loc", [null, [11, 8], [11, 93]]]], ["attribute", "class", ["concat", [["subexpr", "-bind-attr-class", [["get", "model.passwordStrengthClass", []], "password-strength-class"], [], []], " ", "password-strength-meter-bar"]]], ["inline", "t", ["tools.users.allowSell"], [], ["loc", [null, [17, 36], [17, 67]]]], ["inline", "input", [], ["checked", ["subexpr", "@mut", [["get", "model.newAllowSell", ["loc", [null, [17, 83], [17, 101]]]]], [], []], "type", "checkbox"], ["loc", [null, [17, 67], [17, 119]]]], ["inline", "t", ["tools.users.allowPurchase"], [], ["loc", [null, [18, 36], [18, 71]]]], ["inline", "input", [], ["checked", ["subexpr", "@mut", [["get", "model.newAllowPurchase", ["loc", [null, [18, 87], [18, 109]]]]], [], []], "type", "checkbox"], ["loc", [null, [18, 71], [18, 127]]]], ["element", "action", ["add"], [], ["loc", [null, [22, 14], [22, 32]]]], ["inline", "t", ["tools.users.add"], [], ["loc", [null, [22, 60], [22, 85]]]], ["block", "each", [["get", "model.users", ["loc", [null, [26, 12], [26, 23]]]]], [], 0, null, ["loc", [null, [26, 4], [56, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});