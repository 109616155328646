define("incus-vastaanota/pods/webshop/controller", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        countryselector: _ember["default"].inject.service(),

        actions: {
            initIframe: function initIframe() {
                var model = this.get("model");
                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var url = this.get("countryselector.url");
                var that = this;
                iFrameResize({
                    log: false,
                    heightCalculationMethod: 'lowestElement',
                    initCallback: function initCallback(iframe) {
                        var userinfo = JSON.parse(sessionStorage.getItem("session"));
                        var locale = window.localStorage.getItem("locale");
                        if (url === "laskumappi" && locale === 'sv') {
                            locale = 'svfi';
                        }
                        userinfo.push({ locale: locale });
                        userinfo[0].entryPoint = url;
                        iframe.iFrameResizer.sendMessage(userinfo);
                    },
                    messageCallback: function messageCallback(msg) {
                        var command = JSON.parse(msg.message);
                        if (command.type === "paymentSuccess") {
                            if (userData) {
                                var promise = apixclient.GetSaldo(userData.UniqueCompanyID);
                                _ember["default"].RSVP.resolve(promise).then(function (data) {
                                    var saldo = data.data[0].Saldo;
                                    if (saldo < 90000) {
                                        console.log("SALDO", saldo);
                                        that.set("session.user.0.Saldo", saldo);
                                    }
                                });
                            }
                        }
                    } }, '#webshop');
            }
        }

    });
});