define('incus-vastaanota/utils/poller', ['exports', 'ember'], function (exports, _ember) {

  /*
  http://stackoverflow.com/questions/28310414/what-is-the-ember-implementation-of-setinterval-and-clearinterval
  var poller = new Poller();
    poller.setInterval(xxx);
    poller.start(this, function() {...}, xxx);
  
  */

  var Poller = _ember['default'].Object.extend({

    _interval: 1000,
    _currentlyExecutedFunction: null,

    start: function start(context, pollingFunction) {
      this.set('_currentlyExecutedFunction', this._schedule(context, pollingFunction, [].slice.call(arguments, 2)));
    },

    stop: function stop() {
      _ember['default'].run.cancel(this.get('_currentlyExecutedFunction'));
    },

    _schedule: function _schedule(context, func, args) {
      return _ember['default'].run.later(this, function () {
        this.set('_currentlyExecutedFunction', this._schedule(context, func, args));
        func.apply(context, args);
      }, this.get('_interval'));
    },

    setInterval: function setInterval(interval) {
      this.set('_interval', interval);
    }

  });

  exports['default'] = Poller;
});