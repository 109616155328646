define('incus-vastaanota/pods/tools/index/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    session: _ember['default'].inject.service(),
    apixclient: _ember['default'].inject.service(),

    beforeModel: function beforeModel(param) {
      console.log("BEFORE");
      var items = this.get("session").get("showItems");
      if (items && items.tools) {
        this.transitionTo("tools.settings");
      } else if (items && items.buy) {
        this.transitionTo("archives.received");
      } else if (items && items.sell) {
        this.transitionTo("archives.sent");
      }
      /*     let userData = this.get("session").get("user")[0];
          this.get("apixclient").listUsers(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then((data) => {
            console.log("DATA", data)
          }).catch(error => {
            console.log(error)
          }) */
    },
    afterModel: function afterModel() {
      console.log("AFTER");
    }
  });
});