define("incus-vastaanota/pods/tools/validation/route", ["exports", "ember", "incus-vastaanota/utils/email", "incus-vastaanota/config/environment"], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment) {
  exports["default"] = _ember["default"].Route.extend({
    apixclient: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),
    i18n: _ember["default"].inject.service(),

    model: function model() {
      var model = _ember["default"].Object.extend({
        fields: [{
          fieldName: "invoiceNumber",
          numeric: false,
          sortable: true
        }, {
          fieldName: "FV Version",
          numeric: false,
          sortable: false
        }, {
          fieldName: "Valid",
          numeric: false,
          sortable: false
        }, {
          fieldName: "Errors",
          numeric: false,
          sortable: false
        }],
        fileList: null,
        responses: null
      }).create({
        fileList: [],
        responses: []
      });
      return model;
    },

    actions: {

      fileChanged: function fileChanged(file_item) {
        var _this = this;

        console.log(["fileChanged", file_item]);
        var model = this.get("controller.model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        model.set("responses", []);

        file_item.send_status = "notsent";
        file_item.freetext = "";

        var files = this.get("controller.model.fileList");
        files.pushObject(file_item);

        var b = new Uint8Array(file_item.data);
        var string = new TextDecoder("ISO-8859-15").decode(b);
        //console.log(file_item.data, string);_make_deliverymethod_query

        if (file_item.type === "text/xml") {
          var invoices = this._split_invoices(string);
          //console.log(invoices);
          file_item.invoices = invoices;
        } else if (file_item.type === "application/x-zip-compressed" || file_item.type === "application/zip") {
          var zip = new JSZip();
          zip.load(file_item.data);

          var keys = Object.keys(zip.files);

          for (var i = keys.length - 1; i >= 0; i--) {
            if (keys[i].match(/\.(xml|XML)$/)) {
              //console.log(keys[i]);

              var xml_uint8_object = zip.file(keys[i]).asUint8Array();

              //console.log(["AA", xml_uint8_object]);

              //let b = new Uint8Array(xml_file_object);
              var _string = new TextDecoder("ISO-8859-15").decode(xml_uint8_object);
              var invoices = this._split_invoices(_string);
              console.log(invoices);
              file_item.invoices = invoices;
              break;
            }
          }

          console.log(zip);
        }

        if (file_item.invoices) {
          (function () {
            var invoices = file_item.invoices;

            var _loop = function (i) {
              //console.log(invoices[i]);

              apixclient.ValidateFinvoice(userData, invoices[i].finvoice).then(function (data) {
                var meta = _this._harvest_finvoice_details(invoices[i].finvoice);
                //console.log([meta, data]);

                var result = {
                  invoice_number: meta.invoice_number,
                  valid: data.valid,
                  version: data.version,
                  errors: data.errors
                };

                console.log(["result", result]);
                model.get("responses").pushObject(result);
              });
            };

            for (var i = invoices.length - 1; i >= 0; i--) {
              _loop(i);
            }
          })();
        }
      }

    },

    _harvest_finvoice_details: function _harvest_finvoice_details(finvoice_string) {
      var convmap2 = {
        "195:133": "197", // Å
        "195:165": "229", // å
        "195:132": "196", // Ä
        "195:8364": "228", // ä
        "195:150": "214", // Ö
        "195:182": "246" // ö
      };

      var safe_get_element_value = function safe_get_element_value(element, element_tag) {
        try {
          var _ret3 = (function () {
            var value = element.getElementsByTagName(element_tag)[0].childNodes[0].nodeValue;

            try {
              value = decodeURIComponent(window.escape(value));
            } catch (e) {}

            var probably_utf8 = false;
            var asciiKeys = [];
            for (var i = 0; i < value.length; i++) {
              var ascii = value[i].charCodeAt(0);
              asciiKeys.push(ascii);
              if (ascii >= 195) {
                probably_utf8 = true;
              }
            }

            var asciicr = asciiKeys.join(":");

            if (probably_utf8) {
              var keys = Object.keys(convmap2);

              keys.map(function (key) {
                var value = convmap2[key];
                asciicr = asciicr.replace(new RegExp(key, "g"), value);
              });

              var pieces = asciicr.split(":");
              pieces = pieces.map(function (elem) {
                return String.fromCharCode(parseInt(elem));
              });
              value = pieces.join("");
            }

            return {
              v: value
            };
          })();

          if (typeof _ret3 === "object") return _ret3.v;
        } catch (e) {
          console.log(["fv safe_get_element_value error", element, e]);
          return null;
        }
      };

      var parser = new window.DOMParser();
      var meta = {};
      try {
        var xdoc = parser.parseFromString(finvoice_string, "text/xml");

        meta.einvoice_address = safe_get_element_value(xdoc, "ToIdentifier");
        meta.operator = safe_get_element_value(xdoc, "ToIntermediator");

        var s = {
          bid: safe_get_element_value(xdoc, "SellerPartyIdentifier"),
          name: safe_get_element_value(xdoc, "SellerOrganisationName"),
          ovt: safe_get_element_value(xdoc, "SellerOrganisationUnitNumber")
        };
        meta.seller = s;

        // if InvoiceRecipient
        if (safe_get_element_value(xdoc, "InvoiceRecipientOrganisationName")) {
          var ir = {
            bid: safe_get_element_value(xdoc, "InvoiceRecipientPartyIdentifier"),
            name: safe_get_element_value(xdoc, "InvoiceRecipientOrganisationName"),
            ovt: safe_get_element_value(xdoc, "InvoiceRecipientOrganisationUnitNumber")
          };

          meta.receiver = ir;
        } else {
          var b = {
            bid: safe_get_element_value(xdoc, "BuyerPartyIdentifier"),
            name: safe_get_element_value(xdoc, "BuyerOrganisationName"),
            ovt: safe_get_element_value(xdoc, "BuyerOrganisationUnitNumber")
          };
          meta.receiver = b;
        }

        meta.invoice_number = safe_get_element_value(xdoc, "InvoiceNumber");
        meta.invoice_date = safe_get_element_value(xdoc, "EpiDate");
        meta.due_date = safe_get_element_value(xdoc, "EpiDateOptionDate");
        meta.invoice_sum = safe_get_element_value(xdoc, "EpiInstructedAmount");
      } catch (e) {
        console.error([finvoice_string, meta, e]);
      }

      meta.deliverymethod = "unknown";

      return meta;
    },

    _split_invoices: function _split_invoices(xml_string) {
      var invoices_with_soap = xml_string.split("</Finvoice>");

      var out_invoices = [];

      for (var i = 0; i < invoices_with_soap.length; i++) {
        console.log(["invoices_with_soap[i]", i, invoices_with_soap[i]]);

        var current_invoice = invoices_with_soap[i];

        if (current_invoice.replace(/\s+/, "").length > 0) {
          current_invoice += "</Finvoice>";

          var pieces = current_invoice.split("<?xml ");
          var soap = pieces[0];
          var finvoice = "<?xml " + pieces[1];

          if (soap == "") {
            soap = null;
          }

          out_invoices.pushObject({
            soap: soap,
            finvoice: finvoice,
            uuid: this._generateUUID()
          });
        }
      }

      return out_invoices;
    },

    _generateUUID: function _generateUUID() {
      var d = new Date().getTime();
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    }

  });
});