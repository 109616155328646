define('incus-vastaanota/models/comment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    commentNumber: null,
    timeStamp: null,
    approvalStatus: null,
    email: null,
    content: null,
    originalString: null,

    formattedTimeStamp: (function () {
      var date = moment(this.get('timeStamp'), 'YYYYMMDDHHmmss');
      return date.format('DD.MM.YYYY');
    }).property('timeStamp'),

    // comment.0: "20150612071553   POSITIVE    pekka.malli@malli.fi    Testikommentti 1"
    parseComment: function parseComment(commentNumber, data) {
      this.set("commentNumber", commentNumber);
      this.set("originalString", data);
      var re = /^(\d+)\t(POSITIVE|NEGATIVE|NEUTRAL)\t([^\t]*)\t([\s\S]*)$/m;
      var result = re.exec(data);
      if (result && result[0]) {
        this.set("timeStamp", result[1]);
        this.set("approvalStatus", result[2]);
        this.set("email", result[3]);
        this.set("content", result[4]);
      } else {
        _ember['default'].Logger.log("String not recognised as a comment: " + data);
      }
    }

  });
});