define("incus-vastaanota/models/letterlist", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Object.extend({
		name: null,
		date: null,
		document_amount: null,
		page_amount: null,
		uuid: null,
		group: null,
		sent_date: null,
		sent_amount: null,

		setFields: function setFields(values) {
			for (var key in values) {
				if (values.hasOwnProperty(key)) {
					this.set(key, values[key]);
				}
			}
		},

		toJSON: function toJSON() {
			var json_object = {};

			json_object.name = this.get("name");
			json_object.group = this.get("group");
			json_object.date = this.get("date");
			json_object.document_amount = this.get("document_amount");
			json_object.page_amount = this.get("page_amount");
			json_object.uuid = this.get("uuid");
			json_object.sent_date = this.get("sent_date");
			json_object.sent_amount = this.get("sent_amount");

			return JSON.stringify(json_object);
		}

	});
});