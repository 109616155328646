define('incus-vastaanota/pods/invoice/create/sell/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    countryselector: _ember['default'].inject.service(),

    model: function model(params) {
      var countryselector = this.get("countryselector");
      console.log(countryselector);
      var model = _ember['default'].Object.extend({
        countryselector: countryselector.url
      }).create();
      return model;
    }
  });
});