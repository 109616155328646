define("incus-vastaanota/components/my-select", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "span",

    // possible passed-in values with their defaults:
    content: null,
    prompt: null,
    optionValuePath: 'id',
    optionLabelPath: 'title',
    translationField: null,
    action: _ember["default"].K, // action to fire on change

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },

    actions: {
      change: function change() {
        var selectEl = this.$('select')[0];
        var selectedIndex = selectEl.selectedIndex;
        var content = this.get('content');

        // decrement index by 1 if we have a prompt
        var hasPrompt = !!this.get('prompt');
        var contentIndex = hasPrompt ? selectedIndex - 1 : selectedIndex;

        var selection = content[contentIndex];

        this.set('selection', selection);

        var changeCallback = this.get('action');
        if (changeCallback) {
          changeCallback(selection);
        }
      }
    }

  });
});