define('incus-vastaanota/components/pfile-input', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].TextField.extend({
        type: 'file',
        inputData: null,

        change: function change(evt) {
            var _File2ArrayPromise = function _File2ArrayPromise(input) {
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    var reader = new FileReader();

                    reader.onloadstart = function (e) {
                        // TODO Should we display 'file uploading'?
                    };
                    reader.onload = function (e) {
                        var data = e.target.result;
                        resolve(data);
                    };

                    reader.readAsArrayBuffer(input);
                });
            };

            var input = evt.target;

            if (input.files && input.files[0]) {
                //let oldContext = this;

                _ember['default'].Logger.log(input.files);
                this.sendAction('fileChanged', input.files[0].name, input.files[0].type, input.files[0].size, _File2ArrayPromise(input.files[0]));

                var e = _ember['default'].$("#" + input.id);
                //console.log(e);
                e.wrap('<form>').closest('form').get(0).reset();
                e.unwrap();
            }
        }
    });
});