define('incus-vastaanota/pods/traffic/sent/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      test: function test(a) {
        var model = this.get('model');
      }

    }

  });
});