define("incus-vastaanota/pods/tools/users/controller", ["exports", "ember"], function (exports, _ember) {
    exports["default"] = _ember["default"].Controller.extend({
        apixclient: _ember["default"].inject.service(),
        session: _ember["default"].inject.service(),
        i18n: _ember["default"].inject.service(),
        actions: {
            edit: function edit(user) {
                var model = this.get("model");

                model.set("editing", user);
                model.set("editUsername", user.username);
                model.set("editPass", "");
                model.set("editAllowSell", user.createSellInvoice);
                model.set("editAllowPurchase", user.createPurchaseInvoice);
            },
            add: function add() {
                var _this = this;

                var model = this.get("model");
                var arr = model.get("users");
                var userData = this.get("session").get("user")[0];
                var i18n = this.get("i18n");
                var newUser = {
                    username: model.get("newUsername").trim(),
                    password: model.get("newPass").trim(),
                    createPurchaseInvoice: model.get("newAllowPurchase"),
                    createSellInvoice: model.get("newAllowSell")
                };
                this.get("apixclient").addSubuser(userData, newUser, false).then(function (response) {
                    if (response && response.success) {
                        arr.unshiftObject(newUser);
                        model.set("newPass", '');
                        model.set("newUsername", '');
                    } else {
                        _this.get("notify").info(i18n.t("tools.users.usernameInUse").toString());
                    }
                })["catch"](function (error) {
                    console.log(error);
                });
            },

            cancel: function cancel() {
                var model = this.get("model");
                model.set("editing", null);
            },
            "delete": function _delete() {
                var userData = this.get("session").get("user")[0];
                var id = this.get("model.editing.idSubUser");
                var user = this.get("model.editing");
                var arr = this.get("model.users");
                this.get("apixclient").deleteSubuser(userData, id, false).then(function (response) {
                    if (response && response.success) {
                        arr.removeObject(user);
                    }
                })["catch"](function (error) {
                    console.log(error);
                });
            },
            save: function save(user) {
                var _this2 = this;

                var i18n = this.get("i18n");
                var model = this.get("model");
                var userData = this.get("session").get("user")[0];
                var newUser = {
                    createPurchaseInvoice: model.get("editAllowPurchase"),
                    createSellInvoice: model.get("editAllowSell"),
                    idSubUser: user.idSubUser,
                    password: model.get("editPass"),
                    username: user.username
                };

                this.get("apixclient").updateSubuser(userData, newUser, false).then(function (response) {
                    if (response && response.success) {
                        _ember["default"].set(user, "createPurchaseInvoice", newUser.createPurchaseInvoice);
                        _ember["default"].set(user, "createSellInvoice", newUser.createSellInvoice);
                        model.set("editing", null);
                        _this2.get("notify").info(i18n.t("settings.userInfo.updatedUser").toString());
                    } else {
                        _this2.get("notify").info(i18n.t("settings.userInfo.updateUserFailed").toString());
                    }
                })["catch"](function (error) {
                    console.log(error);
                    _this2.get("notify").info(i18n.t("settings.userInfo.updateUserFailed").toString());
                });
            }

        },
        _requestDataString: function _requestDataString(userData, data, addresses) {
            var _this3 = this;

            var doc = window.document.implementation.createDocument(null, "Request", null);
            var request = doc.firstChild;
            request.setAttribute("version", "1.0");
            var content = doc.createElement("Content");
            var group = doc.createElement("Group");

            Object.keys(data).forEach(function (key) {
                console.log(key);

                group.appendChild(_this3._valueElement(doc, key, data[key]));
            });
            group.appendChild(this._valueElement(doc, "y-tunnus", userData.Ytunnus));
            group.appendChild(this._valueElement(doc, "Email", userData.Email));
            group.appendChild(this._valueElement(doc, "Language", userData.LanguageCoded));
            content.appendChild(group);

            addresses.forEach(function (address) {
                var isEmpty = true;
                Object.keys(address).forEach(function (key) {
                    if (address[key] && key !== "AddressType") {
                        isEmpty = false;
                    }
                });
                if (!isEmpty) {
                    group = doc.createElement("Group");
                    Object.keys(address).forEach(function (key) {
                        group.appendChild(_this3._valueElement(doc, key, address[key]));
                    });
                    content.appendChild(group);
                }
            });
            request.appendChild(content);
            var xs = new XMLSerializer();
            var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
            //header = "";
            console.log(header + xs.serializeToString(doc));
            return header + xs.serializeToString(doc);
        },
        _valueElement: function _valueElement(doc, type, text) {
            var el = doc.createElement("Value");
            el.setAttribute("type", type);
            el.textContent = text;
            return el;
        }
    });
});