define('incus-vastaanota/pods/tools/addresses/view/route', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        apixclient: _ember['default'].inject.service(),
        i18n: _ember['default'].inject.service(),
        session: _ember['default'].inject.service(),
        countryselector: _ember['default'].inject.service(),

        model: function model(param) {
            var _this = this;

            var locale = undefined;
            if (window.localStorage) {
                locale = window.localStorage.getItem('locale');
            }
            var otherLocale = undefined;
            switch (locale) {
                case "en":
                    otherLocale = "Other";
                    break;
                case "sv":
                    otherLocale = "Annan";
                    break;
                case "fi":
                    otherLocale = "Muu";
                    break;
                default:
                    otherLocale = "Muu";
                    break;
            }
            var bid = this.get("session.user")[0].Ytunnus.replace("-", "");
            var vatId = "SE" + bid + "01";
            var isLaskumappi = this.get("countryselector.url") === "laskumappi";

            var operators = [];
            if (!isLaskumappi) {

                operators = ["", "Basware", "Bankgirocentralen", "CGISE", "Crediflow", "Enfo", "Evry", "InExchange", "Lexmark", "Nordea", "OpusCapita", "Pagero", "Palette", "ScanCloud", "Strålfors", "Tieto", "VismaProceedo", otherLocale];
            } else {
                operators = ["", "Aktia", "Basware", "Bankgirocentralen", "CGI", "Danskebank", "Handelsbanken", "HighJump", "InExchange", "Lexmark", "Liaison", "Maventa", "Netbox", "Nordea", "OpusCapita", "Osuuspankki", "Pagero", "Palette", "POP Pankki", "Proceedo", "Ropo Capital", "ScanCloud", "Säästöpankki", "S-Pankki", "Stralfors", "TeliaSonera", "Tieto", "Tradeshift", "YAP Solutions", "Ålandsbanken", otherLocale];
            }

            var model = _ember['default'].Object.extend({
                address: '',
                feedback: false,
                isNotChecked: true,

                sendNotification: false,
                isEmailNotificationDisabled: _ember['default'].computed.not('sendNotification'),
                emailToNotificate: '',
                isLaskumappi: isLaskumappi,
                bid: bid,
                vatId: vatId,
                addressToAdd: '',
                companyName: '',
                Ytunnus: '',
                commentField: '',
                emailScanAddr: '',
                eTag: '',

                queryYtunnus: '',
                queryResults: [],

                selectedOperator: '',
                other: '',
                operators: operators,
                //["", "Aktia", "Basware", "Bankgirocentralen", "Danskebank",  "Enfo", "Handelsbanken", "HighJump",  "InExchange", "Itella", "Lexmark", "Liaison", "Logica", "Maventa", "Netbox", "Nordea", "Osuuspankki",
                //            "Pagero", "Palette", "POP Pankki", "Proceedo", "ScanCloud", "Säästöpankki", "S-Pankki", "Stralfors", "TeliaSonera", "Tieto", "Tradeshift", "YAP Solutions", "Ålandsbanken", otherLocale],

                isOtherNotSelected: _ember['default'].computed('selectedOperator', function () {
                    return _this.get('controller.model.selectedOperator') !== otherLocale;
                }),
                isQueryDisabled: _ember['default'].computed('queryYtunnus', function () {
                    return !_this.get('controller.model.queryYtunnus');
                }),

                isDisabled: _ember['default'].computed('addressToAdd', 'companyName', 'selectedOperator', 'Ytunnus', 'isOtherSelected', 'other', 'sendNotification', 'isEmailNotificationDisbled', 'emailToNotificate', function () {
                    var boolean = _this.get('controller.model.addressToAdd') && _this.get('controller.model.companyName') && _this.get('controller.model.selectedOperator') && _this.get('controller.model.Ytunnus');
                    if (!_this.get('controller.model.isOtherNotSelected')) {
                        boolean = boolean && _this.get('controller.model.other');
                    }
                    if (_this.get('controller.model.sendNotification')) {
                        boolean = boolean && _this.get('controller.model.emailToNotificate');
                    }
                    return !boolean;
                })
            }).create();
            return model;
        },
        afterModel: function afterModel() {
            var _this2 = this;

            if (!this.get("model.isLaskumappi")) {
                var userData = this.get("session").get("user")[0];
                this.get("apixclient").listContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID).then(function (data) {
                    var contract = data.data.find(function (c) {
                        return c.ContractType === "eTag";
                    });
                    if (contract && contract.ContractStatus === "Active") {
                        _this2.set("controller.model.eTag", contract.Parameters);
                    }

                    contract = data.data.find(function (contract) {
                        return contract.ContractType === "EMailScan";
                    });
                    if (contract && contract.ContractStatus === "Active") {
                        _this2.set("controller.model.emailScanAddr", contract.Parameters);
                    }
                })['catch'](function (error) {
                    console.log(error);
                });
            }
        },

        actions: {
            checkeInvoiceAddress: function checkeInvoiceAddress() {
                var _this3 = this;

                var apixclient = this.get("apixclient");
                var userData = this.get("session").get("user")[0];
                var address = this.get("controller.model.address").trim();

                this.set('queryDone', false);

                apixclient.checkReceivereInvoiceAddress(userData.Ytunnus, userData.Name, userData.TransferID, userData.TransferKey, address).then(function (data) {
                    _this3.set("controller.model.isNotChecked", false);
                    _this3.set("controller.model.address", '');
                    if (data.data[0].ChannelName === "eInvoice") {
                        _this3.set("controller.model.feedback", true);
                    } else {
                        _this3.set("controller.model.feedback", false);
                    }
                });
            },

            queryAddress: function queryAddress() {
                var _this4 = this;

                var apixclient = this.get("apixclient");

                var userData = this.get("session").get("user")[0];
                var queryYtunnus = this.get("controller.model.queryYtunnus").trim();

                this.set("controller.model.queryYtunnus", '');
                this.set('queryDone', false);
                apixclient.queryAddress(queryYtunnus, userData.TransferID, userData.TransferKey).then(function (data) {
                    if (data.data[0].ReceivereInvoiceAddress) {
                        _this4.set("controller.model.queryResults", data.data);
                    } else {
                        _this4.set("controller.model.queryResults", []);
                    }
                });
            }
        }
    });
});