define('incus-vastaanota/pods/traffic/index/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
    exports['default'] = _ember['default'].Route.extend({
        session: _ember['default'].inject.service(),

        beforeModel: function beforeModel(param) {
            var items = this.get("session").get("showItems");

            if (this.get("session.laskumappiSkin") === "financelink") {
                this.transitionTo("traffic.sent");
            } else if (items && items.sell) {
                this.transitionTo("traffic.pitstop.list");
            } else if (items && items.buy) {
                this.transitionTo("traffic.received");
            }
        }

    });
});