define('incus-vastaanota/pods/registration/info/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    i18n: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    model: function model() {
      var countryselector = this.get("countryselector");
      return _ember['default'].Object.extend({
        pricingUrl: _incusVastaanotaConfigEnvironment['default'].clientHostRoot + '/#/pricing',
        countryselector: countryselector
      }).create();
    },

    actions: {
      setLang: function setLang(lang) {
        _ember['default'].Logger.log(lang);
        this.set("i18n.locale", lang);
        if (!window.localStorageAlias) {
          window.localStorageAlias = window.localStorage;
          if (!window.localStorage) {
            window.localStorageAlias = {};
            window.localStorageAlias.removeItem = function () {};
            window.localStorageAlias.getItem = function (item) {
              return this[item];
            };
            window.localStorageAlias.setItem = function (item, value) {
              this[item] = value;
            };
          }
        }
        window.localStorageAlias.setItem('locale', lang);
      },
      showPrice: function showPrice() {
        console.log(this.get("controller.model.showPrices"));
        this.set("controller.model.showPrices", !this.get("controller.model.showPrices"));
      },
      startRegistration: function startRegistration(identifier) {
        _ember['default'].Logger.log("startRegistration");
        this.transitionTo("registration.form");
      }
    }
  });
});