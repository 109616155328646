define("incus-vastaanota/utils/countrycodes", ["exports"], function (exports) {
   exports["default"] = CountryCodes;
   /* eslint-disable */

   //module.exports = function() {

   function CountryCodes(locale) {

      var codes = {
         "en": [{
            "name": "Select country",
            "value": ""
         }, {
            "name": "Afghanistan",
            "value": "AF"
         }, {
            "name": "Åland Islands",
            "value": "AX"
         }, {
            "name": "Albania",
            "value": "AL"
         }, {
            "name": "Algeria",
            "value": "DZ"
         }, {
            "name": "American Samoa",
            "value": "AS"
         }, {
            "name": "AndorrA",
            "value": "AD"
         }, {
            "name": "Angola",
            "value": "AO"
         }, {
            "name": "Anguilla",
            "value": "AI"
         }, {
            "name": "Antarctica",
            "value": "AQ"
         }, {
            "name": "Antigua and Barbuda",
            "value": "AG"
         }, {
            "name": "Argentina",
            "value": "AR"
         }, {
            "name": "Armenia",
            "value": "AM"
         }, {
            "name": "Aruba",
            "value": "AW"
         }, {
            "name": "Australia",
            "value": "AU"
         }, {
            "name": "Austria",
            "value": "AT"
         }, {
            "name": "Azerbaijan",
            "value": "AZ"
         }, {
            "name": "Bahamas",
            "value": "BS"
         }, {
            "name": "Bahrain",
            "value": "BH"
         }, {
            "name": "Bangladesh",
            "value": "BD"
         }, {
            "name": "Barbados",
            "value": "BB"
         }, {
            "name": "Belarus",
            "value": "BY"
         }, {
            "name": "Belgium",
            "value": "BE"
         }, {
            "name": "Belize",
            "value": "BZ"
         }, {
            "name": "Benin",
            "value": "BJ"
         }, {
            "name": "Bermuda",
            "value": "BM"
         }, {
            "name": "Bhutan",
            "value": "BT"
         }, {
            "name": "Bolivia",
            "value": "BO"
         }, {
            "name": "Bosnia and Herzegovina",
            "value": "BA"
         }, {
            "name": "Botswana",
            "value": "BW"
         },
         /*     {
                 "name": "Bouvet Island",
                 "value": "BV"
             }, */
         {
            "name": "Brazil",
            "value": "BR"
         },
         /*   {
             "name": "British Indian Ocean Territory",
             "value": "IO"
         }, */
         {
            "name": "Brunei Darussalam",
            "value": "BN"
         }, {
            "name": "Bulgaria",
            "value": "BG"
         }, {
            "name": "Burkina Faso",
            "value": "BF"
         }, {
            "name": "Burundi",
            "value": "BI"
         }, {
            "name": "Cambodia",
            "value": "KH"
         }, {
            "name": "Cameroon",
            "value": "CM"
         }, {
            "name": "Canada",
            "value": "CA"
         }, {
            "name": "Cape Verde",
            "value": "CV"
         }, {
            "name": "Cayman Islands",
            "value": "KY"
         }, {
            "name": "Central African Republic",
            "value": "CF"
         }, {
            "name": "Chad",
            "value": "TD"
         }, {
            "name": "Chile",
            "value": "CL"
         }, {
            "name": "China",
            "value": "CN"
         }, {
            "name": "Christmas Island",
            "value": "CX"
         },
         /*     {
                 "name": "Cocos (Keeling) Islands",
                 "value": "CC"
             }, */
         {
            "name": "Colombia",
            "value": "CO"
         }, {
            "name": "Comoros",
            "value": "KM"
         }, {
            "name": "Congo",
            "value": "CG"
         }, {
            "name": "Congo, The Democratic Republic of the",
            "value": "CD"
         },
         /*     {
                 "name": "Cook Islands",
                 "value": "CK"
             }, */
         {
            "name": "Costa Rica",
            "value": "CR"
         }, {
            "name": "Cote D\"Ivoire",
            "value": "CI"
         }, {
            "name": "Croatia",
            "value": "HR"
         }, {
            "name": "Cuba",
            "value": "CU"
         }, {
            "name": "Cyprus",
            "value": "CY"
         }, {
            "name": "Czech Republic",
            "value": "CZ"
         }, {
            "name": "Denmark",
            "value": "DK"
         }, {
            "name": "Djibouti",
            "value": "DJ"
         }, {
            "name": "Dominica",
            "value": "DM"
         }, {
            "name": "Dominican Republic",
            "value": "DO"
         }, {
            "name": "Ecuador",
            "value": "EC"
         }, {
            "name": "Egypt",
            "value": "EG"
         }, {
            "name": "El Salvador",
            "value": "SV"
         }, {
            "name": "Equatorial Guinea",
            "value": "GQ"
         }, {
            "name": "Eritrea",
            "value": "ER"
         }, {
            "name": "Estonia",
            "value": "EE"
         }, {
            "name": "Ethiopia",
            "value": "ET"
         }, {
            "name": "Falkland Islands (Malvinas)",
            "value": "FK"
         }, {
            "name": "Faroe Islands",
            "value": "FO"
         }, {
            "name": "Fiji",
            "value": "FJ"
         }, {
            "name": "Finland",
            "value": "FI"
         }, {
            "name": "France",
            "value": "FR"
         }, {
            "name": "French Guiana",
            "value": "GF"
         }, {
            "name": "French Polynesia",
            "value": "PF"
         }, {
            "name": "French Southern Territories",
            "value": "TF"
         }, {
            "name": "Gabon",
            "value": "GA"
         }, {
            "name": "Gambia",
            "value": "GM"
         }, {
            "name": "Georgia",
            "value": "GE"
         }, {
            "name": "Germany",
            "value": "DE"
         }, {
            "name": "Ghana",
            "value": "GH"
         }, {
            "name": "Gibraltar",
            "value": "GI"
         }, {
            "name": "Greece",
            "value": "GR"
         }, {
            "name": "Greenland",
            "value": "GL"
         }, {
            "name": "Grenada",
            "value": "GD"
         }, {
            "name": "Guadeloupe",
            "value": "GP"
         }, {
            "name": "Guam",
            "value": "GU"
         }, {
            "name": "Guatemala",
            "value": "GT"
         }, {
            "name": "Guernsey",
            "value": "GG"
         }, {
            "name": "Guinea",
            "value": "GN"
         }, {
            "name": "Guinea-Bissau",
            "value": "GW"
         }, {
            "name": "Guyana",
            "value": "GY"
         }, {
            "name": "Haiti",
            "value": "HT"
         },
         /*     {
                 "name": "Heard Island and Mcdonald Islands",
                 "value": "HM"
             }, */
         {
            "name": "Holy See (Vatican City State)",
            "value": "VA"
         }, {
            "name": "Honduras",
            "value": "HN"
         }, {
            "name": "Hong Kong",
            "value": "HK"
         }, {
            "name": "Hungary",
            "value": "HU"
         }, {
            "name": "Iceland",
            "value": "IS"
         }, {
            "name": "India",
            "value": "IN"
         }, {
            "name": "Indonesia",
            "value": "ID"
         }, {
            "name": "Iran, Islamic Republic Of",
            "value": "IR"
         }, {
            "name": "Iraq",
            "value": "IQ"
         }, {
            "name": "Ireland",
            "value": "IE"
         },
         /*     {
                 "name": "Isle of Man",
                 "value": "IM"
             }, */
         {
            "name": "Israel",
            "value": "IL"
         }, {
            "name": "Italy",
            "value": "IT"
         }, {
            "name": "Jamaica",
            "value": "JM"
         }, {
            "name": "Japan",
            "value": "JP"
         }, {
            "name": "Jersey",
            "value": "JE"
         }, {
            "name": "Jordan",
            "value": "JO"
         }, {
            "name": "Kazakhstan",
            "value": "KZ"
         }, {
            "name": "Kenya",
            "value": "KE"
         }, {
            "name": "Kiribati",
            "value": "KI"
         }, {
            "name": "Korea, Democratic People\"S Republic of",
            "value": "KP"
         }, {
            "name": "Korea, Republic of",
            "value": "KR"
         }, {
            "name": "Kuwait",
            "value": "KW"
         }, {
            "name": "Kyrgyzstan",
            "value": "KG"
         }, {
            "name": "Lao People\"S Democratic Republic",
            "value": "LA"
         }, {
            "name": "Latvia",
            "value": "LV"
         }, {
            "name": "Lebanon",
            "value": "LB"
         }, {
            "name": "Lesotho",
            "value": "LS"
         }, {
            "name": "Liberia",
            "value": "LR"
         }, {
            "name": "Libyan Arab Jamahiriya",
            "value": "LY"
         }, {
            "name": "Liechtenstein",
            "value": "LI"
         }, {
            "name": "Lithuania",
            "value": "LT"
         }, {
            "name": "Luxembourg",
            "value": "LU"
         }, {
            "name": "Macao",
            "value": "MO"
         }, {
            "name": "Macedonia, The Former Yugoslav Republic of",
            "value": "MK"
         }, {
            "name": "Madagascar",
            "value": "MG"
         }, {
            "name": "Malawi",
            "value": "MW"
         }, {
            "name": "Malaysia",
            "value": "MY"
         }, {
            "name": "Maldives",
            "value": "MV"
         }, {
            "name": "Mali",
            "value": "ML"
         }, {
            "name": "Malta",
            "value": "MT"
         }, {
            "name": "Marshall Islands",
            "value": "MH"
         }, {
            "name": "Martinique",
            "value": "MQ"
         }, {
            "name": "Mauritania",
            "value": "MR"
         }, {
            "name": "Mauritius",
            "value": "MU"
         }, {
            "name": "Mayotte",
            "value": "YT"
         }, {
            "name": "Mexico",
            "value": "MX"
         }, {
            "name": "Micronesia, Federated States of",
            "value": "FM"
         }, {
            "name": "Moldova, Republic of",
            "value": "MD"
         }, {
            "name": "Monaco",
            "value": "MC"
         }, {
            "name": "Mongolia",
            "value": "MN"
         }, {
            "name": "Montserrat",
            "value": "MS"
         }, {
            "name": "Morocco",
            "value": "MA"
         }, {
            "name": "Mozambique",
            "value": "MZ"
         }, {
            "name": "Myanmar",
            "value": "MM"
         }, {
            "name": "Namibia",
            "value": "NA"
         }, {
            "name": "Nauru",
            "value": "NR"
         }, {
            "name": "Nepal",
            "value": "NP"
         }, {
            "name": "Netherlands",
            "value": "NL"
         }, {
            "name": "Netherlands Antilles",
            "value": "AN"
         }, {
            "name": "New Caledonia",
            "value": "NC"
         }, {
            "name": "New Zealand",
            "value": "NZ"
         }, {
            "name": "Nicaragua",
            "value": "NI"
         }, {
            "name": "Niger",
            "value": "NE"
         }, {
            "name": "Nigeria",
            "value": "NG"
         }, {
            "name": "Niue",
            "value": "NU"
         }, {
            "name": "Norfolk Island",
            "value": "NF"
         },
         /*     {
                 "name": "Northern Mariana Islands",
                 "value": "MP"
             }, */
         {
            "name": "Norway",
            "value": "NO"
         }, {
            "name": "Oman",
            "value": "OM"
         }, {
            "name": "Pakistan",
            "value": "PK"
         }, {
            "name": "Palau",
            "value": "PW"
         },
         /*    {
             "name": "Palestinian Territory, Occupied",
             "value": "PS"
         }, */
         {
            "name": "Panama",
            "value": "PA"
         }, {
            "name": "Papua New Guinea",
            "value": "PG"
         }, {
            "name": "Paraguay",
            "value": "PY"
         }, {
            "name": "Peru",
            "value": "PE"
         }, {
            "name": "Philippines",
            "value": "PH"
         }, {
            "name": "Pitcairn",
            "value": "PN"
         }, {
            "name": "Poland",
            "value": "PL"
         }, {
            "name": "Portugal",
            "value": "PT"
         }, {
            "name": "Puerto Rico",
            "value": "PR"
         }, {
            "name": "Qatar",
            "value": "QA"
         }, {
            "name": "Reunion",
            "value": "RE"
         }, {
            "name": "Romania",
            "value": "RO"
         }, {
            "name": "Russian Federation",
            "value": "RU"
         }, {
            "name": "RWANDA",
            "value": "RW"
         }, {
            "name": "Saint Helena",
            "value": "SH"
         }, {
            "name": "Saint Kitts and Nevis",
            "value": "KN"
         }, {
            "name": "Saint Lucia",
            "value": "LC"
         }, {
            "name": "Saint Pierre and Miquelon",
            "value": "PM"
         }, {
            "name": "Saint Vincent and the Grenadines",
            "value": "VC"
         }, {
            "name": "Samoa",
            "value": "WS"
         }, {
            "name": "San Marino",
            "value": "SM"
         }, {
            "name": "Sao Tome and Principe",
            "value": "ST"
         }, {
            "name": "Saudi Arabia",
            "value": "SA"
         }, {
            "name": "Senegal",
            "value": "SN"
         }, {
            "name": "Serbia and Montenegro",
            "value": "CS"
         }, {
            "name": "Seychelles",
            "value": "SC"
         }, {
            "name": "Sierra Leone",
            "value": "SL"
         }, {
            "name": "Singapore",
            "value": "SG"
         }, {
            "name": "Slovakia",
            "value": "SK"
         }, {
            "name": "Slovenia",
            "value": "SI"
         }, {
            "name": "Solomon Islands",
            "value": "SB"
         }, {
            "name": "Somalia",
            "value": "SO"
         }, {
            "name": "South Africa",
            "value": "ZA"
         },
         /*  {
             "name": "South Georgia and the South Sandwich Islands",
             "value": "GS"
         }, */
         {
            "name": "Spain",
            "value": "ES"
         }, {
            "name": "Sri Lanka",
            "value": "LK"
         }, {
            "name": "Sudan",
            "value": "SD"
         }, {
            "name": "Suriname",
            "value": "SR"
         }, {
            "name": "Svalbard and Jan Mayen",
            "value": "SJ"
         }, {
            "name": "Swaziland",
            "value": "SZ"
         }, {
            "name": "Sweden",
            "value": "SE"
         }, {
            "name": "Switzerland",
            "value": "CH"
         }, {
            "name": "Syrian Arab Republic",
            "value": "SY"
         }, {
            "name": "Taiwan, Province of China",
            "value": "TW"
         }, {
            "name": "Tajikistan",
            "value": "TJ"
         }, {
            "name": "Tanzania, United Republic of",
            "value": "TZ"
         }, {
            "name": "Thailand",
            "value": "TH"
         }, {
            "name": "Timor-Leste",
            "value": "TL"
         }, {
            "name": "Togo",
            "value": "TG"
         }, {
            "name": "Tokelau",
            "value": "TK"
         }, {
            "name": "Tonga",
            "value": "TO"
         }, {
            "name": "Trinidad and Tobago",
            "value": "TT"
         }, {
            "name": "Tunisia",
            "value": "TN"
         }, {
            "name": "Turkey",
            "value": "TR"
         }, {
            "name": "Turkmenistan",
            "value": "TM"
         }, {
            "name": "Turks and Caicos Islands",
            "value": "TC"
         }, {
            "name": "Tuvalu",
            "value": "TV"
         }, {
            "name": "Uganda",
            "value": "UG"
         }, {
            "name": "Ukraine",
            "value": "UA"
         }, {
            "name": "United Arab Emirates",
            "value": "AE"
         }, {
            "name": "United Kingdom",
            "value": "GB"
         }, {
            "name": "United States",
            "value": "US"
         }, {
            "name": "United States Minor Outlying Islands",
            "value": "UM"
         }, {
            "name": "Uruguay",
            "value": "UY"
         }, {
            "name": "Uzbekistan",
            "value": "UZ"
         }, {
            "name": "Vanuatu",
            "value": "VU"
         }, {
            "name": "Venezuela",
            "value": "VE"
         }, {
            "name": "Viet Nam",
            "value": "VN"
         },
         /*     {
                 "name": "Virgin Islands, British",
                 "value": "VG"
             }, */
         /*     {
                 "name": "Virgin Islands, U.S.",
                 "value": "VI"
             }, */
         {
            "name": "Wallis and Futuna",
            "value": "WF"
         },
         /*     {
                 "name": "Western Sahara",
                 "value": "EH"
             }, */
         {
            "name": "Yemen",
            "value": "YE"
         }, {
            "name": "Zambia",
            "value": "ZM"
         }, {
            "name": "Zimbabwe",
            "value": "ZW"
         }],

         fi: [{
            "name": "Valitse maa",
            "value": ""
         }, {
            "value": "AF",
            "name": "Afganistan"
         }, {
            "value": "NL",
            "name": "Alankomaat"
         }, {
            "value": "AN",
            "name": "Alankomaiden Antillit"
         }, {
            "value": "AL",
            "name": "Albania"
         }, {
            "value": "DZ",
            "name": "Algeria"
         }, {
            "value": "AS",
            "name": "Amerikan Samoa"
         }, {
            "value": "AD",
            "name": "Andorra"
         }, {
            "value": "AO",
            "name": "Angola"
         }, {
            "value": "AI",
            "name": "Anguilla"
         }, {
            "value": "AQ",
            "name": "Antarktis"
         }, {
            "value": "AG",
            "name": "Antigua ja Barbuda"
         }, {
            "value": "AE",
            "name": "Arabiemiirikunnat"
         }, {
            "value": "AR",
            "name": "Argentiina"
         }, {
            "value": "AM",
            "name": "Armenia"
         }, {
            "value": "AW",
            "name": "Aruba"
         }, {
            "value": "AU",
            "name": "Australia"
         }, {
            "value": "AZ",
            "name": "Azerbaidzan"
         }, {
            "value": "BS",
            "name": "Bahama"
         }, {
            "value": "BH",
            "name": "Bahrain"
         }, {
            "value": "BD",
            "name": "Bangladesh"
         }, {
            "value": "BB",
            "name": "Barbados"
         }, {
            "value": "BE",
            "name": "Belgia"
         }, {
            "value": "BZ",
            "name": "Belize"
         }, {
            "value": "BJ",
            "name": "Benin"
         }, {
            "value": "BM",
            "name": "Bermuda"
         }, {
            "value": "BT",
            "name": "Bhutan"
         }, {
            "value": "BO",
            "name": "Bolivia"
         }, {
            "value": "BA",
            "name": "Bosnia-Hertsegovina"
         }, {
            "value": "BW",
            "name": "Botswana"
         },
         /*      {  
                "value":"BV",
                "name":"Bouvet´nsaari"
             }, */
         {
            "value": "BR",
            "name": "Brasilia"
         }, {
            "value": "GB",
            "name": "Britannia"
         },
         /*      {  
                "value":"IO",
                "name":"Brittiläinen Intian valtameren alue"
             }, */
         /*      {  
                "value":"VG",
                "name":"Brittiläiset Neitsytsaaret"
             }, */
         {
            "value": "BN",
            "name": "Brunei"
         }, {
            "value": "BG",
            "name": "Bulgaria"
         }, {
            "value": "BF",
            "name": "Burkina Faso"
         }, {
            "value": "BI",
            "name": "Burundi"
         }, {
            "value": "PK",
            "name": "Pakistan"
         }, {
            "value": "KY",
            "name": "Caymansaaret"
         }, {
            "value": "CL",
            "name": "Chile"
         },
         /*      {  
                "value":"CK",
                "name":"Cookinsaaret"
             }, */
         {
            "value": "CR",
            "name": "Costa Rica"
         }, {
            "value": "DJ",
            "name": "Djibouti"
         }, {
            "value": "DM",
            "name": "Dominica"
         }, {
            "value": "DO",
            "name": "Dominikaaninen tasavalta"
         }, {
            "value": "EC",
            "name": "Ecuador"
         }, {
            "value": "EE",
            "name": "Eesti"
         }, {
            "value": "EG",
            "name": "Egypti"
         }, {
            "value": "SV",
            "name": "El Salvador"
         }, {
            "value": "ER",
            "name": "Eritrea"
         }, {
            "value": "ES",
            "name": "Espanja"
         }, {
            "value": "ZA",
            "name": "Etelä-Afrikka"
         }, {
            "value": "KR",
            "name": "Etelä-Korea"
         },
         /*      {  
                "value":"GS",
                "name":"Etelä-Georgia ja Eteläiset Sandwichsaaret"
             }, */
         {
            "value": "ET",
            "name": "Etiopia"
         }, {
            "value": "FK",
            "name": "Falklandinsaaret"
         }, {
            "value": "FJ",
            "name": "Fidzi"
         }, {
            "value": "PH",
            "name": "Filippiinit"
         }, {
            "value": "FO",
            "name": "Färsaaret"
         }, {
            "value": "GA",
            "name": "Gabon"
         }, {
            "value": "GM",
            "name": "Gambia"
         }, {
            "value": "GE",
            "name": "Georgia"
         }, {
            "value": "GH",
            "name": "Ghana"
         }, {
            "value": "GI",
            "name": "Gibraltar"
         }, {
            "value": "GD",
            "name": "Grenada"
         }, {
            "value": "GL",
            "name": "Grönlanti"
         }, {
            "value": "GP",
            "name": "Guadeloupe"
         }, {
            "value": "GU",
            "name": "Guam"
         }, {
            "value": "GT",
            "name": "Guatemala"
         }, {
            "value": "GN",
            "name": "Guinea"
         }, {
            "value": "GW",
            "name": "Guinea-Bissau"
         }, {
            "value": "GY",
            "name": "Guyana"
         }, {
            "value": "HT",
            "name": "Haiti"
         },
         /*      {  
                "value":"HM",
                "name":"Heard ja McDonaldsaaret"
             }, */
         {
            "value": "HN",
            "name": "Honduras"
         }, {
            "value": "HK",
            "name": "Honkong"
         }, {
            "value": "SJ",
            "name": "Huippuvuoret ja Jan Mayen"
         }, {
            "value": "ID",
            "name": "Indonesia"
         }, {
            "value": "IN",
            "name": "Intia"
         }, {
            "value": "IQ",
            "name": "Irak"
         }, {
            "value": "IR",
            "name": "Iran"
         }, {
            "value": "IE",
            "name": "Irlanti"
         }, {
            "value": "IS",
            "name": "Islanti"
         }, {
            "value": "IL",
            "name": "Israel"
         }, {
            "value": "IT",
            "name": "Italia"
         }, {
            "value": "TL",
            "name": "Itä-Timor"
         }, {
            "value": "AT",
            "name": "Itävalta"
         }, {
            "value": "JM",
            "name": "Jamaika"
         }, {
            "value": "JP",
            "name": "Japani"
         }, {
            "value": "YE",
            "name": "Jemen"
         }, {
            "value": "JO",
            "name": "Jordania"
         }, {
            "value": "CX",
            "name": "Joulusaari"
         }, {
            "value": "KH",
            "name": "Kambodza"
         }, {
            "value": "CM",
            "name": "Kamerun"
         }, {
            "value": "CA",
            "name": "Kanada"
         }, {
            "value": "CV",
            "name": "Kap Verde"
         }, {
            "value": "KZ",
            "name": "Kazakstan"
         }, {
            "value": "KE",
            "name": "Kenia"
         }, {
            "value": "CF",
            "name": "Keski-Afrikka"
         }, {
            "value": "CN",
            "name": "Kiina"
         }, {
            "value": "KG",
            "name": "Kirgisia"
         }, {
            "value": "KI",
            "name": "Kiribati"
         }, {
            "value": "CO",
            "name": "Kolumbia"
         }, {
            "value": "KM",
            "name": "Komorit"
         }, {
            "value": "CG",
            "name": "Kongo"
         }, {
            "value": "CD",
            "name": "Kongon Demokraattinen Tasavalta"
         },
         /*      {  
                "value":"CC",
                "name":"Kookossaaret"
             }, */
         {
            "value": "GR",
            "name": "Kreikka"
         }, {
            "value": "HR",
            "name": "Kroatia"
         }, {
            "value": "CU",
            "name": "Kuuba"
         }, {
            "value": "KW",
            "name": "Kuwait"
         }, {
            "value": "CY",
            "name": "Kypros"
         }, {
            "value": "LA",
            "name": "Laos"
         }, {
            "value": "LV",
            "name": "Latvia"
         }, {
            "value": "LS",
            "name": "Lesotho"
         }, {
            "value": "LB",
            "name": "Libanon"
         }, {
            "value": "LR",
            "name": "Liberia"
         }, {
            "value": "LY",
            "name": "Libya"
         }, {
            "value": "LI",
            "name": "Liechtenstein"
         }, {
            "value": "LT",
            "name": "Liettua"
         }, {
            "value": "LU",
            "name": "Luxemburg"
         }, {
            "value": "EH",
            "name": "Länsi-Sahara"
         }, {
            "value": "MO",
            "name": "Macao"
         }, {
            "value": "MG",
            "name": "Madagaskar"
         }, {
            "value": "MK",
            "name": "Makedonia"
         }, {
            "value": "MW",
            "name": "Malawi"
         }, {
            "value": "MV",
            "name": "Malediivit"
         }, {
            "value": "MY",
            "name": "Malesia"
         }, {
            "value": "ML",
            "name": "Mali"
         }, {
            "value": "MT",
            "name": "Malta"
         }, {
            "value": "MA",
            "name": "Marokko"
         }, {
            "value": "MH",
            "name": "Marshallinsaaret"
         }, {
            "value": "MQ",
            "name": "Martinique"
         }, {
            "value": "MR",
            "name": "Mauritania"
         }, {
            "value": "MU",
            "name": "Mauritius"
         }, {
            "value": "YT",
            "name": "Mayotte"
         }, {
            "value": "MX",
            "name": "Meksiko"
         }, {
            "value": "FM",
            "name": "Mikronesia"
         }, {
            "value": "MD",
            "name": "Moldova"
         }, {
            "value": "MC",
            "name": "Monaco"
         }, {
            "value": "SN",
            "name": "Mongolia MN Senegal"
         }, {
            "value": "MS",
            "name": "Montserrat"
         }, {
            "value": "MZ",
            "name": "Mosambik"
         }, {
            "value": "MM",
            "name": "Myanmar"
         }, {
            "value": "NA",
            "name": "Namibia"
         }, {
            "value": "NR",
            "name": "Nauru"
         }, {
            "value": "NP",
            "name": "Nepal"
         }, {
            "value": "NI",
            "name": "Nicaragua"
         }, {
            "value": "NE",
            "name": "Niger"
         }, {
            "value": "NG",
            "name": "Nigeria"
         }, {
            "value": "NU",
            "name": "Niuensaari"
         }, {
            "value": "NF",
            "name": "Norfolkinsaari"
         }, {
            "value": "NO",
            "name": "Norja"
         }, {
            "value": "CI",
            "name": "Norsunluurannikko"
         }, {
            "value": "OM",
            "name": "Oman"
         }, {
            "value": "PW",
            "name": "Palau"
         },
         /*      {  
                "value":"PS",
                "name":"Palestiina"
             }, */
         {
            "value": "PA",
            "name": "Panama"
         }, {
            "value": "PG",
            "name": "Papua-Uusi-Guinea"
         }, {
            "value": "PY",
            "name": "Paraguay"
         }, {
            "value": "PE",
            "name": "Peru"
         }, {
            "value": "PN",
            "name": "Pitcairn"
         }, {
            "value": "KP",
            "name": "Pohjois-Korea"
         }, {
            "value": "MP",
            "name": "Pohjois-Mariaanit"
         }, {
            "value": "PT",
            "name": "Portugali"
         }, {
            "value": "PR",
            "name": "Puerto Rico"
         }, {
            "value": "PL",
            "name": "Puola"
         }, {
            "value": "GQ",
            "name": "Päiväntasaajan Guinea"
         }, {
            "value": "QA",
            "name": "Qatar"
         }, {
            "value": "FR",
            "name": "Ranska"
         }, {
            "value": "GF",
            "name": "Ranskan Guayana"
         }, {
            "value": "PF",
            "name": "Ranskan Polynesia"
         }, {
            "value": "TF",
            "name": "Ranskan eteläiset ja antarktiset alueet"
         }, {
            "value": "RO",
            "name": "Romania"
         }, {
            "value": "RW",
            "name": "Ruanda"
         }, {
            "value": "SE",
            "name": "Ruotsi"
         }, {
            "value": "RE",
            "name": "Réunion"
         }, {
            "value": "SH",
            "name": "Saint Helena"
         }, {
            "value": "KN",
            "name": "Saint Kitts ja Nevis"
         }, {
            "value": "LC",
            "name": "Saint Lucia"
         }, {
            "value": "VC",
            "name": "Saint Vincent ja Grenadiinit"
         }, {
            "value": "PM",
            "name": "Saint-Pierre ja Miquelon"
         }, {
            "value": "DE",
            "name": "Saksa"
         }, {
            "value": "SB",
            "name": "Salomonsaaret"
         }, {
            "value": "ZM",
            "name": "Sambia"
         }, {
            "value": "WS",
            "name": "Samoa"
         }, {
            "value": "SM",
            "name": "San Marino"
         }, {
            "value": "ST",
            "name": "Sao Tomé ja Príncipe"
         }, {
            "value": "SA",
            "name": "Saudi-Arabia"
         }, {
            "value": "CS",
            "name": "Serbia ja Montenegro (ent. Jugoslavia)"
         }, {
            "value": "SC",
            "name": "Seychellit"
         }, {
            "value": "SL",
            "name": "Sierra Leone"
         }, {
            "value": "SG",
            "name": "Singapore"
         }, {
            "value": "SK",
            "name": "Slovakia"
         }, {
            "value": "SI",
            "name": "Slovenia"
         }, {
            "value": "SO",
            "name": "Somalia"
         }, {
            "value": "LK",
            "name": "Sri Lanka"
         }, {
            "value": "SD",
            "name": "Sudan"
         }, {
            "value": "FI",
            "name": "Suomi"
         }, {
            "value": "SR",
            "name": "Suriname"
         }, {
            "value": "CH",
            "name": "Sveitsi"
         }, {
            "value": "SZ",
            "name": "Swazimaa"
         }, {
            "value": "SY",
            "name": "Syyria"
         }, {
            "value": "TJ",
            "name": "Tadzikistan"
         }, {
            "value": "TW",
            "name": "Taiwan"
         }, {
            "value": "TZ",
            "name": "Tansania"
         }, {
            "value": "DK",
            "name": "Tanska"
         }, {
            "value": "TH",
            "name": "Thaimaa"
         }, {
            "value": "TG",
            "name": "Togo"
         }, {
            "value": "TK",
            "name": "Tokelau"
         }, {
            "value": "TO",
            "name": "Tonga"
         }, {
            "value": "TT",
            "name": "Trinidad ja Tobago"
         }, {
            "value": "TD",
            "name": "Tsad"
         }, {
            "value": "CZ",
            "name": "Tsekki"
         }, {
            "value": "TN",
            "name": "Tunisia"
         }, {
            "value": "TR",
            "name": "Turkki"
         }, {
            "value": "TM",
            "name": "Turkmenistan"
         }, {
            "value": "TC",
            "name": "Turks- ja Caicossaaret"
         }, {
            "value": "TV",
            "name": "Tuvalu"
         }, {
            "value": "UG",
            "name": "Uganda"
         }, {
            "value": "UA",
            "name": "Ukraina"
         }, {
            "value": "HU",
            "name": "Unkari"
         }, {
            "value": "UY",
            "name": "Uruguay"
         }, {
            "value": "NC",
            "name": "Uusi-Kaledonia"
         }, {
            "value": "NZ",
            "name": "Uusi-Seelanti"
         }, {
            "value": "UZ",
            "name": "Uzbekistan"
         }, {
            "value": "BY",
            "name": "Valko-Venäjä"
         }, {
            "value": "VU",
            "name": "Vanuatu"
         }, {
            "value": "VA",
            "name": "Vatikaani"
         }, {
            "value": "VE",
            "name": "Venezuela"
         }, {
            "value": "RU",
            "name": "Venäjä"
         }, {
            "value": "VN",
            "name": "Vietnam"
         }, {
            "value": "WF",
            "name": "Wallis- ja Futunasaaret"
         }, {
            "value": "US",
            "name": "Yhdysvallat"
         },
         /*      {  
                "value":"VI",
                "name":"Yhdysvaltain Neitsytsaaret"
             }, */
         {
            "value": "UM",
            "name": "Yhdysvaltain Tyynenmeren pienet erillissaaret"
         }, {
            "value": "ZW",
            "name": "Zimbabwe"
         }],

         sv: [{
            "name": "Välj land",
            "value": ""
         }, {
            "name": "Afghanistan",
            "value": "AF"
         }, {
            "name": "Åland Islands",
            "value": "AX"
         }, {
            "name": "Albania",
            "value": "AL"
         }, {
            "name": "Algeriet",
            "value": "DZ"
         }, {
            "name": "Samoa",
            "value": "AS"
         }, {
            "name": "Andorra",
            "value": "AD"
         }, {
            "name": "Angola",
            "value": "AO"
         }, {
            "name": "Anguilla",
            "value": "AI"
         }, {
            "name": "Antarctica",
            "value": "AQ"
         }, {
            "name": "Antigua och Barbuda",
            "value": "AG"
         }, {
            "name": "Argentina",
            "value": "AR"
         }, {
            "name": "Armenien",
            "value": "AM"
         }, {
            "name": "Aruba",
            "value": "AW"
         }, {
            "name": "Australia",
            "value": "AU"
         }, {
            "name": "Österrike",
            "value": "AT"
         }, {
            "name": "Azerbajdzjan",
            "value": "AZ"
         }, {
            "name": "Bahamas",
            "value": "BS"
         }, {
            "name": "Bahrain",
            "value": "BH"
         }, {
            "name": "Bangladesh",
            "value": "BD"
         }, {
            "name": "Barbados",
            "value": "BB"
         }, {
            "name": "Belarus",
            "value": "BY"
         }, {
            "name": "Belgien",
            "value": "BE"
         }, {
            "name": "Belize",
            "value": "BZ"
         }, {
            "name": "Benin",
            "value": "BJ"
         }, {
            "name": "Bermuda",
            "value": "BM"
         }, {
            "name": "Bhutan",
            "value": "BT"
         }, {
            "name": "Bolivia",
            "value": "BO"
         }, {
            "name": "Bosnien och Hercegovina",
            "value": "BA"
         }, {
            "name": "Botswana",
            "value": "BW"
         },
         /*     {  
                "name":"Bouvet Island",
                "value":"BV"
             }, */
         {
            "name": "Brazil",
            "value": "BR"
         },
         /*     {  
                "name":"British Indian Ocean Territory",
                "value":"IO"
             }, */
         {
            "name": "Brunei",
            "value": "BN"
         }, {
            "name": "Bulgarien",
            "value": "BG"
         }, {
            "name": "Burkina Faso",
            "value": "BF"
         }, {
            "name": "Burundi",
            "value": "BI"
         }, {
            "name": "Cambodia",
            "value": "KH"
         }, {
            "name": "Kamerun",
            "value": "CM"
         }, {
            "name": "Canada",
            "value": "CA"
         }, {
            "name": "Kap Verde",
            "value": "CV"
         }, {
            "name": "Caymanöarna",
            "value": "KY"
         }, {
            "name": "Centralafrikanska republiken",
            "value": "CF"
         }, {
            "name": "Tchad",
            "value": "TD"
         }, {
            "name": "Chile",
            "value": "CL"
         }, {
            "name": "Kina",
            "value": "CN"
         }, {
            "name": "Julön",
            "value": "CX"
         },
         /*     {  
                "name":"Cocos (Keeling) Islands",
                "value":"CC"
             }, */
         {
            "name": "Colombia",
            "value": "CO"
         }, {
            "name": "Komorerna",
            "value": "KM"
         }, {
            "name": "Congo",
            "value": "CG"
         }, {
            "name": "Kongo, Demokratiska republiken",
            "value": "CD"
         },
         /*    {  
               "name":"Cook Islands",
               "value":"CK"
            }, */
         {
            "name": "Costa Rica",
            "value": "CR"
         }, {
            "name": "Elfenbenskusten",
            "value": "CI"
         }, {
            "name": "Kroatien",
            "value": "HR"
         }, {
            "name": "Kuba",
            "value": "CU"
         }, {
            "name": "Cypern",
            "value": "CY"
         }, {
            "name": "Tjeckien",
            "value": "CZ"
         }, {
            "name": "Danmark",
            "value": "DK"
         }, {
            "name": "Djibouti",
            "value": "DJ"
         }, {
            "name": "Dominica",
            "value": "DM"
         }, {
            "name": "Dominikanska republiken",
            "value": "DO"
         }, {
            "name": "Ecuador",
            "value": "EC"
         }, {
            "name": "Egypt",
            "value": "EG"
         }, {
            "name": "El Salvador",
            "value": "SV"
         }, {
            "name": "Ekvatorialguinea",
            "value": "GQ"
         }, {
            "name": "Eritrea",
            "value": "ER"
         }, {
            "name": "Estland",
            "value": "EE"
         }, {
            "name": "Etiopien",
            "value": "ET"
         }, {
            "name": "Falklandsöarna (Malvinas)",
            "value": "FK"
         }, {
            "name": "Färöarna",
            "value": "FO"
         }, {
            "name": "Fiji",
            "value": "FJ"
         }, {
            "name": "Finland",
            "value": "FI"
         }, {
            "name": "Frankrike",
            "value": "FR"
         }, {
            "name": "Franska Guyana",
            "value": "GF"
         }, {
            "name": "Franska Polynesien",
            "value": "PF"
         }, {
            "name": "franska södra territorier",
            "value": "TF"
         }, {
            "name": "Gabon",
            "value": "GA"
         }, {
            "name": "Gambia",
            "value": "GM"
         }, {
            "name": "Georgia",
            "value": "GE"
         }, {
            "name": "Tyskland",
            "value": "DE"
         }, {
            "name": "Ghana",
            "value": "GH"
         }, {
            "name": "Gibraltar",
            "value": "GI"
         }, {
            "name": "Grekland",
            "value": "GR"
         }, {
            "name": "Grönland",
            "value": "GL"
         }, {
            "name": "Grenada",
            "value": "GD"
         }, {
            "name": "Guadeloupe",
            "value": "GP"
         }, {
            "name": "Guam",
            "value": "GU"
         }, {
            "name": "Guatemala",
            "value": "GT"
         }, {
            "name": "Guernsey",
            "value": "GG"
         }, {
            "name": "Guinea",
            "value": "GN"
         }, {
            "name": "Guinea-Bissau",
            "value": "GW"
         }, {
            "name": "Guyana",
            "value": "GY"
         }, {
            "name": "Haiti",
            "value": "HT"
         },
         /*     {  
                "name":"Heard Island och McDonald Islands",
                "value":"HM"
             }, */
         {
            "name": "Vatikanstaten",
            "value": "VA"
         }, {
            "name": "Honduras",
            "value": "HN"
         }, {
            "name": "HongKong",
            "value": "HK"
         }, {
            "name": "Ungern",
            "value": "HU"
         }, {
            "name": "Isländska",
            "value": "IS"
         }, {
            "name": "Indien",
            "value": "IN"
         }, {
            "name": "Indonesien",
            "value": "ID"
         }, {
            "name": "Iran",
            "value": "IR"
         }, {
            "name": "Irak",
            "value": "IQ"
         }, {
            "name": "Irland",
            "value": "IE"
         },
         /*     {  
                "name":"Isle of Man",
                "value":"IM"
             }, */
         {
            "name": "Israel",
            "value": "IL"
         }, {
            "name": "Italien",
            "value": "IT"
         }, {
            "name": "Jamaica",
            "value": "JM"
         }, {
            "name": "Japan",
            "value": "JP"
         }, {
            "name": "Jersey",
            "value": "JE"
         }, {
            "name": "Jordan",
            "value": "JO"
         }, {
            "name": "Kazakstan",
            "value": "KZ"
         }, {
            "name": "Kenya",
            "value": "KE"
         }, {
            "name": "Kiribati",
            "value": "KI"
         }, {
            "name": "Korea, Demokratiska Folkets Republiken ",
            "value": "KP"
         }, {
            "name": "Korea",
            "value": "KR"
         }, {
            "name": "Kuwait",
            "value": "KW"
         }, {
            "name": "Kirgizistan",
            "value": "KG"
         }, {
            "name": "Laos",
            "value": "LA"
         }, {
            "name": "Lettland",
            "value": "LV"
         }, {
            "name": "Libanon",
            "value": "LB"
         }, {
            "name": "Lesotho",
            "value": "LS"
         }, {
            "name": "Liberia",
            "value": "LR"
         }, {
            "name": "Libyen Arabiska Jamahiriya",
            "value": "LY"
         }, {
            "name": "Liechtenstein",
            "value": "LI"
         }, {
            "name": "Litauen",
            "value": "LT"
         }, {
            "name": "Luxemburg",
            "value": "LU"
         }, {
            "name": "Macao",
            "value": "MO"
         }, {
            "name": "Makedonien",
            "value": "MK"
         }, {
            "name": "Madagaskar",
            "value": "MG"
         }, {
            "name": "Malawi",
            "value": "MW"
         }, {
            "name": "Malaysia",
            "value": "MY"
         }, {
            "name": "Maldiverna",
            "value": "MV"
         }, {
            "name": "Mali",
            "value": "ML"
         }, {
            "name": "Malta",
            "value": "MT"
         }, {
            "name": "Marshallöarna",
            "value": "MH"
         }, {
            "name": "Martinique",
            "value": "MQ"
         }, {
            "name": "Mauretanien",
            "value": "MR"
         }, {
            "name": "Mauritius",
            "value": "MU"
         }, {
            "name": "Mayotte",
            "value": "YT"
         }, {
            "name": "Mexico",
            "value": "MX"
         }, {
            "name": "Mikronesien",
            "value": "FM"
         }, {
            "name": "Moldavien",
            "value": "MD"
         }, {
            "name": "Monaco",
            "value": "MC"
         }, {
            "name": "Mongoliet",
            "value": "MN"
         }, {
            "name": "Montserrat",
            "value": "MS"
         }, {
            "name": "Marocko",
            "value": "MA"
         }, {
            "name": "Moçambique",
            "value": "MZ"
         }, {
            "name": "Myanmar",
            "value": "MM"
         }, {
            "name": "Namibia",
            "value": "NA"
         }, {
            "name": "Nauru",
            "value": "NR"
         }, {
            "name": "Nepal",
            "value": "NP"
         }, {
            "name": "Nederländerna",
            "value": "NL"
         }, {
            "name": "Nederländska Antillerna",
            "value": "AN"
         }, {
            "name": "Nya Kaledonien",
            "value": "NC"
         }, {
            "name": "Nya Zeeland",
            "value": "NZ"
         }, {
            "name": "Nicaragua",
            "value": "NI"
         }, {
            "name": "Niger",
            "value": "NE"
         }, {
            "name": "Nigeria",
            "value": "NG"
         }, {
            "name": "Niue",
            "value": "NU"
         }, {
            "name": "Norfolk Island",
            "value": "NF"
         },
         /*     {  
                "name":"Northern Mariana Islands",
                "value":"MP"
             }, */
         {
            "name": "Norge",
            "value": "NO"
         }, {
            "name": "Oman",
            "value": "OM"
         }, {
            "name": "Pakistan",
            "value": "PK"
         }, {
            "name": "Palau",
            "value": "PW"
         },
         /*     {  
                "name":"palestinska territoriet, ockuperade",
                "value":"PS"
             }, */
         {
            "name": "Panama",
            "value": "PA"
         }, {
            "name": "Papua Nya Guinea",
            "value": "PG"
         }, {
            "name": "Paraguay",
            "value": "PY"
         }, {
            "name": "Peru",
            "value": "PE"
         }, {
            "name": "Filippinerna",
            "value": "PH"
         }, {
            "name": "Pitcairn",
            "value": "PN"
         }, {
            "name": "Polen",
            "value": "PL"
         }, {
            "name": "Portugal",
            "value": "PT"
         }, {
            "name": "Puerto Rico",
            "value": "PR"
         }, {
            "name": "Qatar",
            "value": "QA"
         }, {
            "name": "Reunion",
            "value": "RE"
         }, {
            "name": "Rumänien",
            "value": "RO"
         }, {
            "name": "Ryska federationen",
            "value": "RU"
         }, {
            "name": "Rwanda",
            "value": "RW"
         }, {
            "name": "Saint Helena",
            "value": "SH"
         }, {
            "name": "Saint Kitts och Nevis",
            "value": "KN"
         }, {
            "name": "Saint Lucia",
            "value": "LC"
         }, {
            "name": "Saint Pierre och Miquelon",
            "value": "PM"
         }, {
            "name": "Saint Vincent och Grenadinerna",
            "value": "VC"
         }, {
            "name": "Samoa",
            "value": "WS"
         }, {
            "name": "San Marino",
            "value": "SM"
         }, {
            "name": "Sao Tomé och Principe",
            "value": "ST"
         }, {
            "name": "Saudiarabien",
            "value": "SA"
         }, {
            "name": "Senegal",
            "value": "SN"
         }, {
            "name": "Serbien och Montenegro",
            "value": "CS"
         }, {
            "name": "Seychellerna",
            "value": "SC"
         }, {
            "name": "Sierra Leone",
            "value": "SL"
         }, {
            "name": "Singapore",
            "value": "SG"
         }, {
            "name": "Slovakien",
            "value": "SK"
         }, {
            "name": "Slovenien",
            "value": "SI"
         }, {
            "name": "Salomonöarna",
            "value": "SB"
         }, {
            "name": "Somalia",
            "value": "SO"
         }, {
            "name": "Sydafrika",
            "value": "ZA"
         },
         /*     {  
                "name":"South Georgia och South Sandwich Islands",
                "value":"GS"
             }, */
         {
            "name": "Spanien",
            "value": "ES"
         }, {
            "name": "Sri Lanka",
            "value": "LK"
         }, {
            "name": "Sudan",
            "value": "SD"
         }, {
            "name": "Surinam",
            "value": "SR"
         }, {
            "name": "Svalbard och Jan Mayen",
            "value": "SJ"
         }, {
            "name": "Swaziland",
            "value": "SZ"
         }, {
            "name": "Sverige",
            "value": "SE"
         }, {
            "name": "Schweiz",
            "value": "CH"
         }, {
            "name": "Syrien",
            "value": "SY"
         }, {
            "name": "Taiwan, provinsen Kina",
            "value": "TW"
         }, {
            "name": "Tadzjikistan",
            "value": "TJ"
         }, {
            "name": "Tanzania",
            "value": "TZ"
         }, {
            "name": "Thailand",
            "value": "TH"
         }, {
            "name": "Östtimor",
            "value": "TL"
         }, {
            "name": "Togo",
            "value": "TG"
         }, {
            "name": "Tokelau",
            "value": "TK"
         }, {
            "name": "Tonga",
            "value": "TO"
         }, {
            "name": "Trinidad och Tobago",
            "value": "TT"
         }, {
            "name": "Tunisia",
            "value": "TN"
         }, {
            "name": "Turkiet",
            "value": "TR"
         }, {
            "name": "Turkmenistan",
            "value": "TM"
         }, {
            "name": "Turks- och Caicosöarna",
            "value": "TC"
         }, {
            "name": "Tuvalu",
            "value": "TV"
         }, {
            "name": "Uganda",
            "value": "UG"
         }, {
            "name": "Ukraina",
            "value": "UA"
         }, {
            "name": "Förenade Arabemiraten",
            "value": "AE"
         }, {
            "name": "Storbritannien",
            "value": "GB"
         }, {
            "name": "USA",
            "value": "US"
         }, {
            "name": "Förenta staternas mindre avlägsnaöar",
            "value": "UM"
         }, {
            "name": "Uruguay",
            "value": "UY"
         }, {
            "name": "Uzbekistan",
            "value": "UZ"
         }, {
            "name": "Vanuatu",
            "value": "VU"
         }, {
            "name": "Venezuela",
            "value": "VE"
         }, {
            "name": "Vietnam",
            "value": "VN"
         },
         /*     {  
                "name":"Jungfruöarna, brittiska",
                "value":"VG"
             },
             {  
                "name":"Jungfruöarna, USA",
                "value":"VI"
             }, */
         {
            "name": "Wallis och Futuna",
            "value": "WF"
         },
         /*     {  
                "name":"västsahara",
                "value":"EH"
             }, */
         {
            "name": "Jemen",
            "value": "YE"
         }, {
            "name": "Zambia",
            "value": "ZM"
         }, {
            "name": "Zimbabwe",
            "value": "ZW"
         }] };
      if (locale === 'sv' || locale === 'svfi') {
         return codes.sv;
      } else {
         return codes[locale];
      }
   }

   /* return codes;
   } */
});