define('incus-vastaanota/pods/storage/view/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/email'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsEmail) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  //import Invoice from 'incus-vastaanota/models/invoice';

  /* This view is used both by checker and the account holder. Thus the session test is handled within the route. */

  exports['default'] = _ember['default'].Route.extend({

    authenticated: false,
    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    storageId: null,
    storageKey: null,
    email: null,

    // We check the session in model. Should we do this in beforeModel?
    model: function model(param) {

      var authenticatedView = false;
      _ember['default'].Logger.log("***********************************************");
      _ember['default'].Logger.log(param);
      var apixclient = this.get("apixclient");
      // TODO Need interface for invoice without TransferID
      var userData = this.get("session").get("user");
      if (userData) {
        userData = userData[0];
        authenticatedView = true;
        this.set('email', userData.Email);
        this.set('authenticated', true);
      } else {
        this.set("storageKey", param.storageKey);
        this.set('email', param.email);
      }

      this.set("storageId", param.storageId);
      this.set("storageKey", param.storageKey);

      this.add_accessedat(apixclient, param.storageId, param.storageKey);

      var modelCreation = function modelCreation() {
        var storageId = param.storageId;
        var storageKey = param.storageKey;

        var dlURL = apixclient.downloadURL(storageId, storageKey, 'yes', '', storageId + '.zip');

        // TODO clean all this, used only for testing
        var req = [apixclient.listAttachment(storageId, storageKey)];
        return _ember['default'].RSVP.Promise.all(req).then(function (results) {
          var _results = _slicedToArray(results, 1);

          var attachments = _results[0];

          var images = [];
          var other_attachments = [];

          var first_image = {};

          var pdf_re = /application.?pdf/;

          attachments.data.forEach(function (a) {

            var mimetype = a.FileMimeType;
            if (mimetype) {
              mimetype = mimetype.replace(':', '/');
            } else {
              mimetype = "application/octet-stream";
            }

            a.url = apixclient.downloadURL(storageId, storageKey, 'yes', a.FileName, a.FileName, mimetype, 'inline');

            _ember['default'].Logger.log(a);

            if (pdf_re.test(a.FileMimeType)) {
              images.addObject(a);

              if (!first_image.storageId) {
                first_image = a;
              }
            }

            other_attachments.addObject(a);
          });

          var attachmentSrc = first_image.url;
          // Used to determine which will be the actual recipients for the email.

          var model = _ember['default'].Object.extend({
            attachmentSrc: attachmentSrc,
            selectedAttachment: first_image.FileName,
            dlURL: dlURL,
            images: images,
            attachments: other_attachments

          }).create();

          return model;
        });
      };

      return modelCreation();
    },

    actions: {

      changeIframe: function changeIframe(attachment) {
        _ember['default'].Logger.log(attachment);
        var apixclient = this.get("apixclient");
        var storageId = this.get('storageId');
        var storageKey = this.get('storageKey');
        this.set('controller.model.selectedAttachment', attachment.FileName);

        var re = /:/;
        if (re.test(attachment.FileMimeType)) {
          _ember['default'].Logger.log("as the data seems to have colone (category:detail) instead of slash, substituting", attachment.FileMimeType);
          attachment.FileMimeType = attachment.FileMimeType.replace(':', '/');
        }
        _ember['default'].Logger.log(attachment.FileMimeType);

        var attachmentSrc = apixclient.downloadURL(storageId, storageKey, 'yes', attachment.FileName, attachment.FileName, attachment.FileMimeType, 'inline');
        this.set("controller.model.attachmentSrc", attachmentSrc);
        //});
      }

    },

    _create_accessed_metadata: function _create_accessed_metadata(mdid) {
      var date = new Date();

      /*
        note: toISOString returns ISO8601 string in UTC timezone (Z in the end)
      */

      var metadata = "<Group>\r\n";
      metadata = metadata + '<Value type="AccessedAt.' + mdid + '">' + date.toISOString() + "</Value>\r\n";
      metadata = metadata + '</Group>';
      return metadata;
    },

    _get_last_metadata_id_promise: function _get_last_metadata_id_promise(apixclient, storageId, storageKey) {

      return apixclient.receiverMetadata(storageId, storageKey).then(function (data) {
        var mdid = -1;
        var mdid_re = /AccessedAt\.(\d+)/;

        for (var key in data.data[0]) {
          var found = key.match(mdid_re);

          if (found) {
            //console.log(["FOUND!", found]);
            var new_mdid = parseInt(found[1]);

            if (new_mdid > mdid) {
              mdid = new_mdid;
            }
          }
        }

        return mdid;
      });
    },

    add_accessedat: function add_accessedat(apixclient, storageId, storageKey) {
      var _this = this;

      var access_metadata = this._create_accessed_metadata(0);
      var p = apixclient.addMetadata(storageId, storageKey, access_metadata, "text/xml");

      _ember['default'].RSVP.Promise.resolve(p).then(function (r) {

        if (r.callStatus.success === "ERR") {

          _ember['default'].RSVP.Promise.resolve(_this._get_last_metadata_id_promise(apixclient, storageId, storageKey)).then(function (mdid) {

            if (mdid >= 0) {
              mdid++;
              _ember['default'].Logger.log("Added metadata with id: " + mdid);
              var access_metadata_new = _this._create_accessed_metadata(mdid);
              _ember['default'].RSVP.Promise.resolve(apixclient.addMetadata(storageId, storageKey, access_metadata_new, "text/xml"));
            }
          });
        }
      });
    }

  });
});