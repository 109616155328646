define('incus-vastaanota/components/traffic-pagination', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'span',
        hasPages: _ember['default'].computed.gt("pages", 1),

        pagedArray: _ember['default'].computed('pages', function () {
            var pages = this.get("pages");

            var a = [];
            for (var i = 1; i <= pages; i++) {
                a.push(i);
            }

            return a;
        }),

        actions: {
            changePerPage: function changePerPage(newValue) {
                console.log("Change perPage to ", newValue);
                this.set("perPage", newValue);
                this.set("page", 1);
            },

            changePage: function changePage(newValue) {
                console.log("Change page to ", newValue);
                this.set("page", parseInt(newValue));
            }
        }
    });
});