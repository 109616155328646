define("incus-vastaanota/models/receiver", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Object.extend({
		firstname: null,
		lastname: null,
		name3: null,
		street1: null,
		street2: null,
		postcode: null,
		postoffice: null,
		countrycode: null,
		uuid: null,
		group: null,
		custom1: null,
		custom2: null,
		custom3: null,
		custom4: null,
		email: null,

		setFields: function setFields(values) {
			for (var key in values) {
				if (values.hasOwnProperty(key)) {
					this.set(key, values[key]);
				}
			}

			if (this.group === "_default") {
				this.set("group", "");
			}
		},

		toJSON: function toJSON() {
			return JSON.stringify(this.toArray());
		},

		toArray: function toArray() {
			var json_object = {};

			for (var key in this) {
				if (this.hasOwnProperty(key)) {
					var temp = this.get(key);
					json_object[key] = temp;
				}
			}

			return json_object;
		}

	});
});