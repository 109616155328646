define('incus-vastaanota/pods/list/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/email', 'incus-vastaanota/models/invoice', 'incus-vastaanota/utils/list-utils'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsEmail, _incusVastaanotaModelsInvoice, _incusVastaanotaUtilsListUtils) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    invoiceList: null,
    fromDate: null,
    toDate: null,
    targetDate: null,

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var isLaskumappi = this.get("countryselector.url") === 'laskumappi';
      var storedSortProperty = 'dueDate';
      var storedSortDirection = false;
      var storedSortNumeric = false;

      // TODO handle empty list
      return apixclient.listInvoiceZips2(userData.TransferID, userData.TransferKey).then(function (data) {
        //Ember.Logger.log(data);
        var emailAddresses = [];
        var tempArray = _incusVastaanotaUtilsListUtils['default'].processSearchReceivedArchiveData(data, apixclient, userData);
        var invoiceList = tempArray.shift();
        var locale = _incusVastaanotaConfigEnvironment['default'].defaultLocale;
        if (window.localStorage) {
          locale = window.localStorage.getItem('locale');
          storedSortProperty = window.localStorage.getItem('storedSortProperty');
          storedSortDirection = window.localStorage.getItem('storedSortDirection');
          storedSortNumeric = window.localStorage.getItem('storedSortNumeric');
        }

        var fields = [{
          fieldName: 'documentID',
          numeric: true,
          sortable: true
        }, {
          fieldName: 'documentDate',
          numeric: false,
          sortable: true
        }, {
          fieldName: 'senderName',
          numeric: false,
          sortable: true
        }];
        if (isLaskumappi) {
          fields.push({
            fieldName: 'barcode',
            numeric: false,
            sortable: false
          });
        }
        fields = fields.concat([{
          fieldName: 'amountToPay',
          numeric: true,
          sortable: true
        }, {
          fieldName: 'unpaidSum',
          numeric: true,
          sortable: true
        }, {
          fieldName: 'paymentReference',
          numeric: false,
          sortable: true
        }, {
          fieldName: 'bankAccount',
          numeric: false,
          sortable: true
        }, {
          fieldName: 'dueDate',
          numeric: false,
          sortable: true
        }, {
          fieldName: 'paymentStatus',
          numeric: false,
          sortable: true
        }, {
          fieldName: 'invoiceStatus',
          numeric: false,
          sortable: true
        }, {
          fieldName: 'approvalStatus',
          numeric: false,
          sortable: true
        }]);

        var model = _ember['default'].Object.extend({
          isLaskumappi: isLaskumappi,
          businessId: userData.Ytunnus,
          locale: locale,
          fields: fields,

          senderFilter: null,
          amountToPayFilter: null,

          sortProperty: storedSortProperty,
          sortDirection: storedSortDirection,
          sortNumeric: storedSortNumeric,

          // Used for filtering the resultset by date
          startDate: null,
          endDate: null,
          startDueDate: null,
          endDueDate: null,

          paymentStatusFilter: 'NOTPAID',
          validPaymentStatusFilters: ['', 'NOTPAID', 'PAID'],

          emailAddressSourceList: [],
          emailAddressList: _ember['default'].computed('emailAddressSourceList.[]', function () {
            _ember['default'].Logger.log('creating email address object list');
            var list = [];
            var emailAddressSourceList = this.get('emailAddressSourceList');
            _ember['default'].Logger.log(emailAddressSourceList);
            for (var i = 0; i < emailAddressSourceList.length; i++) {
              list.addObject(_ember['default'].Object.create({
                address: emailAddressSourceList[i],
                selected: true,
                index: i
              }));
            }
            return list;
          }),

          invoiceListDynamic: _ember['default'].computed('sortProperty', 'sortDirection', 'sortNumeric', 'senderFilter', 'amountToPayFilter', 'startDate', 'endDate', 'startDueDate', 'endDueDate', 'invoiceList.[]', 'paymentStatusFilter', 'invoiceList.@each.paymentStatus', function () {
            var list = this.get("invoiceList");
            var senderFilter = this.get("senderFilter");
            var amountToPayFilter = this.get("amountToPayFilter");
            var startDate = this.get("startDate");
            var endDate = this.get("endDate");
            var startDueDate = this.get("startDueDate");
            var endDueDate = this.get("endDueDate");
            var paymentStatusFilter = this.get('paymentStatusFilter');

            var invoiceArray = list.filter(function (invoice) {
              var includeInvoice = true;
              //Ember.Logger.log(invoice.get("pastDue"), invoice.get("isDueUnder4"));
              if (startDate) {
                if (moment(invoice.get('createdDate'), 'YYYYMMDDHHmmss').isBefore(moment(startDate), 'day')) {
                  includeInvoice = false;
                }
              }
              if (endDate) {
                if (moment(invoice.get('createdDate'), 'YYYYMMDDHHmmss').isAfter(moment(endDate), 'day')) {
                  includeInvoice = false;
                }
              }
              if (startDueDate) {
                if (moment(invoice.get('dueDate'), 'YYYYMMDD').isBefore(moment(startDueDate), 'day')) {
                  includeInvoice = false;
                }
              }
              if (endDueDate) {
                if (moment(invoice.get('dueDate'), 'YYYYMMDD').isAfter(moment(endDueDate), 'day')) {
                  includeInvoice = false;
                }
              }
              // Filter by senderName
              if (senderFilter) {
                //Ember.Logger.log(senderFilter);
                var re = new RegExp(senderFilter, 'i');
                if (!re.test(invoice.senderName)) {
                  includeInvoice = false;
                }
              }
              if (amountToPayFilter) {
                //Ember.Logger.log(senderFilter);
                var re = new RegExp(amountToPayFilter, 'i');
                if (!re.test(invoice.amountToPay)) {
                  includeInvoice = false;
                }
              }
              if (paymentStatusFilter) {
                if (invoice.get('paymentStatus') !== paymentStatusFilter) {
                  includeInvoice = false;
                }
              }
              return includeInvoice;
            });

            var sortProperty = this.get("sortProperty");
            var sortNumeric = this.get("sortNumeric");
            var sortDirection = this.get("sortDirection");
            if (sortProperty) {
              invoiceArray.sort(_incusVastaanotaUtilsListUtils['default'].dynamicSort(sortProperty, sortDirection, sortNumeric));
            }

            return invoiceArray;
          }),

          summary: _ember['default'].computed('invoiceListDynamic.[]', 'invoiceListDynamic.@each.paymentStatus', 'invoiceListDynamic.@each.totalPaidSum', function () {
            var invoiceArray = this.get("invoiceListDynamic");

            // XXX Do we need better math?
            var paidDue0To3 = _incusVastaanotaUtilsListUtils['default'].sumOver(invoiceArray, 0, 3, true);
            var paidDue4To7 = _incusVastaanotaUtilsListUtils['default'].sumOver(invoiceArray, 4, 7, true);
            var paidDue8OrMore = _incusVastaanotaUtilsListUtils['default'].sumOver(invoiceArray, 8, null, true);
            var paidSum = paidDue0To3 + paidDue4To7 + paidDue8OrMore;
            var unpaidDue0To3 = _incusVastaanotaUtilsListUtils['default'].sumOver(invoiceArray, 0, 3, false);
            var unpaidDue4To7 = _incusVastaanotaUtilsListUtils['default'].sumOver(invoiceArray, 4, 7, false);
            var unpaidDue8OrMore = _incusVastaanotaUtilsListUtils['default'].sumOver(invoiceArray, 8, null, false);
            var unpaidSum = unpaidDue0To3 + unpaidDue4To7 + unpaidDue8OrMore;
            var due0To3 = paidDue0To3 + unpaidDue0To3;
            var due4To7 = paidDue4To7 + unpaidDue4To7;
            var due8OrMore = paidDue8OrMore + unpaidDue8OrMore;
            var totalSum = due0To3 + due4To7 + due8OrMore;
            return {
              paidDue0To3: paidDue0To3.toFixed(2),
              paidDue4To7: paidDue4To7.toFixed(2),
              paidDue8OrMore: paidDue8OrMore.toFixed(2),
              paidSum: paidSum.toFixed(2),
              unpaidDue0To3: unpaidDue0To3.toFixed(2),
              unpaidDue4To7: unpaidDue4To7.toFixed(2),
              unpaidDue8OrMore: unpaidDue8OrMore.toFixed(2),
              unpaidSum: unpaidSum.toFixed(2),
              due0To3: due0To3.toFixed(2),
              due4To7: due4To7.toFixed(2),
              due8OrMore: due8OrMore.toFixed(2),
              totalSum: totalSum.toFixed(2)
            };
          })

        }).create({
          invoiceList: invoiceList
        });
        _ember['default'].Logger.log(model.get('validPaymentStatusFilters'));
        return model;
      });
    },

    actions: {
      setSortProperty: function setSortProperty(sortProperty, numeric, sortable) {
        if (!sortable) {
          return;
        }
        var currentSort = this.get("controller.model.sortProperty");
        if (currentSort === sortProperty) {
          var sortDirection = this.get("controller.model.sortDirection");
          this.set("controller.model.sortDirection", !sortDirection);
        } else {
          this.set("controller.model.sortDirection", false);
        }
        if (numeric) {
          this.set("controller.model.sortNumeric", true);
        } else {
          this.set("controller.model.sortNumeric", false);
        }
        this.set("controller.model.sortProperty", sortProperty);
        if (window.localStorage) {
          window.localStorage.setItem('storedSortProperty', sortProperty);
          window.localStorage.setItem('storedSortDirection', this.get("controller.model.sortDirection"));
          window.localStorage.setItem('storedSortNumeric', numeric);
        }
      },
      clearAllDateFilters: function clearAllDateFilters() {
        this.set('controller.model.startDate', null);
        this.set('controller.model.startDueDate', null);
        this.set('controller.model.endDate', null);
        this.set('controller.model.endDueDate', null);
      },
      showBarcode: function showBarcode(invoice) {
        window.prompt(this.get('i18n').t('listview.barcodePrompt'), invoice.generateBarcode());
      },
      deleteInvoice: function deleteInvoice(invoice) {
        var _this = this;

        var storageId = invoice.get('storageID');
        var apixclient = this.get('apixclient');
        var userData = this.get("session").get("user")[0];
        return apixclient.deleteStorage(userData.TransferID, storageId, userData.TransferKey).then(function (data) {
          var invoiceList = _this.get("controller.model.invoiceList");
          invoiceList = invoiceList.filter(function (el) {
            return el != invoice;
          });
          _this.set("controller.model.invoiceList", invoiceList);
        });
      },
      toAccountingEmail: function toAccountingEmail(invoice) {
        _ember['default'].$("#overlay").addClass('active-overlay');
        this.set("controller.model.term", null);
        this.set("controller.model.emailBoxTitle", this.get('i18n').t("common.toAccounting"));
        _ember['default'].$('#email-address-input').focus();
        this.set('controller.model.emailType', 'accounting');
        this.set("controller.model.targetInvoice", invoice);
      },
      closeSendEmail: function closeSendEmail() {
        this.set('controller.model.emailType', null);
        _ember['default'].$("#overlay").removeClass('active-overlay');
        this.set("controller.model.targetInvoice", null);
      },
      sendEmail: function sendEmail(invoice) {
        var _this2 = this;

        var emailAddressList = this.get('controller.model.emailAddressList');
        var list = emailAddressList.filterBy('selected');
        if (!list.length) {
          this.get('notify').error(this.get('i18n').t("email.noRecipient").toString());
          return;
        }

        /* Check emails */
        var failures = false;
        list.forEach(function (el) {
          if (!_incusVastaanotaUtilsEmail['default'].validateEmail(el.address)) {
            _this2.get('notify').error(_this2.get('i18n').t("email.invalidRecipient") + ": " + el.address);
            failures = true;
          }
        });
        if (failures) {
          return;
        }

        this._sendEmail(list, invoice).then(function () {
          _this2.send("closeSendEmail");
        });
      },
      selectEmailAddress: function selectEmailAddress(e) {
        _ember['default'].Logger.log(e);
        e.toggleProperty('selected');
      },
      // Add a new email address to the list and backend
      addEmailAddress: function addEmailAddress(emailRecipient) {

        var emailAddressSourceList = this.get('controller.model.emailAddressSourceList');
        if (!_incusVastaanotaUtilsEmail['default'].validateEmail(emailRecipient)) {
          // FIXME For some reason without the concatenation the resulting string is empty
          this.get('notify').error("" + this.get('i18n').t("email.invalidRecipient").toString());
          _ember['default'].Logger.log("Invalid email address.", emailRecipient);
          return;
        }
        // If the email Address is not present in the source list, add it.
        if (emailAddressSourceList.indexOf(emailRecipient) === -1) {
          _ember['default'].Logger.log("Adding email addres" + emailRecipient);
          // The list of the email address objects is a property of the full list
          emailAddressSourceList.pushObject(emailRecipient);
          this.set("controller.model.emailAddressSourceList", emailAddressSourceList);
          /*
          apixclient.addEmailRecipient(userData.TransferID, storageID, userData.TransferKey, emailRecipient).then((data) => {
          Ember.Logger.log(data);
          });
          */
          this.set("controller.model.term", null);
          _ember['default'].$('#email-address-input').val(null);
        } else {
          _ember['default'].Logger.log("Address already added.");
        }
      },
      removeEmailAddress: function removeEmailAddress(e) {
        var emailAddressSourceList = this.get('controller.model.emailAddressSourceList');
        //let invoice = this.get("controller.model.invoice");
        //let storageId = invoice.get('storageID');
        var apixclient = this.get('apixclient');
        var userData = this.get("session").get("user")[0];
        /*
        return apixclient.removeEmailRecipient(userData.TransferID, storageId, userData.TransferKey, emailRecipient,).then((data) => {
          Ember.Logger.log(data);
        });
        */
        emailAddressSourceList = emailAddressSourceList.filter(function (el) {
          if (e.address === el) {
            return false;
          }
          return true;
        });
        //emailAddressSourceList.removeObject(e);
        _ember['default'].Logger.log("removed", e, 'from', emailAddressSourceList);
        this.set('controller.model.emailAddressSourceList', emailAddressSourceList);
      },
      togglePaymentStatus: function togglePaymentStatus(invoice) {
        invoice.togglePaymentStatus();
      },
      addPayment: function addPayment(invoice) {
        var notify = this.get('notify');
        var trans = this.get("i18n");

        if (!invoice.isValidPartialAmount(invoice.paidAmount)) {
          notify.error(trans.t("invoice.partialAmount") + " " + trans.t("common.shouldBeNumber"));
          return 1;
        }

        invoice.addPayment(invoice.paidAmount, invoice.paymentDate, invoice.postingDate);

        this.set("controller.model.invoice", invoice);
        return this._setMetadata(invoice);
      }
    },

    _sendEmail: function _sendEmail(emailRecipientList, invoice) {
      var _this3 = this;

      var apixclient = this.get("apixclient");
      // only accounting emails from list
      var emailType = 'accounting';
      var userData = this.get("session.user.0");
      var storageID = invoice.get("storageID");
      _ember['default'].Logger.log(emailRecipientList);
      _ember['default'].Logger.log(storageID);
      var storageKey = invoice.get('storageKey');

      var subject = undefined;
      if (emailType === 'accounting') {
        subject = userData.Name + ' ' + this.get('i18n').t("email.accountingSubject").toString();
      }

      // Loop through the recipients
      var req = [];
      emailRecipientList.forEach(function (recipient) {
        var emailRecipient = recipient.get('address');
        _ember['default'].Logger.log("adding ", emailRecipient);
        // accounting -> no url
        var url = '';
        var xml = _incusVastaanotaUtilsEmail['default'].generateXML(emailRecipient, emailType, invoice.get("documentID"), invoice, _this3.get('i18n'), userData, url, subject);
        req.addObject(apixclient.sendEmail(subject, _incusVastaanotaConfigEnvironment['default'].emailSender, emailRecipient, storageID, 'yes', storageKey, xml).then(function (data) {
          _ember['default'].Logger.log("Email sent.");
          _ember['default'].Logger.log(data);
        }));
      });
      // All emails should be sent by now, so update the metadata
      _ember['default'].Logger.log(req);
      return _ember['default'].RSVP.Promise.all(req).then(function (results) {
        _ember['default'].Logger.log("all requests done");
        _ember['default'].Logger.log(results);
        // TODO change transfer status
        //Ember.Logger.log(invoice);
        invoice.set('sentToBookkeeping', moment().format('YYYYMMDDHHmmss'));
        return _this3._setMetadata(invoice);
      });
    },

    _setMetadata: function _setMetadata(invoice) {
      var storageId = invoice.get('storageID');
      var metadata = invoice.toXML();
      var apixclient = this.get('apixclient');
      var userData = this.get("session").get("user")[0];
      return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
        _ember['default'].Logger.log(data);
      });
    }
  });
});