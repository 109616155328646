define('incus-vastaanota/models/payment', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({
    paymentNumber: null,
    amount: null,
    paymentDate: null,
    postingDate: null,
    originalString: null,

    formattedPaymentDate: (function () {
      return this.formattedDate(this.get('paymentDate'));
    }).property('paymentDate'),
    formattedPostingDate: (function () {
      return this.formattedDate(this.get('postingDate'));
    }).property('postingDate'),

    formattedDate: function formattedDate(date) {
      var d = moment(date);
      return d.format('DD.MM.YYYY');
    },

    parsePayment: function parsePayment(paymentNumber, data) {
      this.set("paymentNumber", paymentNumber);
      this.set("originalString", data);
      var re = /^(.+)\t(.+)\t(.+)$/m;
      var result = re.exec(data);
      this.set("amount", result[1]);
      this.set("paymentDate", result[2]);
      this.set("postingDate", result[3]);
    }

  });
});