define('incus-vastaanota/pods/traffic/pitstop/view/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/email', 'incus-vastaanota/models/invoice'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsEmail, _incusVastaanotaModelsInvoice) {

  /* This view is used both by checker and the account holder. Thus the session test is handled within the route. */

  exports['default'] = _ember['default'].Route.extend({

    authenticated: false,
    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    storageId: null,
    storageKey: null,
    email: null,

    // We check the session in model. Should we do this in beforeModel?
    model: function model(param) {

      var authenticatedView = false;
      _ember['default'].Logger.log(param);
      var apixclient = this.get("apixclient");
      // TODO Need interface for invoice without TransferID
      var userData = this.get("session").get("user");
      if (userData) {
        userData = userData[0];
        authenticatedView = true;
        this.set('email', userData.Email);
        this.set('authenticated', true);
      } else {}

      this.set("TraID", param.TraID);
      this.set('uniqueMessageID', param.uniqueMessageID);
      this.set('role', param.role);
      this.set('role', param.d);

      var first = true;
      var attachmentSrc = _incusVastaanotaConfigEnvironment['default'].pitstopApiRoot + "/View/" + param.uniqueMessageID + ".pdf?";
      for (var k in param) {
        if (param.hasOwnProperty(k)) {
          if (first !== true) {
            attachmentSrc += "&";
          } else {
            first = false;
          }

          attachmentSrc += k + "=" + param[k];
        }
      }

      var model = _ember['default'].Object.extend({
        uniqueMessageID: null
      });

      return model.create({
        uniqueMessageID: param.uniqueMessageID,
        attachmentSrc: attachmentSrc
      });
    },

    actions: {}

  });
});