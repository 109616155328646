define('incus-vastaanota/components/multifile-input', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].TextField.extend({
        type: 'file',
        fileList: null,

        change: function change(evt) {
            var _this = this;

            /*jshint loopfunc:true */

            var _File2ArrayPromise = function _File2ArrayPromise(input) {
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    var reader = new FileReader();

                    reader.onloadstart = function (e) {
                        // TODO Should we display 'file uploading'?
                    };
                    reader.onload = function (e) {
                        var data = e.target.result;
                        resolve(data);
                    };

                    reader.readAsArrayBuffer(input);
                });
            };

            var input = evt.target;
            var oldContext = this;

            var handled = [];
            oldContext.set('fileList', handled);

            if (input.files && input.files[0]) {
                var _loop = function (i) {
                    var current_file = input.files[i];

                    _ember['default'].RSVP.Promise.resolve(_File2ArrayPromise(current_file)).then(function (data) {
                        current_file.data = data;
                    }).then(function () {
                        _this.sendAction('fileChanged', current_file);
                    });
                    //current_file.datapromise = _File2ArrayPromise(current_file);

                    _ember['default'].Logger.log(current_file);
                    handled.pushObject(current_file);
                    //this.sendAction('fileChanged', current_file);
                };

                for (var i = 0; i < input.files.length; i++) {
                    _loop(i);
                }
            }
        }

    });
});