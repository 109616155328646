define("incus-vastaanota/helpers/trafficlight-rdate", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Helper.helper(function (params) {
    var date = params[0];

    if (date.indexOf("2075") === 0) {
      return "";
    }

    return moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');
  });
});