define('incus-vastaanota/components/file-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    type: 'file',
    inputData: null,

    change: function change(evt) {
      var _this = this;

      var input = evt.target;
      if (input.files && input.files[0]) {
        (function () {
          var oldContext = _this;
          var reader = new FileReader();

          reader.onloadstart = function (e) {
            // TODO Should we display 'file uploading'?
          };
          reader.onload = function (e) {
            var data = e.target.result;
            oldContext.set('inputData', data);
          };

          reader.readAsArrayBuffer(input.files[0]);
          _ember['default'].Logger.log(input.files);
          _this.sendAction('fileChanged', input.files[0].name, input.files[0].type, input.files[0].size);
        })();
      }
    }
  });
});