define('incus-vastaanota/pods/traffic/results', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].ArrayController.extend({
    sortProperties: [],
    sortAscending: false,
    actions: {
      sortBy: function sortBy(property) {
        if (this.get('sortAscending')) {
          if (this.get('sortProperties').contains(property)) {
            this.set('sortProperties', []);
            this.set('sortAscending', false);
          } else {
            this.set('sortAscending', false);
            this.set('sortProperties', [property]);
          }
        } else {
          if (this.get('sortProperties').contains(property)) {
            this.set('sortAscending', true);
          } else {
            this.set('sortAscending', false);
            this.set('sortProperties', [property]);
          }
        }
      }
    },

    perPage: 100,

    perPageChanged: (function () {
      this.set("page", 1);
    }).observes("perPage"),

    page: 1,

    pages: (function () {
      var count = this.get('pageCount');
      var currentPage = this.get('page');

      var start = Math.max(1, currentPage - 5);
      var end = Math.min(currentPage + 5, count);
      var pages = [];
      for (var i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    }).property('pageCount', 'page'),

    pageCount: (function () {
      return Math.ceil(this.get('length') / this.get('perPage'));
    }).property('perPage', 'length'),

    pagedContent: (function () {
      var content = this.get('arrangedContent');
      var page = this.get('page');
      var perPage = this.get('perPage');
      var start = (page - 1) * perPage;
      var end = page * perPage;
      return content.slice(start, end);
    }).property('arrangedContent.[]', 'page', 'perPage'),
    allSelected: false,
    allSelectedChanged: (function () {
      var self = this;
      var value = self.get("allSelected");
      _ember['default'].run.next(function () {
        self.get('arrangedContent').forEach(function (el) {
          el.set("isSelected", value);
        });
      });
    }).observes('allSelected'),
    someSelected: (function () {
      var count = this.get('selectedCount');
      return count > 0 && count < this.get('count');
    }).property('selectedCount', 'count'),
    count: _ember['default'].computed.alias('arrangedContent.length'),
    selectedItems: _ember['default'].computed.filterBy('arrangedContent', 'isSelected', true),
    selectedCount: _ember['default'].computed.alias('selectedItems.length'),
    selectedChanged: (function () {
      var self = this;
      _ember['default'].run.next(function () {
        if (self.get('selectedCount') === self.get("count")) {
          if (!self.get('allSelected')) {
            self.set('allSelected', true);
          }
        } else if (self.get('selectedCount') === 0) {
          if (self.get('allSelected')) {
            self.set('allSelected', false);
          }
        }
      });
    }).observes('selectedCount', 'count')
  });
});