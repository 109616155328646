define("incus-vastaanota/components/details-toggle", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    tagName: "div",
    isOpen: false,
    //  classNames: ["open-extra-td"],
    actions: {
      toggleDetailsComponent: function toggleDetailsComponent() {
        this.toggleProperty("isOpen");
      }
    }
  });
});