define('incus-vastaanota/pods/invoice/create/buy/route', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('invoice.create.buy').set('pageTitle', this.get('i18n').t("createPurchaseView.viewTitle").toString());
    },
    model: function model(params) {
      var model = _ember['default'].Object.extend({
        invoiceWriterURL: _incusVastaanotaConfigEnvironment['default'].invoiceWriterURL
      }).create();
      return model;
    }
  });
});