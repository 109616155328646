define("incus-vastaanota/pods/tools/admin/controller", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    apixclient: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),
    session: _ember["default"].inject.service(),

    actions: {
      initIframe: function initIframe() {
        var model = this.get("model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];
        var url = this.get("countryselector.url");
        var that = this;
        iFrameResize({
          log: false,
          heightCalculationMethod: 'lowestElement',
          initCallback: function initCallback(iframe) {
            var userinfo = Object.assign({}, that.get("session.user")[0]); //JSON.parse(window.localStorage.getItem("session"));

            userinfo.asUser = userinfo.UniqueCompanyID;
            userinfo.TransferID = that.get("session.adminSession.mainUser.tid");
            userinfo.TransferKey = that.get("session.adminSession.mainUser.tkey");
            userinfo.UniqueCompanyID = that.get("session.adminSession.mainUser.uid");
            var locale = window.localStorage.getItem("locale");
            if (url === "laskumappi" && locale === 'sv') {
              locale = 'svfi';
            }
            userinfo.locale = locale;
            userinfo.entryPoint = url;
            iframe.iFrameResizer.sendMessage(userinfo);
          },
          messageCallback: function messageCallback(msg) {
            var command = JSON.parse(msg.message);
            if (command.type === "reloadUsers") {
              var mainUser = that.get("session.adminSession.mainUser");
              if (mainUser) {
                apixclient.listUsers(mainUser.tid, mainUser.tkey, mainUser.uid).then(function (data) {
                  if (data.callStatus.success === "OK") {
                    that.set("session.adminSession.users", data.data);
                  }
                })["catch"](function (error) {
                  console.log(error);
                });
              }
            } else if (command.type === "updateCurrentUser") {
              var user = that.get("session.user");
              _ember["default"].set(user.objectAt(0), "Name", command.value.Name);
              _ember["default"].set(user.objectAt(0), "Www", command.value.WWW);
              _ember["default"].set(user.objectAt(0), "Phonenumber", command.value.PhoneNumber);
              _ember["default"].set(user.objectAt(0), "ContactPerson", command.value.ContactPerson);
            }
          }
        }, '#admin');
      }
    }

  });
});