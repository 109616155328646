define('incus-vastaanota/utils/finvoice', ['exports', 'ember', 'incus-vastaanota/models/invoice'], function (exports, _ember, _incusVastaanotaModelsInvoice) {
  exports['default'] = {

    taxCategories: ['24', '14', '10', '0'],

    generateFinvoiceXML: function generateFinvoiceXML(model) {
      var doc = window.document.implementation.createDocument(null, "Finvoice", null);
      var invoice = model.get("invoice");
      _ember['default'].Logger.log(invoice);
      var finvoice = doc.firstChild;
      finvoice.setAttribute("Version", "2.0");
      finvoice.appendChild(this._createSellerPartyDetails(doc, invoice));
      finvoice.appendChild(this._createSellerInformationDetails(doc, invoice));
      finvoice.appendChild(this._createBuyerPartyDetails(doc, invoice));
      var taxCategories = this.taxCategories;
      var elements = this._createSpecsAndRows(doc, invoice, model, taxCategories);
      finvoice.appendChild(this._createInvoiceDetails(doc, invoice, model, elements.vats));

      finvoice.appendChild(this._createPaymentStatusDetails(doc));
      finvoice.appendChild(this._createVirtualBankBarcode(doc, invoice));
      elements.rows.forEach(function (row) {
        finvoice.appendChild(row);
      });
      finvoice.appendChild(this._createEpiDetails(doc, invoice));

      var urlName = doc.createElement("InvoiceUrlNameText");
      urlName.textContent = 'APIX_PDFFILE';
      finvoice.appendChild(urlName);
      var urlText = doc.createElement("InvoiceUrlText");
      urlText.textContent = 'file://' + invoice.get("imageName");
      finvoice.appendChild(urlText);

      var xs = new XMLSerializer();
      var header = "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" + "<!DOCTYPE Finvoice SYSTEM \"Finvoice.dtd\">\n" + "<?xml-stylesheet type=\"text/xsl\" href=\"Finvoice.xsl\"?>\n";
      return header + xs.serializeToString(doc);
    },

    _createSellerPartyDetails: function _createSellerPartyDetails(doc, invoice) {
      var details = doc.createElement("SellerPartyDetails");
      var id = doc.createElement("SellerPartyIdentifier");
      id.textContent = invoice.get("senderVAT");
      var name = doc.createElement("SellerOrganisationName");
      name.textContent = invoice.get("senderName");
      var code = doc.createElement("SellerOrganisationTaxCode");
      code.textContent = "FI" + invoice.get("senderVAT").replace('-', '');
      [id, name, code].forEach(function (el) {
        details.appendChild(el);
      });
      return details;
    },

    _createSellerInformationDetails: function _createSellerInformationDetails(doc, invoice) {
      var details = doc.createElement("SellerInformationDetails");
      var account = doc.createElement("SellerAccountDetails");
      var id = doc.createElement("SellerAccountID");
      id.setAttribute("IdentificationSchemeName", "IBAN");
      id.textContent = invoice.get("bankAccount");
      var code = doc.createElement("SellerBic");
      code.setAttribute("IdentificationSchemeName", "BIC");
      code.textContent = invoice.getBicCode();
      [id, code].forEach(function (el) {
        account.appendChild(el);
      });
      details.appendChild(account);
      return details;
    },
    _createBuyerPartyDetails: function _createBuyerPartyDetails(doc, invoice) {

      var details = doc.createElement("BuyerPartyDetails");
      var id = doc.createElement("BuyerPartyIdentifier");
      id.textContent = invoice.get("receiverVAT");
      var name = doc.createElement("BuyerOrganisationName");
      name.textContent = invoice.get("receiverName");
      [id, name].forEach(function (el) {
        details.appendChild(el);
      });
      return details;
    },

    _createInvoiceDetails: function _createInvoiceDetails(doc, invoice, model, vats) {
      var details = doc.createElement("InvoiceDetails");
      var typeCode = doc.createElement("InvoiceTypeCode");
      typeCode.textContent = 'INV01';
      var typeText = doc.createElement("InvoiceTypeText");
      typeText.textContent = 'LASKU';
      var originCode = doc.createElement("OriginCode");
      originCode.textContent = 'Original';
      var id = doc.createElement("InvoiceNumber");
      id.textContent = invoice.get("documentID");
      var invoiceDate = doc.createElement("InvoiceDate");
      var dateString = invoice.get("documentDate");
      var date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
      invoiceDate.textContent = date;
      invoiceDate.setAttribute("Format", "CCYYMMDD");
      var vatExcluded = doc.createElement("InvoiceTotalVatExcludedAmount");
      vatExcluded.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      vatExcluded.textContent = this._printDecimal(model.get("totalTaxFree"));
      var vatTotal = doc.createElement("InvoiceTotalVatAmount");
      vatTotal.textContent = this._printDecimal(model.get("totalTaxAmount"));
      vatTotal.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      var vatIncluded = doc.createElement("InvoiceTotalVatIncludedAmount");
      vatIncluded.textContent = this._printDecimal(model.get("totalTotal"));
      vatIncluded.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));

      var termsDetails = doc.createElement("PaymentTermsDetails");
      var freeText = doc.createElement("PaymentTermsFreeText");

      freeText.textContent = "Eräpäivä";
      termsDetails.appendChild(freeText);
      var dueDate = doc.createElement("InvoiceDueDate");
      // Due Date is already YYYYMMDD
      dueDate.textContent = invoice.get("dueDate");
      dueDate.setAttribute("Format", "CCYYMMDD");
      termsDetails.appendChild(freeText);
      termsDetails.appendChild(dueDate);

      [typeCode, typeText, originCode, id, invoiceDate, vatExcluded, vatTotal, vatIncluded].concat(vats, termsDetails).forEach(function (el) {
        details.appendChild(el);
      });

      return details;
    },

    _createPaymentStatusDetails: function _createPaymentStatusDetails(doc) {
      var details = doc.createElement("PaymentStatusDetails");
      var paymentCode = doc.createElement("PaymentStatusCode");
      paymentCode.textContent = 'NOTPAID';
      details.appendChild(paymentCode);
      return details;
    },

    _createVirtualBankBarcode: function _createVirtualBankBarcode(doc, invoice) {
      var code = doc.createElement("VirtualBankBarcode");
      code.textContent = invoice.generateBarcode();
      return code;
    },

    _createSpecsAndRows: function _createSpecsAndRows(doc, invoice, model, taxCategories) {
      var _this = this;

      var vats = [];
      var rows = [];
      var itemNumber = 1;
      taxCategories.forEach(function (entry) {
        if (model.get("total" + entry)) {
          vats.push(_this._createVatSpec(doc, invoice, model, entry));
          rows.push(_this._createInvoiceRow(doc, invoice, model, entry, itemNumber++));
        }
      });
      return {
        vats: vats, rows: rows
      };
    },

    _createVatSpec: function _createVatSpec(doc, invoice, model, entry) {

      var details = doc.createElement("VatSpecificationDetails");
      var baseAmount = doc.createElement("VatBaseAmount");
      baseAmount.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      baseAmount.textContent = this._printDecimal(model.get("taxFree" + entry));
      var rate = doc.createElement("VatRatePercent");
      rate.textContent = this._printDecimal(entry);
      var rateAmount = doc.createElement("VatRateAmount");
      rateAmount.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      rateAmount.textContent = this._printDecimal(model.get("taxAmount" + entry));

      [baseAmount, rate, rateAmount].forEach(function (el) {
        details.appendChild(el);
      });
      return details;
    },

    _createInvoiceRow: function _createInvoiceRow(doc, invoice, model, entry, itemNumber) {
      var row = doc.createElement("InvoiceRow");
      var id = doc.createElement("ArticleIdentifier");
      id.textContent = itemNumber;
      var name = doc.createElement("ArticleName");
      name.textContent = 'Tuotteet (' + this._printDecimal(entry) + ' %)';
      var quantity = doc.createElement("DeliveredQuantity");
      quantity.textContent = this._printDecimal(1);
      quantity.setAttribute("QuantityUnitCode", "Kpl");
      var unitPrice = doc.createElement("UnitPriceAmount");
      unitPrice.textContent = this._printDecimal(model.get("taxFree" + entry));
      unitPrice.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      unitPrice.setAttribute("UnitPriceUnitCode", invoice.get("currency") + "/Kpl");
      var rate = doc.createElement("RowVatRatePercent");
      rate.textContent = this._printDecimal(entry);
      var vatAmount = doc.createElement("RowVatAmount");
      vatAmount.textContent = this._printDecimal(model.get("taxAmount" + entry));
      vatAmount.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      var rowTotal = doc.createElement("RowAmount");
      rowTotal.textContent = this._printDecimal(model.get("total" + entry));
      rowTotal.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));

      [id, name, quantity, unitPrice, rate, vatAmount, rowTotal].forEach(function (el) {
        row.appendChild(el);
      });
      return row;
    },

    _createEpiDetails: function _createEpiDetails(doc, invoice) {
      var epi = doc.createElement("EpiDetails");

      var id = doc.createElement("EpiIdentificationDetails");
      var epiDate = doc.createElement("EpiDate");
      var dateString = invoice.get("documentDate");
      var date = moment(dateString, 'YYYYMMDDHHmmss').format("YYYYMMDD");
      epiDate.textContent = date;
      epiDate.setAttribute("Format", "CCYYMMDD");
      var epiRef = doc.createElement("EpiReference");
      epiRef.textContent = invoice.get("paymentReference");
      id.appendChild(epiDate);
      id.appendChild(epiRef);

      var epiParty = doc.createElement("EpiPartyDetails");
      // First epiParty child
      var bfi = doc.createElement("EpiBfiPartyDetails");
      var bfiId = doc.createElement("EpiBfiIdentifier");
      bfiId.textContent = invoice.getBicCode();
      bfiId.setAttribute("IdentificationSchemeName", "BIC");
      bfi.appendChild(bfiId);
      // 2nd epiParty child
      var beneficiary = doc.createElement("EpiBeneficiaryPartyDetails");
      var epiName = doc.createElement("EpiNameAddressDetails");
      epiName.textContent = invoice.get("senderName");
      var bei = doc.createElement("EpiBei");
      bei.textContent = invoice.get("senderVAT");
      var account = doc.createElement("EpiAccountID");
      account.textContent = invoice.get("bankAccount");
      account.setAttribute("IdentificationSchemeName", "IBAN");
      beneficiary.appendChild(epiName);
      beneficiary.appendChild(bei);
      beneficiary.appendChild(account);
      epiParty.appendChild(bfi);
      epiParty.appendChild(beneficiary);

      var payment = doc.createElement("EpiPaymentInstructionDetails");
      var remittance = doc.createElement("EpiRemittanceInfoIdentifier");
      remittance.textContent = invoice.get("paymentReference");
      remittance.setAttribute("IdentificationSchemeName", "SPY");
      var amount = doc.createElement("EpiInstructedAmount");
      amount.textContent = this._printDecimal(invoice.get("amountToPay"));
      amount.setAttribute("AmountCurrencyIdentifier", invoice.get("currency"));
      var charge = doc.createElement("EpiCharge");
      charge.textContent = 'SHA';
      charge.setAttribute("ChargeOption", "SHA");
      var option = doc.createElement("EpiDateOptionDate");
      option.textContent = invoice.get("dueDate");
      option.setAttribute("Format", "CCYYMMDD");
      [remittance, amount, charge, option].forEach(function (el) {
        payment.appendChild(el);
      });

      [id, epiParty, payment].forEach(function (el) {
        epi.appendChild(el);
      });
      return epi;
    },

    _printDecimal: function _printDecimal(number) {
      if (typeof number === 'string') {
        _ember['default'].Logger.log(number + " is a string, casting it to float.");
        number = parseFloat(number);
      }
      return number.toLocaleString("fi-FI", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }

  };
});