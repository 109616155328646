define("incus-vastaanota/pods/terms/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 283,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/terms/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "max-width:800px;color:white;margin:auto;padding:0em 1em 0em 1em;");
          dom.setAttribute(el1, "class", "terms-content");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h1");
          var el3 = dom.createTextNode("VILLKOR");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n12/10/2018\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n1. Parter i avtalet\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nParterna i detta avtal är kunden (\"Kunden\") och Apix Messaging AB, organisationsnummer 556964-9287 (hädanefter benämnt \"Apix\").\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n2. Avtalets omfattning och definitioner\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix och Kunden överenskommer härmed om de villkor som gäller för användning av Apix Messaging Services, i enlighet med vad som anges nedan.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nI detta avtal avser Apix Messaging Services det tjänstepaket som består av Apix produkter och tjänster för elektronisk dataöverföring.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nTjänsterna omfattar konvertering, routing och leverans av meddelanden i elektronisk form. Vid behov skickas meddelandena som traditionella brev.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nVillkoren för produkterna och tjänsterna beskrivs i detalj i bilagan ”Tjänstebeskrivningar”. \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix Messaging Services och relaterade tjänsteprodukter som avses i detta avtal beskrivs mer detaljerat på Apix Messaging AB:s webbplats på www.apixmessaging.se.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix har rätt att ändra, komplettera eller ta bort funktioner i Apix Messaging Services. Om ändringar, tillägg eller borttagningar till servicefunktionerna kräver ändringar i Kundens system, informeras Kunden i god tid före detta.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nDetta avtal ger inga rättigheter till någon datorprogramvara.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n3. Ikraftträdande och upphörande av Apix Messaging Services\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKundens rätt att använda Apix Messaging Services upphör vid avtalets upphörande.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n4. Användning av tjänsten\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden förbinder sig att följa Apix instruktioner för användningen av Apix Messaging Services. Användarinstruktionerna ges av Apix till Kunden när tjänsten tas i bruk.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix ska registrera Kundens kontaktuppgifter i registret för Apix-tjänsten och ska ge Kunden de elektroniska adresser, användar-ID och lösenord som behövs för att kunna använda tjänsten. Apix förbehåller sig rätten att ändra Kundens användaruppgifter och elektroniska adressinformation vid behov, efter att ha informerat Kunden om detta i god tid innan ändringarna görs.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix-tjänsten är tillgänglig för Kunden 24 timmar om dygnet under alla årets dagar, förutom under systemunderhåll eller vid driftstörningar som orsakats av oförutsedda funktionsfel i systemet. Kunden ska meddelas i förväg om avbrott i tjänsten på grund av underhåll, och när så är möjligt ska sådant underhåll utföras utanför vanlig arbetstid. Apix ska försöka att rätta till alla oförutsedda funktionsfel så snart som möjligt.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n4.1 Villkor för Fakturamappen.se \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n Apix Messaging ger Kunden möjligheten att betala för fakturatransaktioner genom köp av elektroniska frimärken, som sedan kan användas till att antingen skicka fakturabrev eller e-fakturor genom tjänsten.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nAlla priser är angivna i svenska kronor och är exklusive mervärdesskatt och andra skatter och avgifter vilka skall betalas av Kunden.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nVarje gång en faktura skickas, dras motsvarande mängd e-frimärken av det av kunden tidigare beställda saldot.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nAlla betalningar sker via vår betalningspartner Dibs Payment Services. Apix sparar ingen information om Kundens betalningar. \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKöpta e-frimärken är giltiga i två (2) år från köpdatumet och de går inte att återinlösa. E-frimärken levereras elektroniskt till Kundens saldo i Fakturamappen.se.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nPersonlig information hanteras i enlighet med information som finns tillgänglig på ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "target", "_blank");
          dom.setAttribute(el3, "href", "www.apixmessaging.se/gdpr");
          var el4 = dom.createTextNode("www.apixmessaging.se/gdpr");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(".\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nFakturamappen.se är endast tillgänglig för företag, inte för privatpersoner. Det går endast att skicka och ta emot fakturor från och till företag.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n5. Betalningar och faktureringskriterier\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n5.1 Betalningsmetod Förskottsbetalning\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden betalar för de tjänster som de använder i förskott med de krediter som erhållits från nätbutiken. Kreditbalansen upprätthålls på Apix servrar. Krediter betalas vid inköpstillfället och krediter kan inte omvandlas till kontanter. Apix förbehåller sig rätten att upphäva de förvärvade kreditnerna om de inte har använts inom två år från inköpsdatumet. Uppdaterad prissättning av tjänster på krediter finns på Apix hemsida.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n5.2 Betalningsmetod\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n5.2.1 Faktureringskriterier\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nAnvändaravgifterna grundar sig på Kundens faktiska sammanlagda månatliga användning som genereras av Kundens slutkunder. Avgifterna faktureras under följande månad baserat på tidigare månaders användning i den valuta som anges i bilagan ”Prislista - Kund”.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nFaktureringsperiod är en (1) månad.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nGrunderna för användningsavgifterna registreras i en separat kundspecifik prislista.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n5.2.2 Betalningsvillkor\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nBetalningsvillkoret för räkningarna är 30 dagar efter fakturadatumet.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nSamtliga avgifter anges i nettopriser, till vilka mervärdesskatt tillkommer till gällande momssats.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nOm betalning sker efter det förfallodatum som anges på fakturan är Kunden skyldig att betala dröjsmålsränta för förseningsperioden till den ränta som fastställs i svensk räntelag och som anges på fakturan. \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n5.2.3 Prisförändringar och faktureringskriterier\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nOm avgifterna ökar, meddelas det månaden före ikraftträdandet till Kunden. Efter förhandlingar har kunden rätt att säga upp sitt avtal när ökningen träder i kraft.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n6. Rättigheter och ansvar\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix ansvarar för att Apix Messaging Services, som tillhandahålls till kunden, överensstämmer med tjänstebeskrivningen.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden är ensam ansvarig för informationsinnehållet i den information som lämnas i Apix Messaging Services. Denna information får inte strida mot gällande lagar, förordningar, andra officiella föreskrifter, god praxis eller allmänt accepterad praxis.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden har rätt till ersättning för direkta skador som orsakats av ett tjänstefel eller avbrott på grund av Apix försumlighet. Kompensationsbeloppet ska emellertid begränsas till betalning av kundens individuella tjänsteegenskaper eller till eventuell framtida betalning under den aktuella faktureringsperioden. Kunden har inte rätt till ersättning för skador som orsakats av egna orsaker till Apix. Apix kommer inte att kompensera för indirekta skador som uppkommit av kunden (till exempel inkomst- eller inkomstförlust, kapitalränta, utebliven vinst, affärsavbrott och skador baserat på avtal mellan kunder och tredje parter).\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nI synnerhet ansvarar Apix inte för eventuella funktionsfel eller störningar på grund av avbrott eller avbrott i driften hos telekommunikationsoperatören eller annan tjänsteleverantör som används i samband med tjänsten.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n7. Sekretess\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix och Kunden ska förbinda sig att behandla allt material och all information konfidentiellt som mottagits från den andra parten och som är märkt konfidentiellt eller som anses som konfidentiell och att inte använda detta material för något annat ändamål än de som anges i detta avtal. Sekretesskravet gäller emellertid inte material eller information som:\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("ul");
          dom.setAttribute(el3, "style", "list-style:none;max-width:620px;margin-left:auto;");
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\n    a. Är allmänt tillgänglig eller på annat sätt offentlig, eller som\n    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\nb. Apix eller Kunden har erhållit från tredje part utan sekretesskyldighet, eller som\n");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\nc. Apix eller Kunden förfogade över utan något sekretesskrav innan materialet eller informationen mottogs direkt från Apix eller Kunden, eller som\n");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("li");
          var el5 = dom.createTextNode("\n    d. Apix eller Kunden själv erhållit utan att använda material eller information som erhållits från den andra parten i detta avtal.\n    ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\nVar och en av parterna i detta avtal ska omedelbart sluta använda konfidentiellt material och information som erhållits av den andra parten och, såvida inget annat överenskommits avseende materialets bortskaffande, returnera materialet i fråga och alla kopior av materialet vid upphörandet av detta avtal eller när parten inte längre behöver materialet för något ändamål i enlighet med detta avtal. Båda parterna i detta avtal har rätt att behålla de kopior som krävs enligt lag eller enligt officiella bestämmelser.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nDe rättigheter och skyldigheter som anges i punkt 6 i detta avtal ska fortsätta gälla efter upphörandet av detta avtal.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n8. Force majeure\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nIngendera parten i detta avtal är ansvarig för eventuella förseningar eller skador som orsakas av ett oöverstigligt hinder som ligger utanför partens kontroll, som parten inte rimligen kunnat förutse vid tecknandet av avtalet och vars effekter parten inte rimligen kunnat undvika eller motverka. Förhållanden som ska betraktas som ett sådant hinder är brand, krigstillstånd, uppror, rekvisition, civila oroligheter, beslag, strejk, embargo, bojkott eller annan industriell åtgärd, inbegripet när en part i detta avtal är en del av, eller föremål för, en sådan åtgärd.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nEndera parten i detta avtal ska omedelbart och skriftligen underrätta den andra parten om förekomsten av ett oöverstigligt hinder samt om upphörandet av ett sådant hinder. \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n9. Kontaktpersoner\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden anger egna kontaktpersoner i kundrelationen. På Apix hemsida finns kontaktuppgifter till Apix.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n10. Användande av kundinformation\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix har rätt att använda kundregistrets information i egen marknadsföring om inte annat överenskommits. Apix har rätt att vidarebefordra kundens namn till tredje part för användning i allmänna marknadsundersökningar eller jämförbara publikationer. Du har rätt att helt eller delvis förbjuda Apixa att använda eller avslöja information om dig på det sätt som beskrivs ovan.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n11. Tillämplig lag och lösning av tvister\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nSvensk lag gäller för detta avtal.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nTvister som rör avtal ska i första hand lösas genom förhandlingar mellan parterna.  Om sådana förhandlingar inte leder till en lösning av tvisten, ska tvisten slutgiltigt avgöras genom medling i enlighet med Stockholms Handelskammare Medlingsinstituts regler, såvida inte någon av parterna motsätter sig detta. \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nOm endera parten motsätter sig medling eller om medlingen avbryts ska tvisten alternativt bestämmas genom skiljeförfarande i enlighet med i Stockholms Handelskammares Skiljedomsinstituts regler.  Medling eller skiljeförfarande ska äga rum i Stockholm och genomföras på svenska.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nUppgifter som framkommer i samband med medling/skiljeförfarande ska anses utgöra konfidentiell information. \n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKonsumenter har rätt att även väcka tvist i svensk tingsrätt.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n12. Överföring av avtal\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix ska ha rätt att överlåta detta avtal till tredje part efter att ha skickat en skriftlig underrättelse härom till Kunden minst två (2) veckor före överlåtelsen.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden har rätt att överlåta detta avtal till tredje part efter att ha skickat en skriftlig underrättelse härom till Apix minst en (1) månad före överlåtelsen.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n13. Bilagor och ändringar av avtalet\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix förbinder sig att tillhandahålla Kunden en tjänst som överensstämmer med beskrivningen av Apix Messaging Service.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden är ensam ansvarig för innehållet i de uppgifter som Kunden överför via Apix-tjänsten. Dessa uppgifter får inte strida mot gällande lagar, förordningar, andra officiella föreskrifter, god praxis eller allmänt godkända förfaranden.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix förbehåller sig rätten att ändra funktionerna, att lägga till funktioner och att ta bort funktioner från Apix Messaging Service. Om eventuella ändringar eller tillägg eller borttagna funktioner i tjänsten kräver ändringar i Kundens system, ska de kommande ändringarna meddelas Kunden i god tid.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix är inte ansvarigt för eventuella kostnader som uppstår för Kunden på grund av dessa ändringar. Apix har dock inte rätt att ensidigt nedgradera kvaliteten på den tjänst som Kunden eller en avtalspart till Kunden erhållit vid genomförandet av någon av ovannämnda förändringar, såvida detta inte är ett resultat av en tredje parts agerande som ligger utanför Apix kontroll.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nEventuella förändringar som orsakats av tredje parts agerande ska registreras i en separat bilaga som bifogas detta avtal.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden ska ha rätt att få ersättning för direkta förluster som orsakas av avbrott i användningen av tjänsten om avbrottet beror på Apix försumlighet. Ersättningsbeloppet ska begränsas till beloppet för de avgifter som Kunden har betalt eller är skyldig att betala under den faktureringsperiod som anges i sista stycket i punkt 6.2 i detta avtal. Kunden har inte rätt att erhålla ersättning för förluster som uppkommer av anledningar som ligger utanför Apix kontroll. Apix ersätter inte Kunden för eventuella indirekta förluster som drabbar Kunden.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nI synnerhet ska Kunden inte ha rätt till ersättning från Apix för avbrott i tjänsten som orsakats av avbrott eller driftstörningar i system eller tjänster hos en teleoperatör eller annan tjänsteleverantör.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix ger inte ut några rättigheter till tillämpningsprogram till Kunden i samband med detta avtal.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n14. Giltighet av avtalet\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nAvtal träder i kraft på dagen för dess undertecknande och fortsätter att gälla tillsvidare.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nEfter att tjänsten har kommit igång ska Kunden ha rätt att säga upp avtalet med upphörande tre månader efter den aktuella faktureringsperioden genom att skicka en skriftlig underrättelse härom minst två (2) veckor före faktureringsmånadens slut. Redan gjorda betalningar återbetalas inte.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden har dessutom rätt att säga upp avtalet i det fall som anges i punkt 6.4 i detta avtal.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix har rätt att säga upp avtalet med upphörande tre (3) månader efter datumet för den skriftliga underrättelsen.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nKunden har rätt att säga upp avtalet om Apix, trots en skriftlig påminnelse, väsentligen försummar att uppfylla sina skyldigheter enligt detta avtal.\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nApix ska ha rätt att säga upp avtalet utan att iaktta uppsägningstiden om:\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "style", "list-style:none;max-width:620px;margin-left:auto;");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\na. Kunden inte har betalt en utestående faktura senast två (2) veckor efter en skriftlig betalningspåminnelse,\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\nb. Kunden väsentligen bryter mot villkoren i detta avtal,\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\nc. Kundens anslutning används för att orsaka störningar i Apix-tjänsten eller för andra användare,\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("li");
          var el4 = dom.createTextNode("\nd. Kunden har ansökt om konkurs eller om Kunden har lämnat in en offentlig stämning mot sina fordringsägare eller om Kunden på annat sätt har förklarats betalningsoförmögen.\n");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\nVid uppsägning av detta avtal har ingen av avtalsparterna rätt att kräva ersättning för eventuella förluster som uppkommit av uppsägningen\n");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 283,
              "column": 0
            },
            "end": {
              "line": 443,
              "column": 8
            }
          },
          "moduleName": "incus-vastaanota/pods/terms/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "max-width:800px;color:white;margin:auto;");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h1");
          dom.setAttribute(el2, "id", "palveluehdot");
          var el3 = dom.createTextNode("PALVELUEHDOT");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h3");
          dom.setAttribute(el2, "id", "2792011");
          var el3 = dom.createTextNode("27.9.2011");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n1. Sopimuksen osapuolet");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tämän sopimuksen osapuolet ovat palvelun käyttöön ottava asiakas (jäljempänä “Asiakas”) ja Apix Messaging Oy, Y-tunnus 2332748-7 (jäljempänä “Apix”).");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n2. Sopimuksen kohde ja määritelmät");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tällä sopimuksella Apix ja Asiakas sopivat Apix Sanomanvälityspalveluiden käytöstä. Apix Sanomanvälitypalveluilla tarkoitetaan tässä sopimuksessa sähköiseen tiedonsiirtoon liittyvien Apixin tuotteiden ja -palveluiden muodostamaa kokonaisuutta. Apixin Sanomanvälityspalvelut kattavat aineistojen muunnokset, reitittämisen ja jakelun täysin sähköisesti. Tarvittaessa aineistot lähetetään tulostettaviksi paperille. Apixin palveluihin sisältyy myös sähköinen arkistointi.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tässä sopimuksessa tarkoitettu Apix Sanomanvälityspalvelu ja siihen liittyvät palvelutuotteet on tarkemmin kuvattu Apix Messaging Oy:n internetsivuilla osoitteessa www.apix.fi/palvelukuvaukset.html.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apixilla on oikeus muuttaa, täydentää tai poistaa Apix Sanomanvälityspalveluiden ominaisuuksia. Mikäli palvelunominaisuuksiin tulevat muutokset, täydennykset tai poistot edellyttävät muutoksia Asiakkaan järjestelmissä, ilmoitetaan muutoksista Asiakkaalle hyvissä ajoin etukäteen.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tällä sopimuksella ei luovuteta Asiakkaalle mitään oikeuksia mihinkään atk-ohjelmiin.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n3. Apix Sanomanvälityspalveluiden alkaminen ja päättyminen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakkaan oikeus käyttää Apix Sanomanvälityspalveluita päättyy tämän sopimuksen päättyessä.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n4. Palvelun käyttö");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakas sitoutuu noudattamaan Apixin antamia Apix Sanomanvälityspalveluiden käyttöä koskevia ohjeita. Ohjeet löytyvät ajantasaisina osoitteesta www.apix.fi. Asiakas sitoutuu lukemaan ja noudattamaan näitä ohjeita ennen kuin ottaa palvelun käyttöön. Apix rekisteröi Apix Sanomanvälityspalveluiden hakemistoon Asiakkaan nimi- ja osoitetiedot sekä antaa käyttäjälle palvelun käytössä tarvittavat osoitteet, tunnukset ja salasanat. Apixilla on tarvittaessa oikeus muuttaa Asiakkaan käyttäjä- ja osoitetietoja ilmoitettuaan siitä Asiakkaalle hyvissä ajoin ennen muutosta. Asiakas vastaa tunnisteellaan tai salasanallaan tehdystä palvelun käytöstä.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apix Sanomanvälityspalvelu on Asiakkaan käytettävissä 24 tuntia vuorokaudessa vuoden jokaisena päivänä lukuun ottamatta järjestelmän huollon tai ennalta arvaamattomien vikatilanteiden edellyttämiä käyttökeskeytyksiä. Huollon vaatimista katkoksista ilmoitetaan Asiakkaalle etukäteen ja ne pyritään ajoittamaan siten, että ne toteutetaan pääsääntöisesti normaalin työajan ulkopuolella. Apix pyrkii selvittämään ennalta arvaamattomat vikatilanteet mahdollisimman nopeasti.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n5. Maksut ja laskutusperusteet");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("5.1 Maksutapana etukäteismaksu");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakas maksaa käyttämistään palveluista etukäteen verkkokaupasta hankittavilla krediiteillä. Krediittien saldoa ylläpidetään Apixin palvelimilla. Krediitiit maksetaan niiden hankintahetkellä ja krediittejä ei voi vaihtaa takaisin rahaksi. Apix varaa oikeuden mitätöidä hankitut krediitit mikäli niitä ei ole käytetty kahden vuoden kuluessa hankintahetkestä. Palveluiden ajantasainen hinnoittelu krediiteissä on saatavilla Apixin verkkosivuilta.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n5.2 Maksutapana lasku");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("5.2.1 Laskutusperusteet");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apix Sanomanvälityspalveluista laskutetaan Asiakkaan palvelun käytön määrän perusteella kuukausittain jälkikäteen.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Käyttömaksujen perusteet ovat kirjattu erilliseen asiakaskohtaiseen hinnastoon.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("5.2.2 Maksuehdot");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Laskujen maksuehto on 14 päivää netto laskun päiväyksestä.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Maksut on ilmoitettu nettohintoina, joihin lisätään kulloinkin voimassa oleva arvonlisävero. Jos maksun suoritus viivästyy laskussa mainitusta eräpäivästä, Asiakas on velvollinen suorittamaan erääntymispäivän jälkeiseltä ajalta laskussa mainitun Suomen korkolain mukaisen viivästyskoron.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("5.2.3 Muutokset hintoihin ja laskutusperusteisiin");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Mikäli maksuissa tapahtuu korotuksia, neuvotellaan tästä kuukausi ennen korotusten voimaantuloa asiakkaan kanssa. Asiakkaalla on oikeus neuvottelun jälkeen irtisanoa sopimuksensa päättyväksi korotuksen tullessa voimaan.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n6. Oikeudet ja vastuut");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apix vastaa siitä, että sen Asiakkaalle tuottama Apix Sanomanvälityspalvelu on palvelukuvauksen mukainen.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakas on yksin vastuussa Apix Sanomanvälityspalveluiden puitteissa lähettämänsä tiedon tietosisällöstä. Tieto ei saa olla voimassa olevien lakien, asetusten, muiden viranomaismääräysten, hyvien tapojen tai yleisesti hyväksyttyjen menettelytapojen vastainen.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakkaalla on oikeus saada korvaus välittömistä vahingoista, jotka aiheutuvat Apixin tuottamuksesta johtuvasta palvelun käyttökatkoksesta tai häiriöstä. Korvauksen määrä rajoittuu kuitenkin Asiakkaan yksittäisestä palveluominaisuudesta suorittamaan maksuun tai suoritettavaksi tulevaan maksuun kyseisellä laskutuskaudella. Asiakkaalla ei ole oikeutta saada korvausta Apixsta riippumattomista syistä aiheutuvista vahingoista. Apix ei korvaa Asiakkaalle aiheutuneita välillisiä vahinkoja (esimerkiksi tulon- tai ansion menetystä, pääoman korkotappioita, saamatta jäänyttä voittoa, liiketoiminnan keskeytymistä, sekä asiakkaan ja kolmannen väliseen sopimukseen perustuvia vahingonkorvauksia).");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Erityisesti todetaan, ettei Asiakkaalla ole oikeutta saada Apixilta korvausta palvelun käyttökatkoksesta tai häiriöstä, joka johtuu palvelun yhteydessä käytetyn teleoperaattorin tai muun palvelun tuottajan toiminnan keskeytyksistä tai häiriöistä.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n7. Salassapito");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apix sekä Asiakas sitoutuvat pitämään luottamuksellisina toisiltaan saamansa aineistot ja tiedot, jotka on merkitty luottamukselliseksi tai jotka on sellaisiksi ymmärrettävä, sekä olemaan käyttämättä niitä muihin kuin sopimuksen mukaisiin tarkoituksiin.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Salassapitovelvollisuus ei kuitenkaan koske aineistoa ja tietoa:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("a. joka on yleisesti saatavilla taikka muuten julkista tai");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("b. jonka Apix tai Asiakas on saanut kolmannelta osapuolelta ilman salassapitovelvollisuutta tai");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("c. joka oli Apixin tai Asiakkaan hallussa ilman niitä koskevaa salassapitovelvollisuutta ennen tietojen tai aineiston saamista Apixilta tai Asiakkaalta tai");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("d. jonka Apix tai Asiakas on itsenäisesti kehittänyt hyödyntämättä toiselta tämän sopimuksen osapuolelta saamaansa aineistoa tai tietoa.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tämän sopimuksen osapuolen on välittömästi lopetettava toiselta osapuolelta saamansa luottamuksellisen aineiston ja tietojen käyttäminen sekä, ellei erikseen sovita aineiston hävittämisestä, palautettava kyseinen aineisto kaikkine kopioineen, kun sopimus päättyy tai kun sopimuksen osapuoli ei enää tarvitse kyseistä aineistoa, tai kyseisiä tietoja sopimuksen mukaiseen tarkoitukseen. Kummallakin tämän sopimuksen osapuolella on kuitenkin oikeus säilyttää lain tai viranomaisten määräysten edellyttämät kopiot.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tämän sopimuksen kohtaan 6 liittyvät oikeudet ja velvoitteet jäävät voimaan sopimuksen päättymisen jälkeenkin.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n8. Ylivoimainen este");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Kumpikaan tämän sopimuksen osapuolista ei vastaa viivästyksistä tai vahingoista, jotka johtuvat hänen vaikutusmahdollisuuksiensa ulkopuolella olevasta esteestä, jota hänen ei kohtuudella voida edellyttää ottaneen huomioon sopimuksentekohetkellä ja jonka seurauksia hän ei myöskään kohtuudella olisi voinut välttää tai voittaa. Edellä mainitun kaltaisiksi esteiksi katsotaan mm. tulipalo, sota, kapina, pakko-otto, mellakka, takavarikko, lakko, sulku, boikotti ja muu työtaistelu, myös silloin kun tämän sopimuksen osapuoli on itse sen kohteena tai siihen osallisena.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tämän sopimuksen osapuolen on viipymättä ilmoitettava ylivoimaisesta esteestä kirjallisesti toiselle sopimuksen osapuolelle, samoin kuin esteen lakkaamisesta.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n9. Yhteyshenkilöt");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakas nimittää yhteyshenkilön asiakassuhteeseen. Apixin puolelta yhteyshenkilö ilmoitetaan servicedesk-palvelussa www.apix.fi.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n10. Asiakastietojen luovuttaminen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apixilla on oikeus käyttää asiakasrekisterin tietoja omassa markkinoinnissaan ellei tästä erityisesti ole muuta sovittu. Apixilla on oikeus luovuttaa Asiakkaiden nimet myös kolmansille osapuolille käytettäviksi yleisissä markkinatutkimuksissa tai niihin verrattavissa julkaisuissa. Asiakkaalla on oikeus kokonaan tai osittain kieltää Apixa käyttämästä tai luovuttamasta häntä koskevia tietoja edellä mainituin tavoin.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n11. Sovellettava laki ja erimielisyyksien ratkaiseminen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tähän sopimukseen sovelletaan Suomen lakia.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Sopimuksesta mahdollisesti aiheutuvat riidat pyritään pääsääntöisesti ratkaisemaan sopijapuolten välisin neuvotteluin. Tästä sopimuksesta aiheutuvat riidat ratkaistaan lopullisesti välimiesmenettelyssä Keskuskauppakamarin välimieslautakunnan sääntöjen mukaisesti yhden (1) välimiehen toimesta. Riita voidaan saattaa myös ratkaistavaksi sovinnollisesti Suomen Asianajajaliiton sovintomenettelysääntöjen mukaan tapahtuvassa sovintomenettelyssä, mikäli sopijapuolet siitä kirjallisesti sopivat.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Kuluttajaasiakkaalla on oikeus saattaa riita-asia vireille myös Suomessa sijaitsevan kotipaikkansa käräjäoikeudessa.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n12. Sopimuksen siirtäminen");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apixilla on oikeus siirtää tämä kolmannelle ilmoitettuaan kirjallisesti siirrosta Asiakkaalle viimeistään kaksi (2) viikkoa ennen siirtoa. Asiakkaalla ei ole oikeutta siirtää sopimusta kolmannelle.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n13. Sopimuksen lisäykset ja muutokset");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apixilla on oikeus muuttaa, täydentää tai poistaa palvelun ominaisuuksia. Apixilla on oikeus ilmoittaa tämän sopimuksen ehtojen muutoksesta julkaisemalla muutetut ehdot palveluntarjoajan www-sivustolla. Muutos tulee voimaan, kun se on julkaistu palveluntarjoajan www-sivustolla.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Mikäli palvelun ominaisuuksiin tulevat muutokset, täydennykset tai poistot edellyttävät muutoksia Asiakkaan järjestelmissä, ilmoitetaan muutoksista Asiakkaalle hyvissä ajoin etukäteen. Apix ei vastaa näistä muutoksista mahdollisesti Asiakkaalle aiheutuvista kustannuksista. Apixilla ei kuitenkaan ole yksipuolisesti oikeutta heikentää Asiakkaan tai tämän sopimuskumppanin saavuttamaa palvelutasoa edellä mainituilla muutoksilla, täydennyksillä tai poistoilla, elleivät heikennykset johdu Apixsta riippumattomista kolmannen osapuolen toimenpiteistä, joihin Apix ei voi vaikuttaa.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n14. Sopimuksen voimassaolo");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Sopimus on voimassa allekirjoituspäivästä alkaen toistaiseksi.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakkaalla on oikeus irtisanoa sopimus päättymään kolmen (3) kuukauden irtisanomisajalla ilmoittamalla tästä kirjallisesti. Suoritettuja maksuja ei palauteta.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Asiakkaalla on lisäksi oikeus irtisanoa sopimus tämän sopimuksen kohdan 13 tarkoittamassa tapauksessa.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apixilla on oikeus irtisanoa sopimus päättymään kolmen (3) kuukauden kuluttua kirjallisen ilmoituksen päiväyksestä.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Apixilla on oikeus irtisanomisaikaa noudattamatta purkaa sopimus tai estää palvelun käyttö seuraavissa tapauksissa:");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("a. Asiakas olennaisesti rikkoo näitä sopimusehtoja.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("b. Asiakkaan liittymää käytetään häiriön aiheuttamiseen Apix Sanomanvälityspalveluille tai muille käyttäjille.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("c. Asiakas on haettu konkurssiin tai hän on hakenut julkista haastetta velkojilleen tai Asiakas on muutoin todettu maksukyvyttömäksi.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("Tämän sopimuksen sopijapuolilla ei ole sopimuksen purkautuessa oikeutta vaatia vahingonkorvausta yllä mainituista syistä johtuneen purkamisen johdosta.");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("br");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n\n            \n\n            \n	    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 443,
            "column": 15
          }
        },
        "moduleName": "incus-vastaanota/pods/terms/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-top");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["common.terms"], [], ["loc", [null, [3, 13], [3, 33]]]], ["block", "if", [["subexpr", "eq", [["get", "model.countryselector.url", ["loc", [null, [6, 10], [6, 35]]]], "fakturamappen"], [], ["loc", [null, [6, 6], [6, 52]]]]], [], 0, 1, ["loc", [null, [6, 0], [443, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});