define("incus-vastaanota/pods/components/css-spinner/component", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    spinner: _ember["default"].inject.service(),

    actions: {
      removeMessage: function removeMessage(message) {
        this.get("spinner.messages").removeObject(message);
        _ember["default"].run.cancel(message.removeTimer);
      }
    }
  });
});