define('incus-vastaanota/models/formdata', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  var FormField = _ember['default'].Object.extend({
    field: undefined,
    invalid: false,
    mandatory: true
  });

  exports['default'] = _ember['default'].Object.extend({
    businessId: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    companyName: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    additionalName: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: false
    }),
    streetAddress1: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    streetAddress2: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: false
    }),
    postCode: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    postOffice: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    country: FormField.create({
      field: _incusVastaanotaConfigEnvironment['default'].build === 'laskumappi' ? 'FI' : 'SE',
      invalid: false,
      mandatory: true
    }),
    softwareName: FormField.create({
      field: _incusVastaanotaConfigEnvironment['default'].build === 'laskumappi' ? 'FI' : 'SE',
      invalid: false,
      mandatory: true
    }),

    contactPerson: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    phoneNumber: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    language: FormField.create({
      field: _incusVastaanotaConfigEnvironment['default'].build === 'laskumappi' ? 'fi_FI' : 'sv_SE',
      invalid: false,
      mandatory: true
    }),
    website: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: false
    }),
    username: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    password: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),
    passwordVerification: FormField.create({
      field: undefined,
      invalid: false,
      mandatory: true
    }),

    fieldNames: ["businessId", "companyName", "additionalName", "streetAddress1", "streetAddress2", "postCode", "postOffice", "country", "contactPerson", "phoneNumber", "language", "website", "username", "password", "passwordVerification"],

    categories: [{
      name: "send",
      apiName: "Send",
      field: false,
      services: [{
        name: "duplicateCheck",
        apiName: "Dupcheck",
        field: true
      }, {
        name: "accounting",
        apiName: "Accountingcopy",
        field: false
      }, {
        name: "priority",
        apiName: "Priority",
        field: false
      }, {
        name: "consumerInvoicing",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "edi",
        field: false,
        properties: {
          isAdvert: true
        }
      },
      // should the defaults be set to true by an observer?
      {
        name: "debtCollect",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "factoringCopy",
        field: false,
        properties: {
          isAdvert: true
        }
      }]
    }, {
      name: "receive",
      apiName: "Receive",
      field: false,
      services: [{
        name: "arrivalNotification",
        apiName: "InvoiceArrivalNotify",
        field: false,
        parameter: null,
        properties: {
          hasEditableParameter: true
        }
      }, {
        name: "expiryNotification",
        apiName: "InvoicePaymentExpiryNotify",
        field: false,
        parameter: null,
        properties: {
          hasEditableParameter: true
        }
      }, {
        name: "scanning",
        apiName: "Scanning",
        field: _incusVastaanotaConfigEnvironment['default'].build === 'laskumappi',
        parameter: 'OC'
      }, {
        name: "emailScan",
        field: false,
        properties: {
          isAdvert: true
        }
      }, {
        name: "edi",
        field: false,
        properties: {
          isAdvert: true
        }
      }]
    }, {
      name: "archive",
      apiName: "Archive",
      field: false
    }]
  });
});