define('incus-vastaanota/components/checkbox-list-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].TextField.extend({
    placeholder: 'email@address.com',

    observeInput: (function () {
      var inp = this.get('value');
      this.set('term', inp);
    }).observes('value'),

    /*  didInsertElement: function() {
        let val = this.get('term');
        this.setProperties({
          activeIndex: -1,
          value: val
        });
      },
      */
    keyDown: function keyDown(e) {
      var offset = e.keyCode === 38 ? -1 : e.keyCode === 40 ? 1 : 0; // up or down

      var aI = this.get('activeIndex'),
          newIndex = aI + offset,
          results = this.get('results');
      if (offset) {
        if (newIndex >= 0 && newIndex < results.length) {
          this.setProperties({
            activeIndex: newIndex
          });
        }
        e.preventDefault();
      }
      // When enter is pressed, if the selection matches, use the selection, otherwise use the filter term as a brand new email address.
      else if (e.keyCode === 13) {
          var term = this.get('term');
          if (!term && results[aI]) {
            this.sendAction('select', results[aI]);
          } else {
            this.sendAction('addEmailAddress', term);
          }
        }
    }
  });
});