define("incus-vastaanota/utils/date-utils", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = {
    twod: function twod(v) {
      return v < 10 ? "0" + v : "" + v;
    },

    parseTime: function parseTime(value, includeTime) {
      if (!value) {
        return null;
      }

      var result = [0, 0, 0, 0, 0, 0];
      var date = value.match(/^(\d{4})-(\d\d)-(\d\d)/);
      if (!date) {
        date = value.match(/^(\d{4})(\d{2})(\d{2})/);
      }

      result[0] = date[1] - 0;
      result[1] = date[2] - 1;
      result[2] = date[3] - 0;

      if (includeTime) {
        var time = value.match(/(\d\d):(\d\d):(\d\d)[.\d]*$/);
        result[3] = time[1] - 0;
        result[4] = time[2] - 0;
        result[5] = time[3] - 0;
      }

      return new Date(result[0], result[1], result[2], result[3], result[4], result[5]);
    },

    formatTime: function formatTime(value, includeTime) {
      if (!value) {
        return "";
      }
      value = new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000);
      return this.twod(value.getDate()) + "." + this.twod(value.getMonth() + 1) + "." + value.getFullYear() + (includeTime ? " " + this.twod(value.getHours()) + ":" + this.twod(value.getMinutes()) : "");
    }
  };
});