define('incus-vastaanota/pods/archives/received/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/models/invoice', 'incus-vastaanota/utils/list-utils'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsInvoice, _incusVastaanotaUtilsListUtils) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var startDate = moment().subtract(30, 'day').format('YYYYMMDD');
      var endDate = moment().format('YYYYMMDD');
      var locale = _incusVastaanotaConfigEnvironment['default'].defaultLocale;
      if (window.localStorage) {
        locale = window.localStorage.getItem('locale');
      }
      return apixclient.searchReceivedArchive(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, startDate, endDate, '', '', '').then(function (data) {
        var tempArray = _incusVastaanotaUtilsListUtils['default'].processSearchReceivedArchiveData(data, apixclient, userData);
        var invoiceList = tempArray.shift();

        var model = _ember['default'].Object.extend({

          fields: [{
            fieldName: 'imageLink',
            numeric: false,
            sortable: false
          }, {
            fieldName: 'documentLink',
            numeric: false,
            sortable: false
          }, {
            fieldName: 'documentID',
            numeric: true,
            sortable: true
          }, {
            fieldName: 'documentDate',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'processingDate',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'receiverName',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'senderName',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'messageType',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'dueDate',
            numeric: false,
            sortable: true
          }, {
            fieldName: 'amountToPay',
            numeric: true,
            sortable: true
          }],

          sortProperty: 'createdDate',
          sortDirection: false,
          sortNumeric: false,

          queryDone: true,

          // Used for filtering the resultset by date
          startDate: moment().subtract(30, 'day').toDate(),
          endDate: new Date(),
          dueDate: '',
          senderFilter: '',
          documentIDFilter: '',
          freeText: '',
          locale: locale,

          invoiceListDynamic: _ember['default'].computed('sortProperty', 'sortDirection', 'sortNumeric', 'senderFilter', 'invoiceList.[]', function () {
            var list = this.get("invoiceList");
            var senderFilter = this.get('senderFilter');
            var invoiceArray = list.filter(function (invoice) {
              var includeInvoice = true;
              if (senderFilter) {
                _ember['default'].Logger.log(senderFilter);
                var re = new RegExp(senderFilter, 'i');
                if (!re.test(invoice.senderName)) {
                  includeInvoice = false;
                }
              }
              return includeInvoice;
            });

            var sortProperty = this.get("sortProperty");
            var sortNumeric = this.get("sortNumeric");
            var sortDirection = this.get("sortDirection");
            if (sortProperty) {
              if (sortProperty === "processingDate") {
                sortProperty = "createdDate";
              }

              invoiceArray.sort(_incusVastaanotaUtilsListUtils['default'].dynamicSort(sortProperty, sortDirection, sortNumeric));
            }
            this.set('queryDone', true);
            this.set('resultCount', invoiceArray.length);
            return invoiceArray;
          })
        }).create({
          invoiceList: invoiceList
        });
        return model;
      });
    },

    actions: {
      /*   syncData(component, event) {
          console.log(this.get("controller.model." + event.target.name))
          console.log(event.target.value)
          let date = event.target.value.split(".")
          console.log(date)
          console.log(new Date(date[2],date[1],date[0]))
          this.set("controller.model." + event.target.name, new Date(date[2] + "." + date[1] + "." + date[0]))
          console.log(component)
          console.log(event)
        }, */
      setSortProperty: function setSortProperty(sortProperty, numeric, sortable) {
        if (!sortable) {
          return;
        }
        var currentSort = this.get("controller.model.sortProperty");
        if (currentSort === sortProperty) {
          var sortDirection = this.get("controller.model.sortDirection");
          this.set("controller.model.sortDirection", !sortDirection);
        } else {
          this.set("controller.model.sortDirection", false);
        }
        if (numeric) {
          this.set("controller.model.sortNumeric", true);
        } else {
          this.set("controller.model.sortNumeric", false);
        }
        this.set("controller.model.sortProperty", sortProperty);
      },
      clearAllDateFilters: function clearAllDateFilters() {
        this.set('controller.model.startDate', moment().subtract(30, 'day').toDate());
        this.set('controller.model.endDate', new Date());
        this.set('controller.model.dueDate', '');
        this.set('controller.model.senderFilter', '');
        this.set('controller.model.documentIDFilter', '');
        this.set('controller.model.freeText', '');
      },
      searchArchive: function searchArchive() {
        var _this = this;

        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];
        var startDate = _incusVastaanotaUtilsListUtils['default'].formatDate(this.get('controller.model.startDate'));
        var endDate = _incusVastaanotaUtilsListUtils['default'].formatDate(this.get('controller.model.endDate'));
        var dueDate = _incusVastaanotaUtilsListUtils['default'].formatDate(this.get('controller.model.dueDate'));
        var senderNameParameter = this.get('controller.model.senderNameParameter');
        var documentID = this.get('controller.model.documentIDFilter');
        var freeText = this.get('controller.model.freeText');
        _ember['default'].Logger.log("Serach for freetext:" + freeText);
        if (!senderNameParameter) {
          senderNameParameter = '';
        }
        if (!documentID) {
          documentID = '';
        }
        if (!freeText) {
          freeText = '';
        }
        this.set('queryDone', false);
        this.set('controller.model.invoiceList', []);
        return apixclient.searchReceivedArchive(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, startDate, endDate, dueDate, documentID, freeText, senderNameParameter).then(function (data) {
          var tempArray = _incusVastaanotaUtilsListUtils['default'].processSearchReceivedArchiveData(data, apixclient, userData);
          var invoiceList = tempArray.shift();
          var queryLimitExceeded = tempArray.shift();

          if (queryLimitExceeded) {
            _this.set("controller.model.queryLimitExceeded", 1);
            var notify = _this.get('notify');
            notify.warning("Search criteria matched too many invoices. Please refine search.", {
              autoClear: true,
              clearDuration: 10000
            });
          } else {
            _this.set("controller.model.queryLimitExceeded", "");
          }
          _ember['default'].Logger.log(invoiceList);
          _this.set('controller.model.invoiceList', invoiceList);
        });
      },
      removeItem: function removeItem(item) {
        var _this2 = this;

        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];
        var i18n = this.get("i18n");
        var list = this.get("controller.model.invoiceList");
        var confirmationText = i18n.t("traffic.tables.pitstopDeleteConfirmation").toString();
        var r = window.confirm(confirmationText + " " + item.documentID + "?");
        if (r) {

          apixclient.deleteStorage(userData.TransferID, item.storageID, userData.TransferKey).then(function (data) {
            _ember['default'].Logger.log(data);
            if (data.callStatus.success === "OK") {
              var invoiceArray = list.filter(function (invoice) {
                return invoice.storageID !== item.storageID;
              });
              _this2.set("controller.model.invoiceList", invoiceArray);
            }
          });
        }
      }

    }
  });
});