define("incus-vastaanota/pods/traffic/pitstop/confirm/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 1
            },
            "end": {
              "line": 30,
              "column": 1
            }
          },
          "moduleName": "incus-vastaanota/pods/traffic/pitstop/confirm/template.hbs"
        },
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
          morphs[5] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
          return morphs;
        },
        statements: [["content", "object.senderName", ["loc", [null, [22, 9], [22, 30]]]], ["content", "object.documentID", ["loc", [null, [23, 9], [23, 30]]]], ["content", "object.sumHuman", ["loc", [null, [24, 9], [24, 28]]]], ["content", "object.dueDateHuman", ["loc", [null, [25, 9], [25, 32]]]], ["content", "object.provisionHuman", ["loc", [null, [26, 9], [26, 34]]]], ["content", "object.sumWithoutProvisionHuman", ["loc", [null, [27, 9], [27, 44]]]], ["inline", "input", [], ["type", "email", "required", "true", "size", "40", "value", ["subexpr", "@mut", [["get", "object.receiverEmail", ["loc", [null, [28, 62], [28, 82]]]]], [], []], "placeholder", ["subexpr", "t", ["traffic.pitstop.receiverEmail"], [], ["loc", [null, [28, 95], [28, 130]]]]], ["loc", [null, [28, 9], [28, 132]]]]],
        locals: ["object"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 0
            },
            "end": {
              "line": 46,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/traffic/pitstop/confirm/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("p");
          var el2 = dom.createTextNode("Mikäli haluat myydä ylläolevan(t) lasku(t) anna yrityksesi nimenkirjoitusoikeuden\nomaavan henkilön nimi ja sähköpostiosoite sekä hyväksy rahoitustarjous.");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
          return morphs;
        },
        statements: [["inline", "input", [], ["type", "text", "required", "true", "size", "40", "value", ["subexpr", "@mut", [["get", "model.signerName", ["loc", [null, [44, 55], [44, 71]]]]], [], []], "placeholder", ["subexpr", "t", ["traffic.pitstop.signerName"], [], ["loc", [null, [44, 84], [44, 116]]]]], ["loc", [null, [44, 3], [44, 118]]]], ["inline", "input", [], ["type", "email", "required", "true", "size", "40", "value", ["subexpr", "@mut", [["get", "model.signerEmail", ["loc", [null, [45, 56], [45, 73]]]]], [], []], "placeholder", ["subexpr", "t", ["traffic.pitstop.signerEmail"], [], ["loc", [null, [45, 86], [45, 119]]]]], ["loc", [null, [45, 3], [45, 121]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 53,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/traffic/pitstop/confirm/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "panel");
        var el2 = dom.createTextNode("\n   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "pitstop");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("form");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        var el5 = dom.createTextNode("Olet valinnut seuraavat laskut rahoitukseen:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("table");
        dom.setAttribute(el4, "class", "table table-striped table-bordered table-hover");
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("thead");
        var el6 = dom.createTextNode("\n	");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("th");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tbody");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("tfoot");
        var el6 = dom.createTextNode("\n	");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("tr");
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        dom.setAttribute(el7, "colspan", "6");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	    ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("td");
        var el8 = dom.createTextNode("Ostajalta tullaan pyytämään erikseen laskutuslupa sähköpostitse.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n	");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n    ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createElement("button");
        var el6 = dom.createTextNode("Hyväksy");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("button");
        var el6 = dom.createTextNode("Peruuta");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1, 1]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element1, [7, 2]);
        var morphs = new Array(11);
        morphs[0] = dom.createElementMorph(element1);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [5]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [7]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [9]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [11]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element3, [13]), 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[9] = dom.createMorphAt(element1, 5, 5);
        morphs[10] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["element", "action", ["setupFinancing"], ["on", "submit"], ["loc", [null, [3, 6], [3, 45]]]], ["inline", "t", ["traffic.tables.sender"], [], ["loc", [null, [10, 9], [10, 38]]]], ["inline", "t", ["traffic.pitstop.invoiceNumber"], [], ["loc", [null, [11, 9], [11, 46]]]], ["inline", "t", ["traffic.tables.sum"], [], ["loc", [null, [12, 9], [12, 35]]]], ["inline", "t", ["traffic.tables.dueDate"], [], ["loc", [null, [13, 9], [13, 39]]]], ["inline", "t", ["traffic.tables.provision"], [], ["loc", [null, [14, 9], [14, 41]]]], ["inline", "t", ["traffic.tables.sumWithoutProvision"], [], ["loc", [null, [15, 9], [15, 51]]]], ["inline", "t", ["traffic.pitstop.receiverEmailHeading"], [], ["loc", [null, [16, 9], [16, 53]]]], ["block", "each", [["get", "model.selected", ["loc", [null, [20, 9], [20, 23]]]]], [], 0, null, ["loc", [null, [20, 1], [30, 10]]]], ["block", "unless", [["get", "model.signomDocumentID", ["loc", [null, [40, 10], [40, 32]]]]], [], 1, null, ["loc", [null, [40, 0], [46, 11]]]], ["element", "action", ["toList"], [], ["loc", [null, [48, 36], [48, 55]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});