define("incus-vastaanota/pods/components/admin-user-selector/component", ["exports", "ember", "incus-vastaanota/utils/email"], function (exports, _ember, _incusVastaanotaUtilsEmail) {
  exports["default"] = _ember["default"].Component.extend({
    session: _ember["default"].inject.service(),
    users: [],
    user: null,
    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      switchUser: function switchUser(evt) {
        this.set("user", evt.target.value);
        var u = this.get("users").find(function (u) {
          return u.UniqueID === evt.target.value;
        });
        this.get("session").adminChangeUser(u);
      },
      ok: function ok() {
        this.sendAction('action');
      },
      toggleShow: function toggleShow() {
        this.toggleProperty("enabled");
      }
    }
  });
});