define('incus-vastaanota/app', ['exports', 'ember', 'ember/resolver', 'ember/load-initializers', 'incus-vastaanota/config/environment', 'ember-i18n/helper'], function (exports, _ember, _emberResolver, _emberLoadInitializers, _incusVastaanotaConfigEnvironment, _emberI18nHelper) {

  var App;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _incusVastaanotaConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _incusVastaanotaConfigEnvironment['default'].podModulePrefix,
    Resolver: _emberResolver['default'],
    rootElement: '#ember-app'
  });

  (0, _emberLoadInitializers['default'])(App, _incusVastaanotaConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});