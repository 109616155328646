define("incus-vastaanota/pods/storage/view/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/storage/view/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "button");
          dom.setAttribute(el2, "target", "_blank");
          dom.setAttribute(el2, "download", "");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("iframe");
          dom.setAttribute(el1, "style", "height:  calc(100vh - 170px);");
          dom.setAttribute(el1, "id", "viewer");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 0]);
          var element1 = dom.childAt(fragment, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'href');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          morphs[2] = dom.createAttrMorph(element1, 'src');
          return morphs;
        },
        statements: [["attribute", "href", ["concat", [["get", "model.attachmentSrc", ["loc", [null, [9, 33], [9, 52]]]]]]], ["inline", "t", ["common.download"], [], ["loc", [null, [9, 81], [9, 104]]]], ["attribute", "src", ["concat", [["get", "model.attachmentSrc", ["loc", [null, [10, 57], [10, 76]]]], "#view=fitH"]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "incus-vastaanota/pods/storage/view/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "white-wrapper");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["storageView.urlError"], [], ["loc", [null, [13, 7], [13, 35]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 41,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/storage/view/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("\n<div class=\"page-top\">\n  <h1>{{t \"storageView.viewTitle\"}}</h1>\n</div>\n<main class=\"flex-view\">\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  <div class=\"pdf-object\">\n{{#each model.images as |attachment|}}\n      <button {{action \"changeIframe\" attachment}} class=\"{{if (is-equal model.selectedAttachment attachment.FileName) \"selected-attachment\"}}\">{{#if (is-equal attachment.FileName \"invoice.pdf\")}}{{t \"storageView.imageName\"}}{{else}}{{attachment.FileName}}{{/if}}</button>\n    {{/each}}  </div>\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  \n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("\n  <div class=\"selectionmenu white-wrapper\">\n    <h2>{{t \"storageView.files\"}}</h2>\n    \n{{#each model.attachments as |attachment|}}\n      <a href=\"{{attachment.url}}\" target=\"_blank\">{{attachment.FileName}}</a><br/>\n    {{/each}}\n    <br/><br/><br/>\n    <a href={{model.dlURL}} target=\"_blank\">{{t \"storageView.downloadAll\"}}</a><br/>\n\n  </div>\n\n\n</main>\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "model.attachmentSrc", ["loc", [null, [8, 6], [8, 25]]]]], [], 0, 1, ["loc", [null, [8, 0], [15, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});