define('incus-vastaanota/pods/traffic/pitstop/confirm/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/models/traffic', 'incus-vastaanota/utils/list-utils', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsTraffic, _incusVastaanotaUtilsListUtils, _emberCliPaginationComputedPagedArray) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      var locale = _incusVastaanotaConfigEnvironment['default'].defaultLocale;
      if (window.localStorage) {
        locale = window.localStorage.getItem('locale');
      }

      var selected_uuids = param.selected;
      if (selected_uuids) {
        selected_uuids = selected_uuids.split(";");
      } else {
        selected_uuids = [];
      }

      return apixclient.pitstopTraffic(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, 1000).then(function (data) {

        var invoiceList = _incusVastaanotaUtilsListUtils['default'].processTrafficData(data, apixclient, userData, function () {
          return _incusVastaanotaModelsTraffic['default'].create();
        });

        var selected = [];

        var signomDocumentID = null;

        for (var i = invoiceList.length - 1; i >= 0; i--) {
          var item = invoiceList[i];

          console.log(["current item"], item);

          if (item.signomDocumentID) {
            signomDocumentID = item.signomDocumentID;
          }

          if (_ember['default'].$.inArray(item.uniqueMessageID, selected_uuids) >= 0 && item.uniqueMessageID) {
            selected.pushObject(item);

            console.log(["added item"], item);
          }
        }

        console.log(["selected_uuids2", selected_uuids]);
        if (selected_uuids.length != selected.length) {
          console.error(["All selected items were not found from pitstopTraffic", selected_uuids, selected]);
        }

        var model = _ember['default'].Object.extend({
          receiverEmail: null,
          signerEmail: null,
          signerName: null,

          hideAdvertisement: false,
          hasValidSelected: _ember['default'].computed("selected.[]", function () {
            var selected = this.get("selected");
            return selected.length !== 0;
          }),

          hasMultipleValidSelected: _ember['default'].computed("selected.[]", function () {
            var selected = this.get("selected");
            return selected.length > 1;
          }),

          selected: null
        }).create({ selected: selected });

        if (signomDocumentID) {
          model.set("signomDocumentID", signomDocumentID);
        }

        return model;
      });
    },

    beforeModel: function beforeModel(a, b, c) {
      console.log("!!!!!!!!!!!!!!!!!!!!beforeModel");
    },
    afterModel: function afterModel(a, b, c) {
      console.log("!!!!!!!!!!!!!!!!!!!!afterModel");
    },

    actions: {
      toList: function toList() {
        this.transitionTo("traffic.pitstop.list");
      },

      toConfirm: function toConfirm() {
        //validSelected
        this.transitionTo("traffic.pitstop.confirm");
      },

      requestOffer: function requestOffer() {
        var model = this.get("controller.model");

        _ember['default'].RSVP.Promise.resolve(null).then(function () {
          model.set("hideAdvertisement", true);
        });
      },

      setupFinancing: function setupFinancing() {
        var _this = this;

        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        var model = this.get("controller.model");

        var selected = model.get("selected");

        var holds = [];

        /* Let's fire up the financing process. */
        selected.forEach(function (el) {
          var buyerEmail = el.get("receiverEmail");
          console.log(["pitstopHold", el.uniqueMessageID, buyerEmail, userData.TransferID, userData.TransferKey]);
          var p = apixclient.pitstopHold(el.uniqueMessageID, buyerEmail, userData.TransferID, userData.TransferKey);
          holds.pushObject(p);
        });

        _ember['default'].RSVP.Promise.all(holds).then(function (data) {

          if (!model.get("signomDocumentID")) {
            var signerEmail = model.get("signerEmail");
            var signerName = model.get("signerName");

            console.log(["pitstopContract", userData.UniqueCompanyID, signerEmail, signerName, userData.TransferID, userData.TransferKey]);

            console.log([userData]);

            return apixclient.pitstopContract(userData.UniqueCompanyID, signerEmail, signerName, userData.TransferID, userData.TransferKey);
          }
        }).then(function () {
          _this.transitionTo("traffic.pitstop.end", selected[0].get("uniqueMessageID"));
        }).fail(function (error) {
          console.log(error);
          window.alert("A step in chain failed");
        });
      }

    }

  });
});