define('incus-vastaanota/components/checkbox-list', ['exports', 'ember', 'jquery'], function (exports, _ember, _jquery) {
  exports['default'] = _ember['default'].Component.extend({
    focusOnKeyDown: (function () {
      var newactiveIndex = this.get('activeIndex');
      if (newactiveIndex >= 0) {
        this.toggleActiveClass(newactiveIndex);
      } else {
        (0, _jquery['default'])('li').removeClass();
      }
    }).observes('activeIndex').on('init'),

    toggleActiveClass: function toggleActiveClass(activeIndex) {
      var element = 'li[tabindex=' + activeIndex + ']';
      var indexEle = (0, _jquery['default'])(element);
      indexEle.addClass('selected-email').siblings().removeClass();
      indexEle.closest('ul.autolist').scrollTop(indexEle.index() * indexEle.outerHeight());
    },
    actions: {
      removeEmailAddress: function removeEmailAddress(result) {
        _ember['default'].Logger.log('remove email address action', result);
        this.sendAction('remove', result);
      },
      selectEmailAddress: function selectEmailAddress(result) {
        _ember['default'].Logger.log('select email address action', result);
        this.sendAction('select', result);
      }
    }

  });
});