define('incus-vastaanota/pods/invoice/create/buy-notin/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/email', 'incus-vastaanota/utils/finvoice', 'incus-vastaanota/models/invoice', 'incus-vastaanota/utils/create-utils', 'incus-vastaanota/utils/demo'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsEmail, _incusVastaanotaUtilsFinvoice, _incusVastaanotaModelsInvoice, _incusVastaanotaUtilsCreateUtils, _incusVastaanotaUtilsDemo) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model(param) {
      var invoice = _incusVastaanotaModelsInvoice['default'].create();
      this._preFillDefaults(invoice);
      var epsilon = 0.00001;
      var notify = this.get('notify');
      var i18n = this.get('i18n');
      var locale = _incusVastaanotaConfigEnvironment['default'].defaultLocale;
      if (window.localStorage) {
        locale = window.localStorage.getItem('locale');
      }

      var model = _ember['default'].Object.extend({
        displayButtons: false,
        noUpload: false,
        fileData: null,
        locale: locale,

        invoice: null,
        missingFields: null,

        storageID: null,
        storageKey: null,

        barcode: null,
        dueDate: null,
        documentDate: null,

        barcodeChanged: (function () {
          var invoice = this.get("invoice");
          var result = invoice.readBarcode(this.get("barcode"));
          if (result) {
            notify.error(i18n.t("createView.barcodeInvalid").toString());
          }
          var dueDate = invoice.get("dueDate");
        }).observes('barcode'),
        dueDateChanged: (function () {
          var invoice = this.get("invoice");
          var dateString = this.get("dueDate");
          var date = moment(dateString).format('YYYYMMDD');
          if (date) {
            invoice.set('dueDate', date);
          } else {
            invoice.set('dueDate');
          }
        }).observes('dueDate'),
        documentDateChanged: (function () {
          var invoice = this.get("invoice");
          var dateString = this.get("documentDate");
          var date = moment(dateString).format('YYYYMMDDHHmmss');
          if (date) {
            invoice.set('documentDate', date);
          } else {
            invoice.set('documentDate');
          }
        }).observes('documentDate'),

        taxFree24Field: null,
        taxFree24: _ember['default'].computed('taxFree24Field', function () {
          var field = this.get('taxFree24Field');
          var myArray = _incusVastaanotaUtilsCreateUtils['default'].parseNumber(field);
          if (myArray[0] === 1) {
            return myArray[1];
          } else {
            notify.info("24 % " + i18n.t("createView.taxBase").toString() + " " + i18n.t("createView.invalidField").toString());
            return field;
          }
        }),

        // TODO Precision
        taxAmount24: (function () {
          var amount = this.get('taxFree24');
          if (amount) {
            return (amount * 0.24 + epsilon).toFixed(2);
          }
        }).property('taxFree24'),
        total24: (function () {
          var amount = this.get('taxFree24');
          if (amount) {
            return (amount * 1.24 + epsilon).toFixed(2);
          }
        }).property('taxFree24'),

        taxFree14Field: null,
        taxFree14: _ember['default'].computed('taxFree14Field', function () {
          var field = this.get('taxFree14Field');
          var myArray = _incusVastaanotaUtilsCreateUtils['default'].parseNumber(field);
          _ember['default'].Logger.log(myArray);
          if (myArray[0] === 1) {
            return myArray[1];
          } else {
            notify.info("14 % " + i18n.t("createView.taxBase").toString() + " " + i18n.t("createView.invalidField").toString());
            return field;
          }
        }),
        taxAmount14: (function () {
          var amount = this.get('taxFree14');
          if (amount) {
            return (amount * 0.14 + epsilon).toFixed(2);
          }
        }).property('taxFree14'),
        total14: (function () {
          var amount = this.get('taxFree14');
          if (amount) {
            return (amount * 1.14 + epsilon).toFixed(2);
          }
        }).property('taxFree14'),

        taxFree10Field: null,
        taxFree10: _ember['default'].computed('taxFree10Field', function () {
          var field = this.get('taxFree10Field');
          var myArray = _incusVastaanotaUtilsCreateUtils['default'].parseNumber(field);
          if (myArray[0] === 1) {
            return myArray[1];
          } else {
            notify.info("10 % " + i18n.t("createView.taxBase").toString() + " " + i18n.t("createView.invalidField").toString());
            return field;
          }
        }),
        taxAmount10: (function () {
          var amount = this.get('taxFree10');
          if (amount) {
            return (amount * 0.10 + epsilon).toFixed(2);
          }
        }).property('taxFree10'),
        total10: (function () {
          var amount = this.get('taxFree10');
          if (amount) {
            return (amount * 1.10 + epsilon).toFixed(2);
          }
        }).property('taxFree10'),

        taxFree0Field: null,
        taxFree0: _ember['default'].computed('taxFree0Field', function () {
          var field = this.get('taxFree0Field');
          var myArray = _incusVastaanotaUtilsCreateUtils['default'].parseNumber(field);
          if (myArray[0] === 1) {
            return myArray[1];
          } else {
            notify.info("0 % " + i18n.t("createView.taxBase").toString() + " " + i18n.t("createView.invalidField").toString());
            return field;
          }
        }),
        taxAmount0: (function () {
          var amount = this.get('taxFree0');
          if (amount) {
            return (amount * 0.0 + epsilon).toFixed(2);
          }
        }).property('taxFree0'),
        total0: (function () {
          var amount = this.get('taxFree0');
          if (amount) {
            return (amount * 1.0 + epsilon).toFixed(2);
          }
        }).property('taxFree0'),

        totalTotal: (function () {
          var sum = 0;
          var t24 = this.get('total24');
          if (t24) {
            sum = sum + parseFloat(t24);
          }
          var t14 = this.get('total14');
          if (t14) {
            sum = sum + parseFloat(t14);
          }
          var t10 = this.get('total10');
          if (t10) {
            sum = sum + parseFloat(t10);
          }
          var t0 = this.get('total0');
          if (t0) {
            sum = sum + parseFloat(t0);
          }
          sum = (sum + epsilon).toFixed(2);
          if (sum !== 0) {
            return sum;
          }
        }).property('total24', 'total14', 'total10', 'total0'),
        totalTotalChanged: (function () {
          var invoice = this.get("invoice");
          var sum = this.get("totalTotal");
          if (sum) {
            invoice.set('amountToPay', sum);
          } else {
            invoice.set('amountToPay');
          }
        }).observes('totalTotal'),
        totalTaxFree: (function () {
          var sum = 0;
          var t24 = this.get('taxFree24');
          if (t24) {
            sum = sum + parseFloat(t24);
          }
          var t14 = this.get('taxFree14');
          if (t14) {
            sum = sum + parseFloat(t14);
          }
          var t10 = this.get('taxFree10');
          if (t10) {
            sum = sum + parseFloat(t10);
          }
          var t0 = this.get('taxFree0');
          if (t0) {
            sum = sum + parseFloat(t0);
          }
          sum = (sum + epsilon).toFixed(2);
          if (sum !== 0) {
            return sum;
          }
        }).property('taxFree24', 'taxFree14', 'taxFree10', 'taxFree0'),
        totalTaxAmount: (function () {
          var sum = 0;
          var t24 = this.get('taxAmount24');
          if (t24) {
            sum = sum + parseFloat(t24);
          }
          var t14 = this.get('taxAmount14');
          if (t14) {
            sum = sum + parseFloat(t14);
          }
          var t10 = this.get('taxAmount10');
          if (t10) {
            sum = sum + parseFloat(t10);
          }
          var t0 = this.get('taxAmount0');
          if (t0) {
            sum = sum + parseFloat(t0);
          }
          sum = (sum + epsilon).toFixed(2);
          if (sum !== 0) {
            return sum;
          }
        }).property('taxAmount24', 'taxAmount14', 'taxAmount10', 'taxAmount0'),
        totalTaxAmountChanged: (function () {
          var invoice = this.get("invoice");
          var sum = this.get("totalTaxAmount");
          if (sum) {
            invoice.set('taxAmount', sum);
          } else {
            invoice.set('taxAmount');
          }
        }).observes('totalTaxAmount'),

        bankAccountChanged: (function () {
          var invoice = this.get("invoice");
          var account = this.get("bankAccount");
          this.set("bicCode", invoice.getBicCode());
        }).observes('invoice.bankAccount')
      }).create({
        invoice: invoice
      });
      model.set('documentDate', new Date());
      _ember['default'].Logger.log(invoice.get('documentDate'));
      return model;
    },

    actions: {
      fileChanged: function fileChanged(fileName, fileType, fileSize) {
        _ember['default'].Logger.log(fileName, "and", fileType, "size", fileSize);
        if (fileSize) {
          this.set('controller.model.headerText', null);
          this.set('controller.model.displayButtons', true);
          this.set('controller.model.file', {
            name: fileName,
            type: fileType,
            size: fileSize
          });
          var userData = this.get("session").get("user")[0];
          var owner = userData.OwnerId;
          if (owner && owner == "10718") {
            this.set('controller.model.noUpload', true);
          }
          //createBuyInvoice();
        } else {
            this.get('notify').error("Error with file upload, please try again.");
          }
      },

      sendToScanning: function sendToScanning() {
        var _this = this;

        _ember['default'].$("#overlay").addClass('active-overlay');
        var apixclient = this.get('apixclient');
        var userData = this.get("session").get("user")[0];
        var file = this.get('controller.model.file');
        var fileData = this.get('controller.model.fileData');
        var timeStamp = moment().format('YYYYMMDDHHmmss');
        var xml = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>' + '<Request><Content><Group>' + '<Value type="FileName">invoice.pdf</Value>' + '<Value type="FileSize">' + _incusVastaanotaUtilsEmail['default'].escapeXML(file.size) + '</Value>' + '<Value type="TransferID">' + _incusVastaanotaUtilsEmail['default'].escapeXML(userData.TransferID) + '</Value>' + '<Value type="UniqueCompanyID">' + _incusVastaanotaUtilsEmail['default'].escapeXML(userData.UniqueCompanyID) + '</Value>' + '<Value type="TimeStamp">' + _incusVastaanotaUtilsEmail['default'].escapeXML(timeStamp) + '</Value>' + '</Group></Content></Request>';

        this.set('controller.model.displayButtons', false); //  create zip file
        var zip = new JSZip();
        zip.file('meta.xml', xml);
        zip.file('invoice.pdf', fileData);
        _ember['default'].Logger.log(xml);
        var zipFile = zip.generate({ type: 'arraybuffer', compression: "DEFLATE" });
        return apixclient.sendInvoiceZip(userData.TransferID, userData.TransferKey, zipFile, 'voscan', '1.0').then(function (data) {
          _ember['default'].Logger.log(data);
          var notify = _this.get('notify');
          _ember['default'].$("#overlay").removeClass('active-overlay');
          if (data.callStatus.success === 'OK') {
            notify.info(_this.get('i18n').t("createView.sendSuccess").toString());
            _this.set('controller.model.storageID', data.data[0].StorageID);
            _this.set('controller.model.storageKey', data.data[1].StorageKey);
            _this.refresh();
          } else {
            notify.error(_this.get('i18n').t("common.error").toString(), { closeAfter: 10000 });
          }
          if (data.callStatus.freeText) {
            notify.info(data.callStatus.freeText, { closeAfter: null });
          }
        });
      },

      createBuyInvoice: function createBuyInvoice() {
        this.set('controller.model.noUpload', true);
      },

      createInvoice: function createInvoice() {
        var _this2 = this;

        if (false /* !this._validateData() */) {
            // TODO Handle valiation errors sensibly
            var errmsg = this.get('i18n').t('common.validationFailed');
            window.alert(errmsg.toString());
          } else {
          _ember['default'].$("#overlay").addClass('active-overlay');
          var promise = _ember['default'].RSVP.Promise.resolve(null);
          promise = promise.then(function () {
            return _this2._createStorage();
          });
          promise = promise.then(function () {
            return _this2._setMetadata();
          });
          promise = promise.then(function () {
            return _this2._upload();
          });
          promise = promise.then(function () {
            var is_demo = _incusVastaanotaUtilsDemo['default'].check_env();
            if (is_demo) {
              console.log("DEMO");
              _this2.set('controller.model.storageID', "dabcef90-a25d-420a-a3bb-f03e89555280");
              _this2.set('controller.model.storageKey', "731964085545");
            }

            var storageId = _this2.get('controller.model.storageID');
            var storageKey = _this2.get('controller.model.storageKey');
            _ember['default'].Logger.log('start transition');
            _ember['default'].Logger.log(storageKey);
            _ember['default'].$('#overlay').removeClass('active-overlay');
            _this2.transitionTo('invoice.view', storageId, { queryParams: { storageKey: storageKey } });
          })['catch'](function (data) {
            var notify = _this2.get('notify');
            notify.error("Error creating invoice");
            _ember['default'].Logger.log(data);
            _ember['default'].$('#overlay').removeClass('active-overlay');
          });
        }
      }
    },

    // TODO Set the expiry? Default 30 days according to pdf.
    _createStorage: function _createStorage() {
      var _this3 = this;

      var apixclient = this.get('apixclient');
      var userData = this.get("session").get("user")[0];
      _ember['default'].Logger.log(userData);
      var name = this._generateUUID();
      return apixclient.createReceiving(userData.TransferID, userData.TransferKey, name).then(function (data) {
        _ember['default'].Logger.log(data);
        _this3.set('controller.model.storageID', data.data[0].StorageID);
        _this3.set('controller.model.storageKey', data.data[1].StorageKey);
      });
    },

    _setMetadata: function _setMetadata() {
      var invoice = this.get("controller.model.invoice");
      invoice.set('createdDate', moment().format('YYYYMMDDHHmmss'));
      _ember['default'].Logger.log(invoice);
      var storageId = this.get('controller.model.storageID');
      if (!storageId) {
        _ember['default'].Logger.log("No storageId while trying to set metadata!");
        return;
      }
      //	let metadata = "<Request version=\"1.0\">\r<Content>" + invoice.toXML() +
      //	let metadata = "<Request version=\"1.0\">\r\n<Content>" +
      //"<Group><Value type=\"AmountToPay\">512.00</Value><Value type=\"ApprovalStatus\">NEUTRAL</Value></Group>" +
      //	    "</Content></Request>\r\n";
      var metadata = invoice.toXML();
      var apixclient = this.get('apixclient');
      var userData = this.get("session").get("user")[0];
      _ember['default'].Logger.log("Setting metadata: " + metadata);
      return apixclient.setMetadata(userData.TransferID, storageId, userData.TransferKey, metadata, 'text/xml').then(function (data) {
        _ember['default'].Logger.log("set metadata");
        _ember['default'].Logger.log(data);
      });
    },

    _preFillDefaults: function _preFillDefaults(invoice) {
      var userData = this.get("session").get("user")[0];
      invoice.set('documentDate', moment().format('YYYYMMDD'));
      invoice.set('paymentStatus', 'NOTPAID');
      invoice.set('transferStatus', 'NEW');
      invoice.set('receiverVAT', userData.Ytunnus);
      invoice.set('imageName', 'invoice.pdf');
      invoice.set('documentName', 'invoice.xml');
      invoice.set('receiverName', userData.Name);
      invoice.set('approvalStatus', 'NEUTRAL');
      invoice.set("currency", "EUR");
      invoice.set("invoiceChannel", invoice.INVOICECHANNEL.MANUAL);
    },

    _upload: function _upload() {
      var apixclient = this.get('apixclient');
      var userData = this.get("session").get("user")[0];

      var storageId = this.get('controller.model.storageID');
      var pdfData = this.get("controller.model.fileData");
      var pdfType = 'application/pdf';
      var pdfName = 'invoice.pdf';
      var pdfRole = 'image';
      var xmlData = _incusVastaanotaUtilsFinvoice['default'].generateFinvoiceXML(this.get("controller.model"));
      console.log(xmlData);
      var xmlType = 'text/xml';
      var xmlName = 'invoice.xml';
      var xmlRole = 'invoice';

      var promise = _ember['default'].RSVP.Promise.resolve(null);
      promise = promise.then(function () {
        return apixclient.upload(userData.TransferID, storageId, pdfType, pdfName, userData.TransferKey, pdfData, 'application/octet-stream', pdfRole).then(function (data) {
          _ember['default'].Logger.log("uploaded");
          _ember['default'].Logger.log(data);
        });
      });
      promise = promise.then(function () {
        return apixclient.upload(userData.TransferID, storageId, xmlType, xmlName, userData.TransferKey, xmlData, 'application/octet-stream', xmlRole).then(function (data) {
          _ember['default'].Logger.log("uploaded");
          _ember['default'].Logger.log(data);
        });
      });
      return promise;
    },

    _generateUUID: function _generateUUID() {
      var d = new Date().getTime();
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    },
    _validateData: function _validateData() {
      var _this4 = this;

      var fileData = this.get("controller.model.fileData");
      var invoice = this.get("controller.model.invoice");
      var result = true;
      var missingFields = [];
      var requiredFields = ['documentID', 'documentDate', 'dueDate', 'senderName', 'senderVAT', 'bankAccount', 'paymentReference', 'currency'];
      requiredFields.forEach(function (entry) {
        if (!invoice.get(entry)) {
          missingFields.push(_this4.get('i18n').t("createView.notFilled") + _this4.get('i18n').t("invoice." + entry));
          // TODO Form field highlighting, how?
          _ember['default'].Logger.log("no value for field " + entry);
          result = false;
        }
      });

      var ba = invoice.get('bankAccount');
      if (!_incusVastaanotaUtilsCreateUtils['default'].validateIBAN(ba)) {
        missingFields.push(this.get('i18n').t("createView.invalidIBAN"));
        result = false;
      }
      var dd = invoice.get('dueDate');
      if (!_incusVastaanotaUtilsCreateUtils['default'].validateDate(dd)) {
        missingFields.push(this.get('i18n').t("invoice.dueDate") + " " + this.get('i18n').t("createView.invalidField"));
        result = false;
      }
      dd = invoice.get('documentDate');
      _ember['default'].Logger.log("docu", dd);
      if (!_incusVastaanotaUtilsCreateUtils['default'].validateTimestamp(dd)) {
        missingFields.push(this.get('i18n').t("invoice.documentDate") + " " + this.get('i18n').t("createView.invalidField"));
        result = false;
      }

      var dn = invoice.get('documentID');
      if (!_incusVastaanotaUtilsCreateUtils['default'].validateInteger(dn)) {
        missingFields.push(this.get('i18n').t("invoice.documentID") + " " + this.get('i18n').t("createView.invalidField"));
        result = false;
      }

      var sum = invoice.get("amountToPay");
      if (!sum || sum === 0) {
        missingFields.push(this.get('i18n').t("createView.totalZero"));
        result = false;
      }
      if (!fileData) {
        // XXX Render error
        missingFields.push(this.get('i18n').t("createView.notFilled") + this.get('i18n').t('createView.imageField'));
        _ember['default'].Logger.log("No pdf uploaded, rejecting");
        result = false;
      }

      this.set("controller.model.missingFields", missingFields);
      return result;
    }
  });
});