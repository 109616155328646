define('incus-vastaanota/utils/email', ['exports', 'ember', 'incus-vastaanota/models/invoice', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaModelsInvoice, _incusVastaanotaConfigEnvironment) {
  exports['default'] = {

    htmlHeadString: '<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd"> <html> <head> <style type="text/css">body {    color: #3A3A3A;    background-color: #f7fdff;    font-family: Arial, Helvetica, sans-serif;}div.tab {    border: solid #ddd 1px;    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=180, Color="#222222")";    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=5, Direction=180, Color="#222222");    -moz-box-shadow:    -2px 5px 11px rgba(0, 0, 0, 0.5);    -webkit-box-shadow: -2px 5px 11px rgba(0, 0, 0, 0.5);    box-shadow:     	0 5px 11px rgba(0, 0, 0, 0.2);    margin:30px;    background-color: white;}div.tab div:first-child {    color: white;    background-color: black;    margin: 0;    text-align: right;	height: 32px;}div.tab div:first-child span { position:relative; top: 0; right: 40px; font-size: 16pt; }div.tab h1 { text-align: center; color: #01b6ed; }div.tab h2 { font-size: inherit; font-weight:bold; margin: 30px 5% 10px; }div.tab p { margin: 10px 5%; }table {margin: 10px 5% 10px 5px;}div.scrollable{width:100%; overflow-x: auto }div.footer{padding-top:10px; padding-bottom:25px; background-color:#2C2F37; color: #A4A6A8; }div.footer a{color:#218B9E;}th {background-color: #adf; text-align:left; }td {border: 0px solid black; }tr.odd td { background-color: #fff; }tr.even td { background-color: #e8e8e8; }td.small { font-size: small;}</style></head> <body> <div class="tab"><div><svg style="float:left" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" width="64" height="32" version="1.1">  <path d="M 0,0 6,0 21,16 6,32 0,32 15,16 z" style="fill:#a7a5a6" />  <path d="m 27,0 6,0 -13,14 13,0 0,4 -13,0 13,14 -6,0 -15,-16 z" style="fill:#367b8b;fill-opacity:1" /></svg>',

    generateXML: function generateXML(emailRecipient, emailType, did, invoice, i18n, userData, url, subject) {
      var storageId = invoice.get('storageID');
      var storageKey = invoice.get('storageKey');
      // TODO Verify templates
      var checkStatus = undefined;
      if (!invoice.get("commentArray") || invoice.get("commentArray").length === 0) {
        checkStatus = i18n.t("email.noComments");
      } else {
        checkStatus = invoice.get('approvalStatus');
      }
      var data = {
        documentID: did,
        senderName: invoice.get('senderName'),
        dueDate: invoice.get('dueDate'),
        amountToPay: invoice.get('amountToPay'),
        url: url,
        name: userData.Name,
        checkStatus: checkStatus
      };
      // Ember template naming has some magic
      var textTemplate = undefined;
      var htmlTemplate = undefined;
      var mimeParts = [];
      var lang = _incusVastaanotaConfigEnvironment['default'].build === "fakturamappen" ? "sv" : "fi";
      var checkerHtmlFi = '<p>Tämä on järjestelmän tuottama sähköposti.</p>' + '<p>Ole hyvä ja tarkista seuraava lasku:</p>' + '<div class="scrollable">' + '<p><%this.documentID%></p></div><p><a href="<%this.url%>">Tarkasta lasku.</a></p>' + '<p>Saamasi linkki on henkilökohtainen.</p><div class="footer"><p>Tukea osoitteesta <a href="mailto:servicedesk@apix.fi">servicedesk@apix.fi</a></p>' + '<p>--<br/>Lisätietoa palvelusta <a href="http://www.apix.fi/">www.apix.fi</a></p></div>';

      var checkerHtmlSv = '<p>Detta är ett e-postmeddelande som genereras av <a href="http://www.fakturamappen.se/">Fakturamappen.se</a>.</p>' + '<p>Vänligen kontrollera följande faktura:</p>' + '<div class="scrollable">' + '<p><%this.documentID%></p></div><p><a href="<%this.url%>">Kontrollera faktura.</a></p>' + '<p>Det här är din personliga länk.</p><div class="footer"><p>Fråga om hjälp från <a href="mailto:servicedesk@apixmessaging.se">servicedesk@apixmessaging.se</a></p>' + '<p>--<br/>Fler tjänster från <a href="http://www.apixmessaging.se/">www.apixmessaging.se</a></p></div>';

      var checkerTextFi = "Tämä on järjestelmän tuottama sähköposti.\n\nOle hyvä ja tarkista seuraava lasku:\n\n" + "Lasku         " + data.documentID + "\n" + "Laskuttaja    " + data.senderName + "\n" + "Eräpäivä      " + data.dueDate + "\n" + "Summa         " + data.amountToPay + "\n" + "Tarkastus     " + data.checkStatus + "\n\n" + url + "\n" + "Saamasi linkki on henkilökohtainen.\n\nTukea osoitteesta servicedesk@apix.fi\n\n--\nLisätietoa palvelusta www.apix.fi";

      var checkerTextSv = "Detta är ett e-postmeddelande som genereras av www.Fakturamappen.se.\n\nVänligen kontrollera följande faktura:\n\n" + "Faktura       " + data.documentID + "\n" + "Utställare    " + data.senderName + "\n" + "Betaldatum    " + data.dueDate + "\n" + "Summa         " + data.amountToPay + "\n" + "Övrigt        " + data.checkStatus + "\n\n" + url + "\n" + "Det här är din personliga länk.\n\nFråga om hjälp från servicedesk@apixmessaging.se\n\n--\nFler tjänster från www.apixmessaging.se";

      var accountingHtmlFi = "<p>Tämä on järjestelmän tuottama sähköposti.</p><p>Yritys <%this.name%> on lähettänyt liitteenä olevat ostolaskut:</p>" + '<div class="scrollable"><p><%this.documentID%></p></div><p>toimenpiteitä varten.</p><div class="footer">' + '<p>Tukea osoitteesta <a href="mailto:servicedesk@apix.fi">servicedesk@apix.fi</a></p><p>--<br/>' + 'Lisätietoa palvelusta <a href="http://www.apix.fi/">www.apix.fi</a></p></div>';

      var accountingHtmlSv = "<p>Det här är ett e-postmeddelande som genereras av Fakturamappen.se.</p><p>Företaget <%this.name%> har skickat bifogade fakturor:</p>" + '<div class="scrollable"><p><%this.documentID%></p></div><p>för åtgärder.</p><div class="footer">' + '<p>Support från <a href="mailto:servicedesk@apixmessaging.se">servicedesk@apixmessaging.se</a></p><p>--<br/>' + 'Mer information om tjänsten <a href="http://www.apixmessaging.se/">www.apixmessaging.se</a></p></div>';

      var accountingTextSv = "Det här är ett e-postmeddelande som genereras av Fakturamappen.se.\n\nFöretaget " + data.name + " har skickat bifogade fakturor:\n\n" + data.documentID + "\n\nför åtgärder.\n\nSupport från servicedesk@apixmessaging.se\n\n--\nMer information om tjänsten www.apixmessaging.se";
      var accountingTextFi = "Tämä on järjestelmän tuottama sähköposti.\n\nYritys " + data.name + " on lähettänyt liitteenä olevat ostolaskut:\n\n" + data.documentID + "\n\ntoimenpiteitä varten.\n\nTukea osoitteesta servicedesk@apix.fi\n\n--\nLisätietoa palvelusta www.apix.fi";

      var text = undefined;
      var html = undefined;

      if (emailType === 'accounting') {
        text = lang === "fi" ? accountingTextFi : accountingTextSv;
        html = lang === "fi" ? accountingHtmlFi : accountingHtmlSv;
      } else if (emailType === 'checker') {
        text = lang === "fi" ? checkerTextFi : checkerTextSv;
        html = lang === "fi" ? checkerHtmlFi : checkerHtmlSv;
      } else {
        _ember['default'].Logger.log(emailType);
      }

      // text
      var plainText = this.escapeXML(text);
      mimeParts.push({
        mimeType: 'text/plain; charset=UTF-8',
        content: plainText
      });

      // html
      var htmlHeadString = this.htmlHeadString;
      htmlHeadString += '<span>|' + subject + '|</span></div>';
      var htmlText = this.escapeXML(htmlHeadString + this.templateEngine(html, data) + '</div> </body> </html>');
      _ember['default'].Logger.log(data);

      mimeParts.push({
        mimeType: 'text/html; charset=UTF-8',
        content: htmlText
      });

      var xml = '<?xml version="1.0" encoding="utf-8" standalone="yes"?><Request><Content>';
      mimeParts.forEach(function (part) {
        xml += '<Group>' + '<Value type="MimeType">' + part.mimeType + "</Value>" + '<Value type="Content">' + part.content + "</Value>\n" + "</Group>\n";
      });
      xml += '</Content></Request>';

      return xml;
    },

    escapeXML: function escapeXML(unsafe) {
      if (typeof unsafe === "string") {
        return unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
      } else {
        return unsafe;
      }
    },
    validateEmail: function validateEmail(email) {
      var re = /^\S+@\S+\.\S+$/i;
      return re.test(email);
    },

    templateEngine: function templateEngine(template, options) {
      /*jshint expr:true*/
      /*jshint boss:true*/
      /*jshint evil:true*/
      /*jshint eqeqeq:true*/
      /*jshint eqnull:true*/

      var re = /<%([^%>]+)?%>/g,
          reExp = /(^( )?(if|for|else|switch|case|break|{|}))(.*)?/g,
          code = 'var r=[];\n',
          cursor = 0,
          match = undefined;
      var add = function add(line, js) {
        js ? code += line.match(reExp) ? line + '\n' : 'r.push(' + line + ');\n' : code += line !== '' ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : '';
        return add;
      };
      while (match = re.exec(template)) {
        add(template.slice(cursor, match.index))(match[1], true);
        cursor = match.index + match[0].length;
      }
      add(template.substr(cursor, template.length - cursor));
      code += 'return r.join("");';
      _ember['default'].Logger.log(code);
      return new Function(code.replace(/[\r\n\t]/g, '')).apply(options);
    }

  };
});