define("incus-vastaanota/pods/invoice/create/buy/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "incus-vastaanota/pods/invoice/create/buy/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-top");
        var el2 = dom.createTextNode("  \n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("iframe{width: 1px;min-width: 100%;}");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("iframe");
        dom.setAttribute(el1, "id", "laskunkirjoituskone");
        dom.setAttribute(el1, "frameborder", "0");
        dom.setAttribute(el1, "scrolling", "no");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [5]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
        morphs[1] = dom.createAttrMorph(element0, 'src');
        morphs[2] = dom.createAttrMorph(element0, 'onload');
        return morphs;
      },
      statements: [["content", "pageTitle", ["loc", [null, [4, 4], [4, 17]]]], ["attribute", "src", ["get", "model.invoiceWriterURL", ["loc", [null, [8, 39], [8, 61]]]]], ["attribute", "onload", ["subexpr", "action", ["initIframe"], [], ["loc", [null, [8, 102], [8, 125]]]]]],
      locals: [],
      templates: []
    };
  })());
});