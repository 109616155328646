define('incus-vastaanota/components/text-editor', ['exports', 'ember', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaConfigEnvironment) {
  var docRoot = _incusVastaanotaConfigEnvironment['default'].clientHostRoot;

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['text-editor'],

    _options: {
      height: 500,
      skin_url: docRoot + '/tinymce/skins/lightgray',
      //skin_url: docRoot+'/assets/tinymce/skins/lightgray',
      theme_url: docRoot + '/tinymce/themes/modern/theme.min.js',
      fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",

      external_plugins: {
        //bdesk_photo:'/tinymce/plugins/bdesk_photo/plugin.min.js',
        advlist: docRoot + '/tinymce/plugins/advlist/plugin.min.js',
        autolink: docRoot + '/tinymce/plugins/autolink/plugin.min.js',
        lists: docRoot + '/tinymce/plugins/lists/plugin.min.js',
        link: docRoot + '/tinymce/plugins/link/plugin.min.js',
        image: docRoot + '/tinymce/plugins/image/plugin.min.js',
        charmap: docRoot + '/tinymce/plugins/charmap/plugin.min.js',
        print: docRoot + '/tinymce/plugins/print/plugin.min.js',
        preview: docRoot + '/tinymce/plugins/preview/plugin.min.js',
        anchor: docRoot + '/tinymce/plugins/anchor/plugin.min.js',
        searchreplace: docRoot + '/tinymce/plugins/searchreplace/plugin.min.js',
        visualblocks: docRoot + '/tinymce/plugins/visualblocks/plugin.min.js',
        fullscreen: docRoot + '/tinymce/plugins/fullscreen/plugin.min.js',
        insertdatetime: docRoot + '/tinymce/plugins/insertdatetime/plugin.min.js',
        media: docRoot + '/tinymce/plugins/media/plugin.min.js',
        table: docRoot + '/tinymce/plugins/table/plugin.min.js',
        contextmenu: docRoot + '/tinymce/plugins/contextmenu/plugin.min.js',
        paste: docRoot + '/tinymce/plugins/paste/plugin.min.js',
        code: docRoot + '/tinymce/plugins/code/plugin.min.js',
        bdesk_photo: docRoot + '/assets/tinymce/bdesk/plugin.min.js'

      }, /*
         plugins: [
           'bdesk_photo advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table contextmenu paste code',
         ],
         */
      toolbar1: 'insertfile undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | imageupload bdesk_photo | firstname lastname name3 street1 street2 postcode postoffice countrycode custom1 custom2 custom3 custom4',
      paste_data_images: true,
      images_upload_handler: function images_upload_handler(blobInfo, success, failure) {
        // no upload, just return the blobInfo.blob() as base64 data
        success("data:" + blobInfo.blob().type + ";base64," + blobInfo.base64());
      }
    },

    didInsertElement: (function () {
      var component = this;
      var options = this.get('_options');

      _ember['default'].merge(options, {
        setup: function setup(editor) {
          // bind change event
          component.set('editor', editor);
          editor.on('change', function () {
            component.set('value', editor.getContent());
          });

          editor.addCommand("trueInsertRawHTML", function (value) {
            this.selection.setContent('tiny_mce_marker');editor.setContent(editor.getContent().replace(/tiny_mce_marker/g, function () {
              return value;
            }), { format: "raw" });
          });
        },
        menu: {
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
          //insert: {title: 'Insert', items: 'link media | template hr bdesk_photo'},
          view: { title: 'View', items: 'visualaid' },
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' },
          table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
          tools: { title: 'Tools', items: 'spellchecker code' }
        }
      });

      this.$('textarea').tinymce(options);
    }).on('didInsertElement'),

    valueChanged: function valueChanged() {
      var controller = this;
      tinymce.editors.filter(function (editor) {
        return editor.id === controller.get('editor').id;
      }).forEach(function (editor) {
        editor.setContent(controller.get('value'));
      });
    } //.observes('value')
  });
});
// app/components/text-editor.js