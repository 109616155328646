define('incus-vastaanota/pods/forgotpw/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    saldoupdater: _ember['default'].inject.service(),
    queryParams: ['id'],
    route: null,

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        if (this.get("model.passwordsValid") !== "is-valid") {
          this.get("notify").error(this.get("i18n").t("passwordreset.invalidPasswords").toString());
        } else {
          this.get("apixclient").changePasswordRenewal(this.get("model.newPass"), this.get("model.id")).then(function (res) {
            _this.set("model.id", null);
            _this.set("model.requesting", true);
            _this.transitionToRoute("login");
            _this.get("notify").info(_this.get("i18n").t("settings.userInfo.updatedPassword").toString());
          })['catch'](function (error) {
            console.log(error);
            _this.get("notify").error(_this.get("i18n").t("settings.userInfo.updatePasswordFailed").toString());
          });
        }
      },
      requestPWRenewal: function requestPWRenewal() {
        var _this2 = this;

        var username = this.get("model.username");
        var language = this.get("i18n.locale");
        var domain = window.location.origin;
        this.get("apixclient").requestPasswordRenewal(username, domain, language).then(function (res) {

          _this2.transitionToRoute("login");
          _this2.get("notify").info(_this2.get("i18n").t("passwordreset.sent").toString());
        })['catch'](function (error) {
          _this2.set("validRequest", false);
        });
      },
      resetState: function resetState() {

        this.set("model.id", null);
        this.set("model.requesting", true);
      }
    }
  });
});