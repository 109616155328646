define("incus-vastaanota/services/spinner", ["exports", "ember"], function (exports, _ember) {

  /* Service which handles the spinner while waiting and provides
   * facility to set up notifications with. */
  exports["default"] = _ember["default"].Service.extend({
    i18n: _ember["default"].inject.service(),

    /* Spinner visibility counter. */
    visible: 0,

    /* Notification messages are observed from this array by css-spinner */
    messages: [],

    show: function show() {
      this.incrementProperty("visible");
    },

    hide: function hide() {
      this.decrementProperty("visible");
    }
  });
});