define('incus-vastaanota/helpers/to-fixed', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.helper(function (params) {
    if (params.length !== 2) {
      throw "requires 2 parameters";
    }
    var num = params[0];
    if (typeof num === 'undefined') {
      return;
    } else if (typeof num === 'string') {
      num = parseFloat(num);
    }
    return num.toFixed(params[1]);
  });
});