define('incus-vastaanota/pods/oauth2/procountor/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/models/traffic', 'incus-vastaanota/utils/list-utils', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsTraffic, _incusVastaanotaUtilsListUtils, _emberCliPaginationComputedPagedArray) {
  var _this = this;

  var SERVICE = "Procountor";
  var CONTEXT = "PROCOUNTOR-API";
  var REDIRECT_URL = encodeURIComponent("https://test-laskumappi.apix.fi/oauth2");
  var LOGIN_URL = "https://api-test.procountor.com/login?response_type=code&client_id=apixTestClient&state=procountor&redirect_uri=" + REDIRECT_URL;

  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      var tokenHandler = this.handleTokenUpdate(apixclient, userData, this.getURLparams);
      //let tokenHandler = null;

      var model = _ember['default'].Object.extend({
        service: null,
        context: null,
        redirect_url: null,
        token: null
      });

      return _ember['default'].RSVP.resolve(tokenHandler).then(function () {
        return apixclient.GetCustomToken(userData, CONTEXT);
      }).then(function (data) {

        var token = null;
        if (data) {
          token = data.token;
        }

        return model.create({
          redirect_url: REDIRECT_URL,
          token: token,
          service: SERVICE,
          context: CONTEXT
        });
      })['catch'](function (err) {
        return model.create({
          redirect_url: REDIRECT_URL,
          service: SERVICE,
          context: CONTEXT
        });
      });
    },

    //beforeModel: function(a,b,c){},
    //afterModel: function(model,transition){},

    actions: {

      doLogin: function doLogin() {
        window.location.replace(LOGIN_URL);
      },

      updateCustomToken: function updateCustomToken(token) {
        var model = this.get("controller.model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        apixclient.UpdateCustomToken(userData, model.get("context"), token).then(function (data) {
          model.set("token", token);
        });
      }

    },

    getURLparams: function getURLparams() {
      var query = window.location.search.substr(1);
      if (query === "") {
        var parts = window.location.hash.split("?");
        if (parts.length > 1) {
          query = parts[1];
        }
      }

      var result = {};
      query.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    },

    handleTokenUpdate: function handleTokenUpdate(apixclient, userData, getURLparams) {
      var tokenHandler = null;

      var params = {
        code: window.localStorage.getItem("oauth2_code"),
        state: window.localStorage.getItem("oauth2_state")
      };

      if (params.code && params.state) {

        var requestData = _this._requestDataString("ProcountorAPI", parameters);
        var c = apixclient.setContract(userData.TransferID, userData.TransferKey, userData.UniqueCompanyID, requestData);

        console.log(["Update token", params.state, params.code]);
        tokenHandler = apixclient.UpdateCustomToken(userData, params.state, params.code).then(function () {
          window.localStorage.removeItem("oauth2_code");
          window.localStorage.removeItem("oauth2_state");
        }).then(c);
      } else {
        console.log("No token defined in localstorage");
      }

      return tokenHandler;
    },

    _requestDataString: function _requestDataString(contractType, parameters) {
      var doc = window.document.implementation.createDocument(null, "Request", null);
      var request = doc.firstChild;
      request.setAttribute("version", "1.0");
      var content = doc.createElement("Content");
      var group = doc.createElement("Group");

      group.appendChild(this._valueElement(doc, "ContractType", contractType));
      group.appendChild(this._valueElement(doc, "ContractStatus", "ACTIVE"));
      group.appendChild(this._valueElement(doc, "Parameters", parameters));

      content.appendChild(group);
      request.appendChild(content);
      var xs = new XMLSerializer();
      var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
      //header = "";
      return header + xs.serializeToString(doc);
    },

    _valueElement: function _valueElement(doc, type, text) {
      var el = doc.createElement("Value");
      el.setAttribute("type", type);
      el.textContent = text;
      return el;
    }

  });
});