define("incus-vastaanota/initializers/route-css", ["exports", "ember"], function (exports, _ember) {

  var routeActivate = (function () {
    _ember["default"].$("body").addClass(this.routeName.replace(".", "-"));
  }).on("activate");

  var routeDeactivate = (function () {
    _ember["default"].$("body").removeClass(this.routeName.replace(".", "-"));
  }).on("deactivate");

  exports["default"] = {
    initialize: function initialize(container, app) {
      app.register("route-css:activate", routeActivate, {
        instantiate: false
      });
      app.register("route-css:deactivate", routeDeactivate, {
        instantiate: false
      });
      app.inject("route", "_routeCssActivate", "route-css:activate");
      app.inject("route", "_routeCssDeactivate", "route-css:deactivate");
    }
  };
});