define('incus-vastaanota/pods/components/my-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    enabled: null,

    init: function init() {
      this._super.apply(this, arguments);
    },

    actions: {
      ok: function ok() {
        this.sendAction('action');
      },
      toggleShow: function toggleShow() {
        this.toggleProperty("enabled");
      }
    }
  });
});