define("incus-vastaanota/instance-initializers/ember-i18n", ["exports", "ember", "ember-i18n/legacy-helper", "incus-vastaanota/config/environment"], function (exports, _ember, _emberI18nLegacyHelper, _incusVastaanotaConfigEnvironment) {
  exports["default"] = {
    name: 'ember-i18n',

    initialize: function initialize(instance) {
      if (_emberI18nLegacyHelper["default"] != null) {
        _ember["default"].HTMLBars._registerHelper('t', _emberI18nLegacyHelper["default"]);
      }
    }
  };
});