define("incus-vastaanota/models/letter", ["exports", "ember"], function (exports, _ember) {
	exports["default"] = _ember["default"].Object.extend({
		name: null,
		date: null,
		documents: null,
		page_amount: null,
		uuid: null,
		group: null,
		sent_date: null,
		sent_amount: null,
		sender_email: null,

		setFields: function setFields(values) {
			for (var key in values) {
				if (values.hasOwnProperty(key)) {
					this.set(key, values[key]);
				}
			}
		},

		toJSON: function toJSON() {
			var json_object = {};

			// for(let key in this){
			// 	if(this.hasOwnProperty(key)){
			// 		let temp = this.get(key);
			// 		json_object[key] = temp;
			// 	}
			// }

			// let converted = [];
			// let d = this.get("documents");

			// for(let i in d){
			// 	let temp = d[i];

			// }

			json_object.name = this.get("name");
			json_object.date = this.get("date");
			json_object.uuid = this.get("uuid");
			json_object.group = this.get("group");
			json_object.documents = this.get("documents");
			json_object.page_amount = this.get("page_amount");
			json_object.sent_date = this.get("sent_date");
			json_object.sent_amount = this.get("sent_amount");
			json_object.sender_email = this.get("sender_email");

			return JSON.stringify(json_object);
		},

		_arrayBufferToBase64: function _arrayBufferToBase64(buffer) {
			var binary = '';
			var bytes = new Uint8Array(buffer);
			var len = bytes.byteLength;
			for (var i = 0; i < len; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			return window.btoa(binary);
		}

	});
});