define('incus-vastaanota/pods/tools/users/route', ['exports', 'ember', 'incus-vastaanota/utils/email', 'incus-vastaanota/config/environment', 'incus-vastaanota/utils/countrycodes'], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaConfigEnvironment, _incusVastaanotaUtilsCountrycodes) {
  exports['default'] = _ember['default'].Route.extend({
    apixclient: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      return apixclient.listSubusers(userData, false).then(function (data) {

        var model = _ember['default'].Object.extend({
          editing: null,
          newUsername: '',
          newPass: '',
          newAllowPurchase: true,
          newAllowSell: true,
          editUsername: '',
          editPass: '',
          editAllowPurchase: true,
          editAllowSell: true,
          users: data,
          i18n: _this.get("i18n"),
          passwordStrengthClass: "neutral",
          passwordStrengthClass2: "neutral",
          newpassObserver: _ember['default'].observer("newPass", function () {
            var newPass = this.get("newPass");

            var _zxcvbn = zxcvbn(newPass);

            var score = _zxcvbn.score;

            console.log(score);
            switch (score) {
              case 1:
                this.set("passwordStrengthClass", "red");
                break;
              case 2:
                this.set("passwordStrengthClass", "orange");
                break;
              case 3:
                this.set("passwordStrengthClass", "yellow");
                break;
              case 4:
                this.set("passwordStrengthClass", "green");
                break;
              default:
                this.set("passwordStrengthClass", "neutral");
                break;
            }
          }),
          editPassObserver: _ember['default'].observer("editPass", function () {
            var newPass = this.get("editPass");

            var _zxcvbn2 = zxcvbn(newPass);

            var score = _zxcvbn2.score;

            switch (score) {
              case 1:
                this.set("passwordStrengthClass2", "red");
                break;
              case 2:
                this.set("passwordStrengthClass2", "orange");
                break;
              case 3:
                this.set("passwordStrengthClass2", "yellow");
                break;
              case 4:
                this.set("passwordStrengthClass2", "green");
                break;
              default:
                this.set("passwordStrengthClass2", "neutral");
                break;
            }
          })
        }).create();

        return model;
      });
    },
    afterModel: function afterModel(model) {}

  });
});