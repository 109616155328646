define("incus-vastaanota/pods/pricing/route", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    i18n: _ember["default"].inject.service(),
    countryselector: _ember["default"].inject.service(),

    model: function model() {
      var countryselector = this.get("countryselector");
      return _ember["default"].Object.extend({
        countryselector: countryselector
      }).create();
    }
  });
});