define('incus-vastaanota/pods/registration/form/route', ['exports', 'ember', 'incus-vastaanota/models/formdata', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaModelsFormdata, _incusVastaanotaConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    countryselector: _ember['default'].inject.service(),

    model: function model(params) {
      var routeContext = this;
      var softwares = this.get("countryselector.url") === "laskumappi" ? ["A-laskutus", "Asteri", "Certum", "Deston", "Directo", "Festum", "Fortnox", "Hansaworld", "Infoglove", "Lemonsoft", "MeritAktiva", "NBfinvoice", "Ninjaware", "Oiko", "PayEx", "Piimega", "Pupesoft", "Sunisoft", "Suonentieto", "TimeWorks", "Tribuni", "Zervant"] : ["Apix Fakturamappen", "24SevenOffice", "Amako", "Fakturan.nu", "Finago Procountor", "Hansaworld", "Magenta", "Prosmart", "Qvitoo", "Virtual Systems", "Younium"];
      var model = _ember['default'].Object.extend({
        formData: null,
        servicePhase: false,
        referer: null,
        countryselector: this.get("countryselector"),
        passwordObserver: _ember['default'].observer("formData.password.field", "formData.passwordVerification.field", function () {
          _ember['default'].run.debounce(routeContext, routeContext._validatePasswords, 500);
        }),

        usernameObserver: _ember['default'].observer("formData.username.field", function () {
          _ember['default'].run.debounce(routeContext, routeContext._checkUsernameAvailability, 500);
        }),

        businessIdObserver: _ember['default'].observer("formData.businessId.field", function () {
          _ember['default'].run.debounce(routeContext, routeContext._validateBusinessId, 500);
        }),

        serviceSelectionObserver: _ember['default'].observer("formData.categories.@each.field", function () {
          this.toggleProperty('servicePhase');
          var y = window.scrollY;
          var categories = this.get('formData.categories');
          var moveAmount = 400;
          if (categories[0].field || categories[1].field) {
            moveAmount = 800;
          }
          if (window.scrollBy) {
            _ember['default'].run.next(function () {
              window.scrollBy(0, moveAmount);
            });
          }
        }),

        countryList: ['FI', 'SE', 'NO', 'DK', 'LV', 'LT', 'PL', 'EE', 'GB', 'DE'],
        softwareList: [this.get("i18n").t("registration.softwareNames.other")].concat(softwares),
        languageList: ['fi_FI', 'en_US', 'sv_SE'],
        dataVerified: false,
        showDialog: false

      }).create({
        formData: _incusVastaanotaModelsFormdata['default'].create(),
        referer: params.ref
      });
      /*
      model.set('formData.businessId.field', '7105176-1');
      model.set('formData.companyName.field', 'Testiyritys');
      model.set('formData.streetAddress1.field', 'Testikatu 1');
      model.set('formData.streetAddress2.field', 'B 3');
      model.set('formData.postCode.field', '66200');
      model.set('formData.postOffice.field', 'Testipaikka');
      model.set('formData.phoneNumber.field', '+358-9-6655443');
      model.set('formData.contactPerson.field', 'Teppo Testaaja');
      model.set('formData.username.field', 'teppo3.testaaja@malli.fi');
      model.set('formData.password.field', 'salasana');
      model.set('formData.passwordVerification.field', 'salasana');
      */
      return model;
    },

    actions: {
      acceptContract: function acceptContract() {
        /*       window.registrationFormData = this.get('controller.model.formData');
              this.transitionTo('registration.receipt'); */

        _ember['default'].Logger.log('close dialog');
        this.toggleProperty('controller.model.showDialog');
        if (!this._verifyAll()) {
          return;
        }
        this._createCustomer();
      },
      queryBusinessId: function queryBusinessId() {
        //TODO
      },
      toggleDialog: function toggleDialog() {
        if (!this._verifyAll()) {
          return;
        }
        this.toggleProperty('controller.model.showDialog');
      },
      verifyForm: function verifyForm() {
        this._verifyFormData();
      }
    },

    _verifyAll: function _verifyAll() {
      var _this = this;

      var i18n = this.get("i18n");

      var valid = true;
      // First verify that the form is still ok
      if (!this._verifyFormData()) {
        // the _verifyFormData already displays the errors
        valid = false;
      }
      // Check that atleast one contract is accepted
      var categories = this.get("controller.model.formData.categories");
      if (!categories[0].field && !categories[1].field && !categories[2].field) {
        this.get("notify").error(i18n.t("registration.form.serviceMandatory").toString());
        valid = false;
      }
      categories.forEach(function (c) {
        var services = c.services;
        if (services) {
          services.forEach(function (s) {
            if (s.properties && s.properties.hasEditableParameter && s.field) {
              if (!s.parameter) {
                _this.get("notify").error(i18n.t("registration.form.parameterMandatory").toString() + " " + i18n.t("registration.service." + c.name + "." + s.name).toString());
                valid = false;
              } else {
                _ember['default'].set(s, 'parameter', _this._processEmailList(s.parameter));
                s.parameter.split(';').forEach(function (email) {
                  if (!_this._validateEmail(email)) {
                    _this.get("notify").error(i18n.t("registration.form.invalidNotifyEmail").toString() + " " + i18n.t("registration.service." + c.name + "." + s.name).toString());
                    valid = false;
                  }
                });
              }
            }
          });
        }
      });
      return valid;
    },

    _checkUsernameAvailability: function _checkUsernameAvailability() {
      var username = this.get("controller.model.formData.username.field");
      // TODO query username
    },

    _validateEEBusinessId: function _validateEEBusinessId(bid) {

      _ember['default'].Logger.log('Debug: validating EE');

      this.set("controller.model.formData.businessId.invalid", false);
      return true;

      // this calculation check is'not working correctly. For QND fix it has been removed
      /*
        //	alert("we be here");
      let multiplicationArray = [3, 7, 1, 3, 7, 1, 3, 7];
      let a = bid.split('');
      let sum = 0;
       for (let i = 0; i < 8; i++) {
        sum += a[i] * multiplicationArray[i];
      }
      let ceil=Math.round(sum / 10) * 10;
        let check=ceil-sum;
      let remainder=a[8];
          Ember.Logger.log("remainder:"+remainder+" and check is:" + check+ " from :"+ceil+" and:" +sum);
          Ember.Logger.log({remainder: remainder, check:check});
        if (remainder != check) {
        this.set("controller.model.formData.businessId.invalid", true);
        return false;
      }
      this.set("controller.model.formData.businessId.invalid", false);
      return true;
      */
    },

    /*
      Tunnus on muotoa NNNNNNN-T.
       Tunnuksen numeroita (7 kpl, tarvittaessa lisätään alkuun nolla; numeroita oli aikaisemmin kuusi, ja tätä vanhaa muotoa voi hyvin harvoin nähdä vieläkin) painotetaan vasemmalta lähtien kertoimilla 7, 9, 10, 5, 8, 4 ja 2.
      Tulot lasketaan yhteen.
      Summa jaetaan 11:llä.
      Jos jakojäännös on 0, tarkistusnumero on 0.
      Ei anneta tunnuksia, jotka tuottaisivat jakojäännöksen 1.
      Jos jakojäännös on 2..10, tarkistusnumero on 11 miinus jakojäännös.
      Esimerkki 1: 1572860-0
        1  5  7  2  8  6  0  - 0
        7  9 10  5  8  4  2
        7 45 70 10 64 24  0  = 220 ≡ 0 (mod 11) → 0
      Esimerkki 2: 0737546-2
        0  7  3  7  5  4  6  - 2
        7  9 10  5  8  4  2
        0 63 30 35 40 16 12  = 196 ≡ 9 (mod 11) → 2
      Kertoimista on lisätietoja standardiin ISO 7064 sisältyvän menetelmän Mod 11-2 kuvauksessa.
      Kuten Y-tunnus, myös rikosrekisterinumero muodostuu seitsemästä merkistä, väliviivasta ja tarkistusnumerosta, mutta muuten sisällöstä tai tarkisteen laskentatavasta ei ole mitään tietoa. Esimerkkejä kaivataan.
    */
    _validateBusinessId: function _validateBusinessId() {
      var currentUrl = this.get("countryselector.url");
      if (currentUrl === "laskumappi") {
        var businessId = this.get("controller.model.formData.businessId.field");

        if (/^EE\d{9}$/.test(businessId)) {
          return this._validateEEBusinessId(businessId.substring(2));
        }
        if (/^FI\d{8}$/.test(businessId)) {
          businessId = businessId.substring(2);
        } else if (/^\d{7}-\d$/.test(businessId)) {
          businessId = businessId.replace('-', '');
        } else {
          this.set("controller.model.formData.businessId.invalid", true);
          return false;
        }

        var id = businessId.substring(0, 7);
        var check = businessId.substring(7, 8);
        var multiplicationArray = [7, 9, 10, 5, 8, 4, 2];
        var a = id.split('');
        var sum = 0;

        for (var i = 0; i < a.length; i++) {
          sum += a[i] * multiplicationArray[i];
        }

        var remainder = sum % 11;
        if (remainder !== 0) {
          remainder = 11 - remainder;
        }
        if (typeof check === 'string') {
          check = parseInt(check);
          if (isNaN(check)) {
            _ember['default'].Logger.log('Error: last number is NaN?');
          }
        }
        if (typeof remainder === 'string') {
          remainder = parseInt(remainder);
          if (isNaN(remainder)) {
            _ember['default'].Logger.log('Error: calculated remainder is NaN?');
          }
        }

        if (remainder !== check) {
          this.set("controller.model.formData.businessId.invalid", true);
          return false;
        }
        this.set("controller.model.formData.businessId.invalid", false);
        return true;
      } else if (currentUrl === "fakturamappen") {
        //   let re = /([5]\d{5}[-]\d{4})|([5]\d{9}\s)/g
        var businessId = this.get("controller.model.formData.businessId.field");
        //   return re.test(businessId)
        if (businessId) {
          businessId = businessId.trim();
          if (businessId.includes('-')) {
            var arr = businessId.split('-');
            return arr[0].length === 6 && arr[1].length === 4;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },

    _validatePasswords: function _validatePasswords() {
      var password = this.get("controller.model.formData.password.field");
      var passwordVerification = this.get("controller.model.formData.passwordVerification.field");
      if (password !== null && passwordVerification !== null) {
        if (password !== passwordVerification) {
          this.set("controller.model.formData.password.invalid", true);
          this.set("controller.model.formData.passwordVerification.invalid", true);
          return false;
        } else if (password && password.length < 4) {
          this.set("controller.model.formData.password.invalid", true);
          this.set("controller.model.formData.passwordVerification.invalid", true);
        } else {
          this.set("controller.model.formData.password.invalid", false);
          this.set("controller.model.formData.passwordVerification.invalid", false);
          return true;
        }
      } else if (password !== null || passwordVerification !== null) {
        this.set("controller.model.formData.password.invalid", true);
        this.set("controller.model.formData.passwordVerification.invalid", true);
        return false;
      }
    },
    _validateUsername: function _validateUsername() {
      var username = this.get('controller.model.formData.username.field');
      var valid = this._validateEmail(username);
      if (valid) {
        this.set("controller.model.formData.username.invalid", false);
      } else {
        this.get("notify").error(this.get("i18n").t("registration.form.invalidUsername").toString(), { closeAfter: 10000 });
        this.set("controller.model.formData.username.invalid", true);
      }
    },
    _validateEmail: function _validateEmail(email) {
      var re = /^\S+@\S+\.\S+$/i;
      return re.test(email);
    },

    _processEmailList: function _processEmailList(email) {
      if (/,/.test(email)) {
        email = email.replace(/,/g, ';');
        email = email.replace(/ /g, '');
      } else {
        email = email.replace(/ /g, ';');
      }
      return email;
    },

    _verifyFormData: function _verifyFormData() {
      var _this2 = this;

      var fields = this.get("controller.model.formData.fieldNames");
      var valid = true;
      var i18n = this.get("i18n");
      /*if (!this.get('controller.model.softwareName')) {
        this.get("notify").error(this.get("i18n").t("registration.form.missingMandatory").toString() + " " + this.get("i18n").t("registration.form.softwareName"));
        valid = false;
      }*/
      /*if (!this.get('controller.model.softwareVersion')) {
        this.get("notify").error(this.get("i18n").t("registration.form.missingMandatory").toString() + " " + this.get("i18n").t("registration.form.softwareVersion"));
        valid = false;
      }*/
      fields.forEach(function (key) {
        var obj = _this2.get("controller.model.formData." + key);
        if (obj.get("mandatory")) {
          if (obj.get('field') !== null && obj.get('field')) {
            obj.set('invalid', false);
          } else {
            valid = false;
            obj.set('invalid', true);
            var str = i18n.t("registration.form.missingMandatory").toString() + " " + i18n.t("registration.formfields." + key).toString();
            _this2.get("notify").info(str);
          }
          _this2.set("controller.model.formData." + key, obj);
        }
      });

      var validPasswords = this._validatePasswords();
      if (!validPasswords) {
        valid = false;
        var password = this.get("controller.model.formData.password.field");
        if (password !== null && password.length < 4) {
          this.get("notify").error(this.get("i18n").t("registration.form.passwordTooShort").toString(), { closeAfter: 10000 });
        } else {
          this.get("notify").error(this.get("i18n").t("registration.form.passwordMismatch").toString(), { closeAfter: 10000 });
        }
      }

      var validBusinessId = this._validateBusinessId();
      if (!validBusinessId) {
        this.get("notify").error(this.get("i18n").t("registration.form.invalidBusinessId").toString(), { closeAfter: 10000 });
        valid = false;
      }
      // TODO Check username

      if (valid) {
        this.set("controller.model.dataVerified", true);
      } else {
        this.set("controller.model.dataVerified", false);
        this.get("notify").error(this.get("i18n").t("registration.form.verifyFormFailed").toString(), { closeAfter: 10000 });
      }
      return valid;
    },

    _createCustomer: function _createCustomer() {
      var _this3 = this;

      var formData = this.get('controller.model.formData');

      var requestData = this._generateAddCustomerXml(formData);
      var adminId = _incusVastaanotaConfigEnvironment['default'].adminId;
      var adminKey = _incusVastaanotaConfigEnvironment['default'].adminKey;
      if (_incusVastaanotaConfigEnvironment['default'].build === "fakturamappen") {
        adminId = _incusVastaanotaConfigEnvironment['default'].fakturamappenCreatorId;
        adminKey = _incusVastaanotaConfigEnvironment['default'].fakturamappenCreatorKey;
      }

      var apixclient = this.get('apixclient');

      var success = true;

      apixclient.registrationAddCustomer(adminId, adminKey, requestData).then(function (data) {
        if (typeof data !== 'object') {
          _ember['default'].Logger.log(typeof data !== 'object');
          _this3.get('notify').error(_this3.get("i18n").t("registration.form.apiError").toString(), {
            closeAfter: null
          });
          success = false;
          return;
        }
        var callStatus = data.callStatus;
        if (typeof callStatus === 'object' && callStatus.success) {
          if (callStatus.success !== 'OK') {
            var errText = "";
            if (callStatus.freeText) {
              //let userExistsRE = /(Can't add customer because it already exists)/;
              var otherErrorRE = /java.lang.Exception:(.*)\)/;

              var errorTexts = callStatus.freeText.split("\n");
              for (var i = 0; i < errorTexts.length; i++) {
                var err = errorTexts[i];

                var m2 = err.match(otherErrorRE);
                if (m2) {
                  errText = " " + m2[1];
                  break;
                }
              }

              if (errText.length == 0) {
                errText = " " + callStatus.statusCode;
              }

              //errText += " " + callStatus.statusCode;
              errText += "( " + formData.businessId.field;
              errText += ", " + formData.username.field + ")";
            }

            _this3.get('notify').error(_this3.get("i18n").t("registration.form.createCustomerFailed").toString() + errText, {
              closeAfter: null
            });
            success = false;
          }
        } else {
          _this3.get('notify').error(_this3.get("i18n").t("registration.form.apiError").toString(), {
            closeAfter: null
          });
          success = false;
        }

        if (success) {
          var notify = _this3.get('notify');
          if (data && typeof data === "object" && data.callStatus && data.callStatus.success === 'OK') {
            var len = data.data.length;
            if (len === 1) {
              var result = data.data[0];
              _ember['default'].Logger.log(result);
              return _this3._createContracts(adminId, adminKey, result);
            }
          } else {
            notify.error(_this3.get('i18n').t("registration.form.apiError").toString(), {
              closeAfter: null
            });
            return;
          }
        }
      });
    },

    _createContracts: function _createContracts(adminId, adminKey, result) {
      var _this4 = this;

      var categories = this.get('controller.model.formData.categories');
      var apixclient = this.get('apixclient');
      var uniqueCompanyId = result.UniqueID;
      var reqs = [];
      var requestData = undefined;
      var receiveIsOn = false;
      var serviceList = [];
      categories.forEach(function (c) {
        if (c.name === "receive" && c.field) {
          receiveIsOn = true;
        }
        if (!c.field) {
          return;
        }
        var software = null;
        var version = null;
        software = _this4.get('controller.model.formData.softwareName.field');
        version = ""; //#this.get('controller.model.softwareVersion');
        requestData = _this4._generateSetContractXml(c.apiName, null, software, version);
        reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData));

        if (c.services) {
          c.services.forEach(function (s) {
            if (s.properties && s.properties.isAdvert && s.field) {
              serviceList.push(s.name);
              // TODO notify sales
            } else if (s.field) {
                requestData = _this4._generateSetContractXml(s.apiName, s.parameter, software, version);
                reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData));
              }
          });
        }
      });
      var vatId = "";
      if (_incusVastaanotaConfigEnvironment['default'].build === "fakturamappen") {
        var software = this.get('controller.model.formData.softwareName.field');
        var bid = this.get("controller.model.formData.businessId.field").replace("-", "");
        vatId = "SE" + this.get("controller.model.formData.businessId.field").replace("-", "") + "01";
        requestData = this._generateSetContractXml("eTag", "@" + vatId + "@", software, "");
        reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData));
        if (receiveIsOn) {
          requestData = this._generateSetContractXml("EMailScan", bid + "@apixscan.se", software, "");
          reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData));
        }
      } else {

        var bid = this.get("controller.model.formData.businessId.field").replace("-", "");
        vatId = "FI" + bid;
        var software = this.get('controller.model.formData.softwareName.field');
        requestData = this._generateSetContractXml("eTag", "@" + vatId + "@", software, "");
        reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData));
        if (receiveIsOn) {
          requestData = this._generateSetContractXml("EMailScan", vatId + "@apix.fi", software, "");
          reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, requestData));
        }
      }

      var referer = this.get('controller.model.referer');
      if (referer) {
        var _requestData = this._generateSetContractXml("Registration", referer);
        reqs.push(apixclient.registrationSetContract(adminId, adminKey, uniqueCompanyId, _requestData));
      }

      return _ember['default'].RSVP.Promise.all(reqs).then(function (data) {
        var success = true;
        if (typeof data !== 'object') {
          _this4.get('notify').error(_this4.get("i18n").t("registration.form.apiError").toString(), {
            closeAfter: null
          });
          success = false;
          return;
        }
        data.forEach(function (response) {
          if (typeof response === 'object' && response.callStatus && response.callStatus.success) {
            if (response.callStatus.success !== 'OK') {
              var errText = "";
              if (response.callStatus.freeText) {
                errText = " " + response.callStatus.freeText;
              }
              _this4.get('notify').error(_this4.get("i18n").t("registration.form.addContractFailed").toString() + errText, {
                closeAfter: null
              });
              success = false;
            }
          } else {
            _this4.get('notify').error(_this4.get("i18n").t("registration.form.apiError").toString(), {
              closeAfter: null
            });
            success = false;
          }
        });
        if (success) {
          if (serviceList.length > 0) {
            _this4.get('notify').info(_this4.get("i18n").t("registration.form.contractsCreated").toString(), { closeAfter: 10000 });
            window.registrationFormData = _this4.get('controller.model.formData');
            _this4.transitionTo('registration.receipt');
          } else {
            _this4.get('notify').info(_this4.get("i18n").t("registration.form.contractsCreated").toString(), { closeAfter: 10000 });
            window.registrationFormData = _this4.get('controller.model.formData');
            _this4.transitionTo('registration.receipt');
          }
        }
      });
    },

    _generateSetContractXml: function _generateSetContractXml(contractType, parameter, software, version) {
      var doc = window.document.implementation.createDocument(null, "Request", null);
      var request = doc.firstChild;
      request.setAttribute("version", "1.0");
      var content = doc.createElement("Content");
      var group = doc.createElement("Group");

      group.appendChild(this._valueElement(doc, "ContractType", contractType));
      group.appendChild(this._valueElement(doc, "ContractStatus", "ACTIVE"));
      group.appendChild(this._valueElement(doc, "ContractSoftwareName", software));
      group.appendChild(this._valueElement(doc, "ContractSoftwareVersion", version));
      if (parameter) {
        group.appendChild(this._valueElement(doc, "Parameters", parameter));
      }
      content.appendChild(group);
      request.appendChild(content);
      var xs = new XMLSerializer();
      var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
      return header + xs.serializeToString(doc);
    },

    _generateAddCustomerXml: function _generateAddCustomerXml(formData) {
      var doc = window.document.implementation.createDocument(null, "Request", null);
      var request = doc.firstChild;
      request.setAttribute("version", "1.0");
      var content = doc.createElement("Content");
      var group = undefined;
      var country = _incusVastaanotaConfigEnvironment['default'].build === "fakturamappen" ? "SE" : "FI";
      group = doc.createElement("Group");
      group.appendChild(this._valueElement(doc, "Email", formData.username.field));
      group.appendChild(this._valueElement(doc, "Password", formData.password.field));
      group.appendChild(this._valueElement(doc, "Name", formData.companyName.field));
      group.appendChild(this._valueElement(doc, "Language", formData.language.field));
      group.appendChild(this._valueElement(doc, "y-tunnus", formData.businessId.field));
      group.appendChild(this._valueElement(doc, "ContactPerson", formData.contactPerson.field));
      group.appendChild(this._valueElement(doc, "PhoneNumber", formData.phoneNumber.field));
      group.appendChild(this._valueElement(doc, "ApixCountry", country));
      content.appendChild(group);

      group = doc.createElement("Group");
      group.appendChild(this._valueElement(doc, "AddressType", "Official"));
      group.appendChild(this._valueElement(doc, "Street1", formData.streetAddress1.field));
      group.appendChild(this._valueElement(doc, "Street2", formData.streetAddress2.field));
      group.appendChild(this._valueElement(doc, "PostalOffice", formData.postOffice.field));
      group.appendChild(this._valueElement(doc, "PostalCode", formData.postCode.field));
      group.appendChild(this._valueElement(doc, "Country", formData.country.field));

      content.appendChild(group);
      request.appendChild(content);
      var xs = new XMLSerializer();
      var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
      return header + xs.serializeToString(doc);
    },

    _generateEmailXml: function _generateEmailXml(data) {
      var doc = window.document.implementation.createDocument(null, "Request", null);
      var request = doc.firstChild;
      request.setAttribute("version", "1.0");
      var content = doc.createElement("Content");
      var group = undefined;

      group = doc.createElement("Group");
      group.appendChild(this._valueElement(doc, "MimeType", 'text/html; charset=UTF-8'));
      group.appendChild(this._valueElement(doc, "Content", data));

      content.appendChild(group);
      request.appendChild(content);
      var xs = new XMLSerializer();
      var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
      return header + xs.serializeToString(doc);
    },
    _generateEmailHtml: function _generateEmailHtml(serviceList, customer) {
      var formData = this.get('controller.model.formData');
      var doc = window.document.implementation.createDocument(null, "html", null);
      var html = doc.firstChild;
      var body = doc.createElement("body");
      var el = undefined;

      el = doc.createElement("p");
      el.textContent = "Yritys " + formData.get('companyName.field');
      body.appendChild(el);
      el = doc.createElement("p");
      el.textContent = "y-tunnus: " + formData.get('businessId.field');
      body.appendChild(el);
      el = doc.createElement("p");
      el.textContent = "UniqueID: " + customer.UniqueID;
      body.appendChild(el);
      el = doc.createElement("p");
      el.textContent = "Email: " + customer.Email;
      body.appendChild(el);
      el = doc.createElement("p");
      el.textContent = "Yritys on kiinnostunut seuraavista palveluista:\n";
      body.appendChild(el);

      serviceList.forEach(function (service) {
        el = doc.createElement("p");
        el.textContent = service;
        body.appendChild(el);
      });
      html.appendChild(body);
      var xs = new XMLSerializer();
      return xs.serializeToString(doc);
    },

    _valueElement: function _valueElement(doc, type, text) {
      var el = doc.createElement("Value");
      el.setAttribute("type", type);
      el.textContent = text;
      return el;
    }
  });
});