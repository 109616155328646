define('incus-vastaanota/models/invoice', ['exports', 'ember', 'incus-vastaanota/utils/email', 'incus-vastaanota/models/comment', 'incus-vastaanota/models/payment'], function (exports, _ember, _incusVastaanotaUtilsEmail, _incusVastaanotaModelsComment, _incusVastaanotaModelsPayment) {
  exports['default'] = _ember['default'].Object.extend({

    // XXX Should const be used? What's our stance on backwards compatibility?
    // Alternatively we could use Object.defineProperty and set a get() {return 'POSITIVE'}
    APPROVALSTATUS: {
      POSITIVE: 'POSITIVE',
      NEUTRAL: 'NEUTRAL',
      NEGATIVE: 'NEGATIVE'
    },

    INVOICECHANNEL: {
      SCANNED: 'Scanned',
      MANUAL: 'Manual',
      EDI: 'EDI',
      EINVOICE: 'eInvoice'
    },

    INVOICESTATUS: {
      NEW: 'NEW',
      WAIT: 'WAIT',
      READY: 'READY'
    },

    //VMS: Payment status: tilat on PAID ja NOT PAID (REMOVED jätetään pois)
    PAYMENTSTATUS: {
      NOTPAID: 'NOTPAID',
      PAID: 'PAID'
    },

    //REMOVED: 'REMOVED',
    /* Returns the list of valid invoice statuses given the current transfer and invoicestatus of the invoice */
    validInvoiceStatuses: _ember['default'].computed("transferStatus", "invoiceStatus", {
      get: function get() {
        var transferStatus = this.get("transferStatus");
        var invoiceStatus = this.get("invoiceStatus");
        if (transferStatus === "RETRIEVED") {
          return ["READY"];
        }
        if (invoiceStatus === "NEW") {
          return ["NEW", "WAIT", "READY"];
        }
        return ["WAIT", "READY"];
      }
    }),
    isValidPartialAmount: function isValidPartialAmount(amount) {
      if (amount === "" || isNaN(amount)) {
        return false;
      }
      return true;
    },

    totalPaidSum: _ember['default'].computed("paymentArray.length", {
      get: function get() {
        var arr = this.get("paymentArray");
        if (!arr) {
          return 0;
        }
        var arrLength = arr.length;
        var sum = 0;
        for (var i = 0; i < arrLength; i++) {
          sum += parseFloat(arr[i].get("amount"));
        }
        return sum.toFixed(2);
      }
    }),
    unpaidSum: _ember['default'].computed("paymentArray.length", {
      get: function get() {
        var arr = this.get("paymentArray");
        var total = this.get("amountToPay");
        var paymentStatus = this.get('paymentStatus');
        if (paymentStatus === "PAID") {
          return 0;
        }
        if (!arr) {
          return total;
        }
        var arrLength = arr.length;
        var sum = 0;
        for (var i = 0; i < arrLength; i++) {
          sum += parseFloat(arr[i].get("amount"));
        }
        return total - sum.toFixed(2);
      }
    }),

    setFields: function setFields(values) {
      var _this = this;

      this.set("postingDate", new Date()); // default
      this.set("paymentDate", new Date()); // default
      this.set("paidAmount", 0); // default

      // The list2 and searchreceivedarchive both return metadata + storage info
      var fieldNames = this.get('metadataFieldNames').concat(this.get('storageFieldNames'));
      // TODO verify paymentStatus, invoiceChannel, invoiceStatus and approvalStatus against the consts
      fieldNames.forEach(function (entry) {
        // Payments handled separetly
        // comment.0: "20150612071553 20150612071553    21424.123"
        if (entry === 'paymentArray') {
          var paymentSize = values['payment.size'];
          if (!paymentSize) {
            return;
          }
          var paymentArray = _this.get("paymentArray");
          if (!paymentArray) {
            paymentArray = [];
          }
          for (var i = 0; i < paymentSize; i++) {
            var id = 'payment.' + i;
            var payment = _incusVastaanotaModelsPayment['default'].create();
            payment.parsePayment(i, values[id]);
            paymentArray.push(payment);
          }
          _this.set("paymentArray", paymentArray);
          return;
        }
        // Comments handled separetly
        // comment.0: "20150612071553	POSITIVE    pekka.malli@malli.fi    Testikommentti 1"
        if (entry === 'commentArray') {
          var commentSize = values['comment.size'];
          if (!commentSize) {
            // no comments
            return;
          }
          var commentArray = _this.get("commentArray");
          if (!commentArray) {
            commentArray = [];
          }
          for (var i = 0; i < commentSize; i++) {
            var commentId = 'comment.' + i;
            var comment = _incusVastaanotaModelsComment['default'].create();
            comment.parseComment(i, values[commentId]);
            commentArray.push(comment);
          }
          _this.set("commentArray", commentArray);
          return;
        }
        // Approvers handled separetly
        // Due to metadataFieldNames order comments already processed.
        // approver.0: "20150612071553	pekka.malli@malli.fi"
        if (entry === 'approversList') {
          var approverSize = values['approver.size'];
          if (!approverSize) {
            // no comments
            return;
          }
          var approversList = _this.get("approversList");
          if (!approversList) {
            approversList = [];
          }
          for (var i = 0; i < approverSize; i++) {
            var approverId = 'approver.' + i;
            var approver = _this._parseApprover(values[approverId]);
            approversList.push(approver);
          }
          _this.set("approversList", approversList);
          return;
        }

        var key = _ember['default'].String.capitalize(entry);
        if (values.hasOwnProperty(key)) {
          _this.set(entry, values[key]);
          return;
        } else {
          // TODO handle errors
          //Ember.Logger.log("Error: No value for " + key);
          return;
        }
      });
      //Ember.Logger.log("after parsing, approversList", this.get('approversList'));
    },

    // 485295018000205740100228800000000000001180050173150716
    // 485295018000205740100228800000000000001180050173150714
    // 485295018000205740002240000000000000001180050173150721

    readBarcode: function readBarcode(value) {
      // TODO error handling
      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
          position = position || 0;
          return this.indexOf(searchString, position) === position;
        };
      }
      if (!value.startsWith("4")) {
        var errorString = "Barcode version is not 4, but: " + value.substring(0, 1);
        _ember['default'].Logger.log(errorString);
        return errorString;
      }
      _ember['default'].Logger.log(typeof value);
      if (value.length != 1 + 16 + 8 + 3 + 20 + 6) {
        var errorString = "Barcode does not have the correct length. Length received: " + value.length + " expected " + (1 + 16 + 8 + 3 + 20 + 6);
        _ember['default'].Logger.log(errorString);
        return errorString;
      }

      var bankAccount = "FI" + value.substring(1, 17);
      this.set("bankAccount", bankAccount);
      var amountToPay = parseFloat(value.substring(17, 23) + "." + value.substring(23, 25));
      this.set("amountToPay", amountToPay);
      var paymentReference = value.substring(28, 48).replace(/^0+/, "");
      this.set("paymentReference", paymentReference);
      var dueDate = "20" + value.substring(48, 54);
      this.set("dueDate", dueDate);
    },

    // https://www.fkl.fi/teemasivut/sepa/tekninen_dokumentaatio/Dokumentit/Pankkiviivakoodi-opas.pdf

    generateBarcode: function generateBarcode() {
      var result = '4';
      var account = this.get("bankAccount");
      if (!account) {
        return '';
      }
      var pad = "0000000000000000"; //16
      account = account.substring(2).replace(/ /g, '');
      var temp = (pad + account).slice(-pad.length);
      result += temp;
      pad = "00000000"; //8
      _ember['default'].Logger.log("sum pad", pad.length);
      var amountToPay = this.get("amountToPay").toString().replace('.', '');
      _ember['default'].Logger.log(amountToPay);
      temp = (pad + amountToPay).slice(-pad.length);
      _ember['default'].Logger.log("amount", temp);
      result += temp;
      result += '000';
      pad = "00000000000000000000"; //20
      _ember['default'].Logger.log("ref pad", pad.length);
      var ref = this.get("paymentReference").replace(/ /g, '');
      var re = /\D/;
      if (re.test(ref)) {
        return '';
      }
      temp = (pad + ref).slice(-pad.length);
      _ember['default'].Logger.log("ref", temp);
      result += temp;
      var date = this.get("dueDate").substring(2);
      _ember['default'].Logger.log("date", date);
      result += date;
      _ember['default'].Logger.log(result, result.length);
      return result;
    },

    // Returns the new invoice status
    addComment: function addComment(approvalStatus, email, commentText) {
      _ember['default'].Logger.log("Adding a new comment: " + approvalStatus + " - " + commentText);
      var commentNumber = 0;
      var commentArray = this.get("commentArray");
      if (!commentArray) {
        commentArray = [];
      } else {
        commentNumber = commentArray.length;
      }
      _ember['default'].Logger.log(commentArray);
      _ember['default'].Logger.log(commentNumber);
      // Using localtime as original implementation also used localtime
      var timeStamp = moment().format('YYYYMMDDHHmmss');
      var originalString = [timeStamp, approvalStatus, email, commentText].join("\t");
      var comment = _incusVastaanotaModelsComment['default'].create({
        commentNumber: commentNumber,
        timeStamp: timeStamp,
        approvalStatus: approvalStatus,
        content: commentText,
        email: email,
        originalString: originalString
      });
      commentArray.pushObject(comment);
      this.set("commentArray", commentArray);
      this._updateApprovalStatus();
      return [originalString, commentNumber];
    },
    //Returns error string
    addPayment: function addPayment(amount, paymentDate, postingDate) {
      //check validity

      _ember['default'].Logger.log("Adding a new payment: " + amount + " - " + paymentDate + " - " + postingDate);
      var paymentNumber = 0;
      var paymentArray = this.get("paymentArray");
      if (!paymentArray) {
        paymentArray = [];
      } else {
        paymentNumber = paymentArray.length;
      }
      _ember['default'].Logger.log(paymentArray);
      _ember['default'].Logger.log(paymentNumber);
      var originalString = [amount, paymentDate, postingDate].join("\t");
      var payment = _incusVastaanotaModelsPayment['default'].create({
        paymentNumber: paymentNumber,
        amount: amount,
        paymentDate: paymentDate,
        postingDate: postingDate,
        originalString: originalString
      });
      paymentArray.pushObject(payment);
      this.set("paymentArray", paymentArray);
      this.set("postingDate", new Date()); // default
      this.set("paymentDate", new Date()); // default
      this.set("paidAmount", 0); // default
      //this._updateApprovalStatus();
      return [originalString, paymentNumber];
    },

    addApprover: function addApprover(emailRecipient) {
      _ember['default'].Logger.log("Adding a new approver:", emailRecipient);
      var approverNumber = 0;
      var approversList = this.get("approversList");
      if (!approversList) {
        approversList = [];
      } else {
        approverNumber = approversList.length;
      }
      // Only one entry per approver.
      _ember['default'].Logger.log(approversList);
      for (var i = 0; i < approversList.length; i++) {
        if (approversList[i].emailAddress === emailRecipient) {
          approverNumber = i;
          break;
        }
      }
      var timeStamp = moment().format('YYYYMMDDHHmmss');
      var originalString = [timeStamp, emailRecipient].join("\t");

      var approver = {
        emailAddress: emailRecipient,
        timeStamp: timeStamp,
        originalString: originalString,
        /* Theoretically there could already be a comment by the email address
        we're adding here, but I don't think it makes sense to handle that case.
        When the metadata will be fetched next it will be displayed anyways
        */
        commentIds: []
      };
      _ember['default'].Logger.log(approver);
      approversList[approverNumber] = approver;
      this.set("approversList", approversList);
      return [originalString, approverNumber];
    },

    // TODO use the document.implementation.createDocument
    toXML: function toXML() {
      var _this2 = this;

      var str = "<Group>\r\n";
      this.get('metadataFieldNames').forEach(function (entry) {
        if (entry === 'commentArray') {
          var arr = _this2.get(entry);
          if (!arr) {
            return;
          }
          var arrLength = arr.length;
          str = str + '<Value type="comment.size">' + _incusVastaanotaUtilsEmail['default'].escapeXML(arrLength) + "</Value>\n";
          for (var i = 0; i < arrLength; i++) {
            str = str + '<Value type="comment.' + i + '">' + _incusVastaanotaUtilsEmail['default'].escapeXML(arr[i].get("originalString")) + "</Value>\r\n";
          }
          return;
        }
        if (entry === 'paymentArray') {
          var arr = _this2.get(entry);
          if (!arr) {
            return;
          }
          var arrLength = arr.length;
          str = str + '<Value type="payment.size">' + _incusVastaanotaUtilsEmail['default'].escapeXML(arrLength) + "</Value>\n";
          for (var i = 0; i < arrLength; i++) {
            str = str + '<Value type="payment.' + i + '">' + _incusVastaanotaUtilsEmail['default'].escapeXML(arr[i].get("originalString")) + "</Value>\r\n";
          }
          return;
        }
        if (entry === 'approversList') {
          var arr = _this2.get(entry);
          if (!arr) {
            return;
          }
          var arrLength = arr.length;
          str = str + '<Value type="approver.size">' + _incusVastaanotaUtilsEmail['default'].escapeXML(arrLength) + "</Value>\n";
          for (var i = 0; i < arrLength; i++) {
            str = str + '<Value type="approver.' + i + '">' + _incusVastaanotaUtilsEmail['default'].escapeXML(arr[i].originalString) + "</Value>\r\n";
          }
          return;
        }
        var value = _this2.get(entry);
        if (value) {
          var key = _ember['default'].String.capitalize(entry);
          str = str + '<Value type="' + key + '">' + _incusVastaanotaUtilsEmail['default'].escapeXML(value) + "</Value>\r\n";
          return;
        }
      });
      str = str + '</Group>';
      return str;
    },

    togglePaymentStatus: function togglePaymentStatus() {
      var invoice = this;
      var paymentStatus = invoice.get('paymentStatus');
      if (paymentStatus === "PAID") {
        invoice.set('paymentStatus', invoice.PAYMENTSTATUS.NOTPAID);
      } else {
        invoice.set('paymentStatus', invoice.PAYMENTSTATUS.PAID);
      }
    },

    _parseApprover: function _parseApprover(data) {
      // approver.0: "20150612071553 pekka.malli@malli.fi"
      _ember['default'].Logger.log("parsing approver", data);
      var re = /^(\d+)\s+([^\s]+)/;
      var result = re.exec(data);
      if (result && result[0]) {
        var approver = {
          originalString: data,
          timeStamp: result[1],
          emailAddress: result[2]
        };
        // Iterate comments, to see if already approved
        var commentIds = [];
        var commentArray = this.get('commentArray');
        if (commentArray) {
          for (var i = 0; i < commentArray.length; i++) {
            if (commentArray[i].get('email') === result[2]) {
              commentIds.push(i);
            }
          }
        }
        approver.commentIds = commentIds;
        return approver;
      } else {
        _ember['default'].Logger.log("String not recognised as an approver: " + data);
      }
    },

    // TODO verify what is the logic here
    _updateApprovalStatus: function _updateApprovalStatus() {
      var _this3 = this;

      var commentArray = this.get("commentArray");
      var positive = 0;
      var negative = 0;
      var neutral = 0;
      commentArray.forEach(function (comment) {
        var commentApproval = comment.get("approvalStatus");
        if (commentApproval === _this3.APPROVALSTATUS.NEGATIVE) {
          negative++;
        }
        if (commentApproval === _this3.APPROVALSTATUS.POSITIVE) {
          positive++;
        }
        if (commentApproval === _this3.APPROVALSTATUS.NEUTRAL) {
          neutral++;
        }
      });
      var invoiceStatus = this.APPROVALSTATUS.NEUTRAL;
      if (positive > 0) {
        invoiceStatus = this.APPROVALSTATUS.POSITIVE;
      }
      if (neutral > 0) {
        invoiceStatus = this.APPROVALSTATUS.NEUTRAL;
      }
      if (negative > 0) {
        invoiceStatus = this.APPROVALSTATUS.NEGATIVE;
      }
      _ember['default'].Logger.log("resulting status: " + invoiceStatus);
      this.set("approvalStatus", invoiceStatus);
    },

    // XXX Copy-pasted from old vastaanota+. Probably ok, verify
    getBicCode: function getBicCode() {
      var iban = this.get("bankAccount");
      if (!iban) {
        return '';
      }
      iban = iban.toUpperCase().replace(/ /g, '');

      if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
          position = position || 0;
          return this.indexOf(searchString, position) === position;
        };
      }
      if (!iban.startsWith("FI")) {
        return null;
      }
      var bcode = iban.substring(4, 5);
      var bcode2 = iban.substring(4, 7);
      var bgroup = iban.substring(5, 6);

      if (bcode === "1" || bcode === "2") {
        return "NDEAFIHH";
      }
      if (bcode === "3") {
        if (bgroup === "1") {
          return "HANDFIHH";
        }
        if (bgroup === "3") {
          return "ESSEFIHX";
        }
        if (bgroup === "4") {
          return "DBDFIHX";
        }
        if (bgroup === "6") {
          return "SBANFIHH";
        }
        if (bgroup === "7") {
          return "DNBAFIHX";
        }
        if (bgroup === "8") {
          return "SWEDFIHH";
        }
        if (bgroup === "9") {
          return "SBANFIHH";
        }
      }
      if (bcode === "4") {
        var helsfi_map1 = {
          "405": "HELSFIHH",
          "497": "HELSFIHH",
          "400": "ITELFIHH",
          "402": "ITELFIHH",
          "403": "ITELFIHH",
          "479": "POPFFI22",
          "499": "HELSFIHH"
        };

        if (helsfi_map1[bcode2]) {
          return helsfi_map1[bcode2];
        }

        if (bcode2 >= 406 && bcode2 <= 408 || bcode2 >= 410 && bcode2 <= 412 || bcode2 >= 414 && bcode2 <= 421 || bcode2 >= 423 && bcode2 <= 432 || bcode2 >= 435 && bcode2 <= 452 || bcode2 >= 454 && bcode2 <= 464 || bcode2 >= 483 && bcode2 <= 493 || bcode2 >= 495 && bcode2 <= 496) {
          return "ITELFIHH";
        }

        if (bcode2 >= 470 && bcode2 <= 478) {
          return "POPFFI22";
        }

        return "HELSFIHH";
      }
      if (bcode === "5") {
        return "OKOYFIHH";
      }
      if (bcode === "6") {
        return "AABAFI22";
      }

      if (bcode2 === "713") {
        return "CITIFIHX";
      }

      if (bcode2 === "799") {
        return "HOLVFIHH";
      }

      if (bcode === "8") {
        return "DABAFIHH";
      }
      return null;
    },

    metadataFieldNames: ['documentID', 'dueDate', 'senderVAT', 'taxAmount', 'documentDate', 'senderName', 'paymentReference', 'paymentStatus', // PAID, NOTPAID
    'invoiceStatus', // NEW, WAIT, READY
    'transferStatus', // RETRIEVED or ???
    'sentToBookkeeping', 'receiverVAT', 'imageName', 'documentName', 'amountToPay', 'createdDate', 'bankAccount', 'currency', 'receiverName', 'invoiceChannel', 'commentArray', 'paymentArray', 'approvalStatus', 'approversList'],

    storageFieldNames: ['storageID', 'storageKey', 'storageStatus'],

    // Metadata
    amountToPay: null,
    approvalStatus: null,
    bankAccount: null,
    createdDate: null,
    currency: null,
    documentDate: null,
    documentID: null,
    documentLink: null,
    documentName: null,
    dueDate: null,
    imageLink: null,
    imageName: null,
    invoiceChannel: null,
    orderReference: null,
    paymentReference: null,
    paymentStatus: null,
    receiverID: null,
    receiverName: null,
    receiverVAT: null,
    //  I guess sellerReference is not used with archive invoices
    //  sellerReference: null,
    senderName: null,
    senderVAT: null,
    taxAmount: null,
    invoiceStatus: "NEW",
    transferStatus: null,
    sentToBookkeeping: null,

    // storage
    storageID: null,
    storageKey: null,
    storageStatus: null,

    // internal
    commentArray: null,
    dlURL: null,
    showDetails: false,

    isSentToChecker: (function () {
      var approversList = this.get('approversList');
      //Ember.Logger.log("approversList is", approversList);
      if (approversList && approversList.length) {
        return true;
      } else {
        return false;
      }
    }).property('approversList'),

    isDueUnder4: (function () {
      var dueDate = moment(this.get('dueDate'), 'YYYYMMDD');
      // If already paid ignore due date
      if (this.get('paymentStatus') === this.PAYMENTSTATUS.PAID) {
        return false;
      }
      return dueDate.isAfter(moment()) && dueDate.isBefore(moment(0, 'HH').add(4, 'days'));
    }).property('dueDate', 'paymentStatus'),
    pastDue: (function () {
      if (this.get('paymentStatus') === this.PAYMENTSTATUS.PAID) {
        return false;
      }
      var dueDate = moment(this.get('dueDate'), 'YYYYMMDD');
      return moment().isAfter(dueDate);
    }).property('dueDate', 'paymentStatus'),

    parsedCurrency: (function () {
      var currencyMap = {
        EUR: '€',
        USD: '$',
        GBP: '£'
      };
      var currency = this.get('currency');
      if (currencyMap[currency]) {
        currency = currencyMap[currency];
      }
      return currency;
    }).property('currency'),

    documentIDNumber: (function () {
      return parseInt(this.get('documentID'));
    }).property('documentID'),
    formattedReference: function formattedReference(ref) {
      _ember['default'].Logger.log("formatted: ", ref);
      if (_ember['default'].isPresent(ref) && ref !== 'N/A') {
        return ref;
      }
    },
    formattedPaymentReference: (function () {
      return this.formattedReference(this.get('paymentReference'));
    }).property('paymentReference'),
    formattedSellerReference: (function () {
      return this.formattedReference(this.get('sellerReference'));
    }).property('sellerReference'),

    nonApproverComments: _ember['default'].computed('approversList.[]', 'commentArray', function () {
      var approversList = this.get("approversList");
      if (!approversList) {
        approversList = [];
      }

      var commentArray = this.get("commentArray");
      if (!commentArray) {
        return [];
      }
      var idList = [];
      var ids = [];

      approversList.forEach(function (approver) {
        approver.commentIds.forEach(function (id) {
          ids.push(id);
        });
      });
      for (var i = 0; i < commentArray.length; i++) {
        if (ids.indexOf(i) === -1) {
          _ember['default'].Logger.log("adding", i);
          idList.push(i);
        }
      }

      return idList;
    })

  });
});