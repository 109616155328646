define('incus-vastaanota/utils/demo', ['exports', 'ember', 'incus-vastaanota/models/invoice', 'incus-vastaanota/config/environment'], function (exports, _ember, _incusVastaanotaModelsInvoice, _incusVastaanotaConfigEnvironment) {
    //import ApixClient from 'incus-vastaanota/services/apixclient';

    exports['default'] = {
        check_call: function check_call(method, path, params) {
            if (_incusVastaanotaConfigEnvironment['default'].environment === "demo" && (method === 'PUT' || method === 'DELETE')) {
                return true;
            }
            return false;
        },

        check_env: function check_env() {
            return _incusVastaanotaConfigEnvironment['default'].environment === "demo";
        },

        handle_call: function handle_call(apixclient, method, path, params) {

            _ember['default'].Logger.log("call/demo for method " + method + " blocked, path: " + path);

            _ember['default'].Logger.log(["handle_call", apixclient, method, path, params]);

            var stub_response = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Response><Status>OK</Status><StatusCode>2G00</StatusCode><Content><Group></Group></Content></Response>';
            //let invoice_sent_ok = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Response><Status>OK</Status><StatusCode>2200</StatusCode><Content><Group><Value type="BatchID">E28ece7a5-5fb9-44a7-887a-a329a82b45ba</Value><Value type="Saldo">90000</Value><Value type="CostInCredits">0.00</Value><Value type="Letters">0</Value><Value type="NetworkedInvoices">1</Value><Value type="LetterPages">0</Value><Value type="AcceptedDocument">1</Value></Group><Group><Value type="AcceptedDocumentID">demoinvoice</Value><Value type="ValidateText">OK 1</Value></Group></Content></Response>';
            //let method_response_paper = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Response><Status>OK</Status><StatusCode>2G00</StatusCode><Content><Group><Value type="LetterClass">1</Value><Value type="ReceiverYtunnus">null</Value><Value type="SenderYtunnus">2428598-7</Value><Value type="LetterCountry">FI</Value><Value type="ChannelName">Paper</Value><Value type="LetterPrintColor">BW</Value><Value type="MessageType">eInvoice</Value><Value type="SenderName">TestiYritys Oy</Value><Value type="ReceiverName">afwefewa</Value></Group></Content></Response>';
            var addressquery_response_einvoice = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?> <Response> <Status>OK</Status> <StatusCode>2A00</StatusCode> <Content> <Group> <Value type="ReceiverName">Demoyritys Oy</Value> <Value type="ReceiverYtunnus">1234567-8</Value> <Value type="ReceivereInvoiceAddress">0037123456781</Value> <Value type="ReceiverOperator">Operaattori 1</Value> <Value type="ReceiverOperatorId">00372332748700001</Value> </Group> <Group> <Value type="ReceiverName">Demoyritys Oy</Value> <Value type="ReceiverYtunnus">1234567-8</Value> <Value type="ReceivereInvoiceAddress">0037123456782</Value> <Value type="ReceiverOperator">Operaattori 2</Value> <Value type="ReceiverOperatorId">00372332748700001</Value> </Group> </Content> </Response>';

            var pistop_list_response = '<?xml version="1.0" encoding="UTF-8" standalone="yes"?> <Response> <Status>OK</Status> <StatusCode>7000</StatusCode> <Content> <Group/> <Group> <Value type="uniqueMessageID">__DEMO__</Value> <Value type="documentID">12345</Value> <Value type="createTime">2016-11-18 08:06:46.0</Value> <Value type="expireTime">2016-11-18 09:06:46.0</Value> <Value type="receiverName">Demovastaanottaja Oy</Value> <Value type="receiverVAT">1234567-8</Value> <Value type="receiverCountryCode">FI</Value> <Value type="senderName">Demolähettäjä Oy</Value> <Value type="senderVAT">8765432-1</Value> <Value type="dueDate">2016-12-02</Value> <Value type="sum">100.20</Value> <Value type="deliveryMethod">eInvoice</Value> <Value type="dataName"></Value> <Value type="imageName"></Value> </Group> <Group> <Value type="uniqueMessageID">__DEMO__</Value> <Value type="documentID">54321</Value> <Value type="createTime">2016-11-18 08:06:46.0</Value> <Value type="expireTime">2016-11-18 09:06:46.0</Value> <Value type="receiverName">Demovastaanottaja 2 Oy</Value> <Value type="receiverVAT">1234567-8</Value> <Value type="receiverCountryCode">FI</Value> <Value type="senderName">Demolähettäjä 2 Oy</Value> <Value type="senderVAT">8765432-1</Value> <Value type="dueDate">2016-12-02</Value> <Value type="sum">20.00</Value> <Value type="deliveryMethod">eInvoice</Value> <Value type="dataName"></Value> <Value type="imageName"></Value><Value type="testTrafficLight">true</Value> </Group> </Content> </Response>';

            var data = stub_response;

            if (path.indexOf("/addressquery?") > 0) {
                data = addressquery_response_einvoice;
            } else if (path.indexOf("/Customer") > 0) {
                data = pistop_list_response;
            }

            data = _ember['default'].$.parseXML(data);
            data = apixclient._transformToObject(data);

            return new Promise(function (resolve) {
                resolve(data);
            });
        },

        get_dl_link: function get_dl_link(apixclient, uniqueCompanyId, transferId, transferKey, uniqueMessageID, role, type, extension) {
            _ember['default'].Logger.log("Replace PitStopDownloadURL with DEMO storage link");

            if (type === "data") {
                type = "invoice";
            }

            /*
            let storageId = "22f7745b-7b01-48a5-a2ce-3a20007f494b"; //"__REPLACE_ME_WITH_STATIC_STORAGEID";
            let storageKey = "913310972736"; //"__REPLACE_ME_WITH_STATIC_STORAGEKEY";
            
            let fileName = null;
            let imageName = "05e1bd7c-956f-4379-bc4e-96de4194f9c9.pdf";
            let xmlName = "invoice.xml";
            */

            var storageId = "7a111908-f454-412e-affc-b4f8f6b51215";
            var storageKey = "588530568377";

            var fileName = null;
            var imageName = "image0.pdf";
            var xmlName = "Invoice6638.xml";

            var mime = "application/octet-stream";

            if (type === "image") {
                mime = 'application/pdf';
                fileName = imageName;
            } else if (type === "invoice") {
                mime = 'application/xml';
                fileName = xmlName;
            }

            var url = _ember['default'].String.htmlSafe(apixclient.downloadURL(storageId, storageKey, 'no', fileName, fileName, mime, 'inline'));

            _ember['default'].Logger.log(url);

            return url;
        }

    };
});