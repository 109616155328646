define('incus-vastaanota/pods/oauth2/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/models/traffic', 'incus-vastaanota/utils/list-utils', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsTraffic, _incusVastaanotaUtilsListUtils, _emberCliPaginationComputedPagedArray) {
  var _this = this;

  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      var tokenHandler = null;

      var model = _ember['default'].Object.extend({
        service: null,
        context: null,
        token: null,
        error: null,
        waiting: null
      });

      return _ember['default'].RSVP.resolve(tokenHandler).then(function (params) {
        if (params === null) {
          params = {};
        }

        model.params = params;
        return apixclient.GetCustomToken(userData, params.state);
      }).then(function (data) {

        var token = null;
        if (data) {
          token = data.token;
        }

        return model.create({
          token: model.params.code,
          context: model.params.state
        });
      })['catch'](function (err) {
        return model.create({
          context: model.params.state
        });
      });
    },

    //beforeModel: function(a,b,c){},
    afterModel: function afterModel(model, transition) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];
      var tokenHandler = this.handleTokenUpdate(model, apixclient, userData);
    },

    actions: {

      updateCustomToken: function updateCustomToken(token) {
        var model = this.get("controller.model");
        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        apixclient.UpdateCustomToken(userData, model.get("context"), token).then(function (data) {
          model.set("token", token);
        });
      }

    },

    handleTokenUpdate: function handleTokenUpdate(model, apixclient, userData) {
      var tokenHandler = null;
      //let model = this.get("controller.model");

      var params = {
        code: window.localStorage.getItem("oauth2_code"),
        state: window.localStorage.getItem("oauth2_state")
      };

      if (params.code && params.state) {
        model.set("context", params.state);

        console.log(["Update token", params.state, params.code]);
        model.set("waiting", true);
        tokenHandler = apixclient.AuthorizeToken(userData, params.state, params.code).then(function () {
          model.set("waiting", null);
          window.localStorage.removeItem("oauth2_code");
          window.localStorage.removeItem("oauth2_state");

          model.set("context", params.state);
          model.set("token", "token");

          return params;
        })['catch'](function (err, a) {
          console.log([err, a]);
          model.set("waiting", null);
          _this.get('notify').error(_this.get("i18n").t("common.error").toString() + " " + params.state + "-liitos epäonnistui");
        });
      } else {
        console.log("No token defined in localstorage");
      }

      return tokenHandler;
    },

    _valueElement: function _valueElement(doc, type, text) {
      var el = doc.createElement("Value");
      el.setAttribute("type", type);
      el.textContent = text;
      return el;
    },

    _generateEmailXml: function _generateEmailXml(data) {
      var doc = window.document.implementation.createDocument(null, "Request", null);
      var request = doc.firstChild;
      request.setAttribute("version", "1.0");
      var content = doc.createElement("Content");
      var group = undefined;

      group = doc.createElement("Group");
      group.appendChild(this._valueElement(doc, "MimeType", 'text/html; charset=UTF-8'));
      group.appendChild(this._valueElement(doc, "Content", data));

      content.appendChild(group);
      request.appendChild(content);
      var xs = new XMLSerializer();
      var header = "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"yes\" ?>\n";
      return header + xs.serializeToString(doc);
    },

    _generateEmailHtml: function _generateEmailHtml(userData, context) {
      var formData = this.get('controller.model.formData');
      var doc = window.document.implementation.createDocument(null, "html", null);
      var html = doc.firstChild;
      var body = doc.createElement("body");
      var el = undefined;

      el = doc.createElement("p");
      el.textContent = "Ympäristö: " + _incusVastaanotaConfigEnvironment['default'].environment;
      body.appendChild(el);

      body.appendChild(doc.createElement("br"));

      el = doc.createElement("p");
      el.textContent = "Yritys " + userData.Name;
      body.appendChild(el);
      el = doc.createElement("p");
      el.textContent = "y-tunnus: " + userData.Ytunnus;
      body.appendChild(el);
      el = doc.createElement("p");
      el.textContent = "UniqueID: " + userData.UniqueCompanyID;
      body.appendChild(el);

      el = doc.createElement("p");
      el.textContent = "Palvelu: " + context;
      body.appendChild(el);

      el = doc.createElement("p");
      el.textContent = "Yritys liittänyt palvelun " + context + " Laskumapin kautta.\n";
      body.appendChild(el);

      html.appendChild(body);
      var xs = new XMLSerializer();
      return xs.serializeToString(doc);
    }

  });
});