define('incus-vastaanota/pods/traffic/pitstop/advertisement/route', ['exports', 'ember', 'incus-vastaanota/config/environment', 'incus-vastaanota/models/traffic', 'incus-vastaanota/utils/list-utils', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _incusVastaanotaConfigEnvironment, _incusVastaanotaModelsTraffic, _incusVastaanotaUtilsListUtils, _emberCliPaginationComputedPagedArray) {
  exports['default'] = _ember['default'].Route.extend({

    apixclient: _ember['default'].inject.service(),

    model: function model(param) {
      var apixclient = this.get("apixclient");
      var userData = this.get("session").get("user")[0];

      var locale = _incusVastaanotaConfigEnvironment['default'].defaultLocale;
      if (window.localStorage) {
        locale = window.localStorage.getItem('locale');
      }

      console.log(["ADVERT PARAMS", param]);

      var selected_uuids = param.selected;
      if (selected_uuids) {
        selected_uuids = selected_uuids.split(";");
      } else {
        selected_uuids = [];
      }

      console.log(["selected_uuids2", selected_uuids]);

      return apixclient.pitstopTraffic(userData.UniqueCompanyID, userData.TransferID, userData.TransferKey, 1000).then(function (data) {

        var invoiceList = _incusVastaanotaUtilsListUtils['default'].processTrafficData(data, apixclient, userData, function () {
          return _incusVastaanotaModelsTraffic['default'].create();
        });

        var selected = [];

        for (var i = invoiceList.length - 1; i >= 0; i--) {
          var item = invoiceList[i];

          if (_ember['default'].$.inArray(item.uniqueMessageID, selected_uuids) >= 0 && item.uniqueMessageID) {
            selected.pushObject(item);
          }
        }

        console.log(["selected_uuids2", selected_uuids]);
        if (selected_uuids.length != selected.length) {
          console.error(["All selected items were not found from pitstopTraffic", selected_uuids, selected]);
        }

        var model = _ember['default'].Object.extend({
          hideAdvertisement: false,

          hasValidSelected: _ember['default'].computed("selected.[]", function () {
            var selected = this.get("selected");

            for (var i = selected.length - 1; i >= 0; i--) {
              if (selected[i].get("valid")) {
                return true;
              }
            }

            return false;
          }),

          hasMultipleValidSelected: _ember['default'].computed("selected.[]", function () {
            var selected = this.get("selected");
            return selected.length > 1;
          }),

          selected: null
        }).create({ selected: selected });

        return model;
      });
    },

    actions: {
      toList: function toList() {
        this.transitionTo("traffic.pitstop.list");
      },

      toConfirm: function toConfirm() {
        var model = this.get("controller.model");

        var invoices = model.get("selected");
        var selected = [];

        console.log(invoices);

        for (var i = invoices.length - 1; i >= 0; i--) {
          var item = invoices[i];

          if (item.get("valid")) {
            selected.pushObject(item.uniqueMessageID);
          }
        }

        var sc = selected.join(";");

        console.log(["Confirm", selected, sc]);

        this.transitionTo("traffic.pitstop.confirm", sc);
      },

      requestOffer: function requestOffer() {
        var model = this.get("controller.model");

        console.error("requestOffer not implemented.");

        _ember['default'].RSVP.Promise.resolve(null).then(function () {
          model.set("hideAdvertisement", true);
        });
      },

      abort: function abort(uuid) {
        var _this = this;

        var apixclient = this.get("apixclient");
        var userData = this.get("session").get("user")[0];

        console.log(uuid);

        apixclient.pitstopUpdateHeld(uuid, "Reject", "seller", userData.TransferID, userData.TransferKey).then(function (data) {

          var response = data.data[0];

          console.log("Count ", response.Count);

          console.info(["abort_response", data]);
          _this.transitionTo("traffic.pitstop.list");
        });
      }
    }

  });
});